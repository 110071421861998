import { FC, useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import Footer from 'layouts/Footer';
import { BlackText } from 'assets/custom-icons';
import { Background } from 'layouts-elements/Background';
import { Header } from 'layouts-elements/Header';
import { Content } from 'layouts-elements/Content';
import HeaderMenu from 'layouts-elements/Header/HeaderMenu';
import { useLocation } from 'react-router';
import { Jornay } from 'components/Jornay';
import { IAuth } from './types';

// ==========================================:
export const Auth: FC<IAuth> = ({ children, title }) => {
	// const theme = useSelector(getTheme);
	// const pageTitle = title ? `| ${title}` : '';
	const location = useLocation();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (location.pathname !== '/') {
			document.body.className = 'theme-white';
		}
	}, [location]);

	return (
		<>
			{/* <Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet> */}
			<Header />
			<div className="wrapper">
				{/* <HeaderMenu /> */}
				<Background />
				<Content>
					<BlackText className="main__text" />
					{children}
				</Content>
				{/* <Jornay /> */}
				<Footer />
			</div>
		</>
	);
};
