import cube1 from 'assets/images/ecosystem/soon/cube1.png';
import cube2 from 'assets/images/ecosystem/soon/cube2.png';
import cube3 from 'assets/images/ecosystem/soon/cube3.png';

export const cardContent = [
	{
		id: 0,
		text: 'Crafted for game operators, distributors and players alike, Soon makes sure that all the stakeholders in the gaming industry are benefited from the superior gaming experience we provide. Join our multi-gaming platform and explore what`s in store!',
		btn: false,
		img: { coin1: cube3, coin2: cube1, coin3: cube2 },
	},
	{
		id: 1,
		text: 'Get to the world of poker, casinos and live games with Soon! Apart from the endless gaming possibilities, we also offer utmost security and fair play strategies to ensure you win each time. Come, let`s play your best shot!',
		btn: true,
		img: { coin1: cube3, coin2: cube1, coin3: cube2 },
	},
];
