import { FC, useState, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { sanitize } from 'dompurify';
import CopyToClipboard from 'react-copy-to-clipboard';
import cosmoMan from 'assets/images/gate/cosmo-man.png';
import blockQr from 'assets/images/gate/qr-code-block.png';
import { getActions2FALoading, getGoogle2FAKey } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { ITurnOn2FAProps } from './types';

export const TurnOn2FA: FC<ITurnOn2FAProps> = ({ onSubmit, onClose }) => {
	const google2FAKey = useSelector(getGoogle2FAKey);
	const actsLoading = useSelector(getActions2FALoading);

	const [code, setCode] = useState('');

	const handleSecretCodeCopy = () => {
		notificationContainer('Secret code copied', 'info');
	};

	const handleChange2FA = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;
		setCode(e.target.value);
	};

	return (
		<div className="popup popup--padding-type2 popup--smaller popup--twofa">
			<div className="cosmo-block">
				<img src={cosmoMan} alt="" />
			</div>
			<div className="popup-header">
				<button className="popup-close" type="button" onClick={onClose}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="26"
						height="26"
						viewBox="0 0 26 26"
						fill="none"
					>
						<circle cx="20.0634" cy="6.24062" r="5.26796" fill="#00B2FF" />
						<rect
							x="1.82568"
							y="5.48828"
							width="26.3398"
							height="1.88142"
							transform="rotate(45 1.82568 5.48828)"
							fill="white"
						/>
						<rect
							x="0.494629"
							y="24.1143"
							width="26.3398"
							height="1.88142"
							transform="rotate(-45 0.494629 24.1143)"
							fill="white"
						/>
					</svg>
				</button>
			</div>

			<div className="popup-body">
				<div className="popup-body__qr-code">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="292"
						height="198"
						viewBox="0 0 292 198"
						fill="none"
					>
						<path
							d="M0.613044 197.266H282.225C287.025 197.266 290.917 193.374 290.917 188.574V0.832745"
							stroke="url(#paint0_linear_453_18941)"
							strokeWidth="0.434586"
						/>
						<defs>
							<linearGradient
								id="paint0_linear_453_18941"
								x1="290.482"
								y1="197.265"
								x2="180.532"
								y2="92.0959"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="white" />
								<stop offset="1" stopColor="white" stopOpacity="0" />
							</linearGradient>
						</defs>
					</svg>

					<div className="popup__wrapper--qr-code">
						<p className="popup-header__title">Turn on 2FA</p>
						<div className="popup-qrcodeblock">
							<div
								className="tfa-qr"
								// eslint-disable-next-line
								dangerouslySetInnerHTML={{ __html: sanitize(google2FAKey?.QR_Image || '') }}
							/>
							<img className="qr-code-background" src={blockQr} alt="" />
						</div>
					</div>
				</div>

				<div className="form-group">
					<div className="input">
						<p className="input__name">Secret Code</p>

						<div className="input-wrapper">
							<div className="code-field">
								<p className="code-field__value">{google2FAKey?.secret}</p>

								<CopyToClipboard text={google2FAKey?.secret || ''} onCopy={handleSecretCodeCopy}>
									<button className="right-info right-info--copy" type="button">
										<svg
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M10.6673 8.59992V11.3999C10.6673 13.7333 9.73398 14.6666 7.40065 14.6666H4.60065C2.26732 14.6666 1.33398 13.7333 1.33398 11.3999V8.59992C1.33398 6.26659 2.26732 5.33325 4.60065 5.33325H7.40065C9.73398 5.33325 10.6673 6.26659 10.6673 8.59992Z"
												stroke="#C0C0CF"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M14.6673 4.59992V7.39992C14.6673 9.73325 13.734 10.6666 11.4007 10.6666H10.6673V8.59992C10.6673 6.26659 9.73398 5.33325 7.40065 5.33325H5.33398V4.59992C5.33398 2.26659 6.26732 1.33325 8.60065 1.33325H11.4007C13.734 1.33325 14.6673 2.26659 14.6673 4.59992Z"
												stroke="#C0C0CF"
												strokeWidth="1.5"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</button>
								</CopyToClipboard>
							</div>
						</div>
					</div>
				</div>

				<div className="form-text form-text--center">
					<p>
						Scan the QR code using the mobile app <Link to="./">Google Authenticator</Link> or{' '}
						<Link to="./">Authy</Link>
					</p>
				</div>

				<div className="form-group ">
					<div className="input">
						<p className="input__name">Enter your 2FA authentication code</p>

						<div className="input-wrapper">
							<input
								className="input-item"
								type="number"
								placeholder="6 digit code"
								value={code}
								onChange={handleChange2FA}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="popup-footer">
				<button
					className="button button--full-width"
					type="button"
					onClick={() => onSubmit(code)}
					disabled={!code || actsLoading}
				>
					Activate
				</button>
			</div>
		</div>
	);
};
