import { FC, useRef } from 'react';
import share from 'assets/images/gate/card/share.png';
import { popupLinks } from '../constants';
import { ShareItems } from './ShareItems';
import { ItemShare } from '../types';
/*eslint-disable */

export const SharePopup: FC<ItemShare> = ({ close, share_img, name }) => {
	return (
		<div className="wrapped-inner inner">
			{/* <button onClick={() => console.log('f')} type="button" className="card__share">
				<img src={share} alt="" />
			</button> */}
			<div className="inner__block">
				<div className="inner__title">Invite to Earn</div>
				<ShareItems share_img={share_img} name={name} />
			</div>
			<button type="button" onClick={() => close()} className="close-button">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="33"
					height="33"
					viewBox="0 0 33 33"
					fill="none"
				>
					<circle cx="26" cy="7" r="7" fill="#6D5EEA" />
					<rect
						x="1.76758"
						y="6"
						width="35"
						height="2.5"
						transform="rotate(45 1.76758 6)"
						fill="white"
					/>
					<rect
						y="30.7485"
						width="35"
						height="2.5"
						transform="rotate(-45 0 30.7485)"
						fill="white"
					/>
				</svg>
			</button>
		</div>
	);
};
