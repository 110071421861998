// import { isAuth, isGuest } from 'routes/authMiddlewares';
/* eslint-disable */
import { FC } from 'react';
import useRouteRaplace from 'hooks/useRouteRaplace';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { HomePage } from 'pages/HomePage';
import { Registration } from 'pages/Auth/Registration';
import { CheckEmailResetPassword } from 'pages/Auth/CheckEmailResetPassword';
import { ProfilePage } from 'pages/Profile';
import { Confirm } from 'pages/Auth/Confirm';
import { AccountSettingsPage } from 'pages/Profile/AccountSettingsPage';
import { AccountLanguagePage } from 'pages/Profile/AccountLanguagePage';
import { AccountDetailsPage } from 'pages/Profile/AccountDetailsPage';
import { SecurityPage } from 'pages/Profile/Security';
import { InformationPage } from 'pages/Profile/InformationPage';
import { TwoFactorPage } from 'pages/Profile/TwoFactorPage';
import Login from 'pages/Auth/Login';
import EmailConfirm from 'pages/Auth/EmailConfirm';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { CreateNewPassword } from 'pages/Auth/CreateNewPassword';
import { ReferralPage } from 'pages/Profile/Referral';
import routes from './routes';
import { PrivateRoute } from './authMiddlewares';

const routesList: FC = () => {
	const authenticated = useSelector(getAuthIsAuthenticated);
	console.log(authenticated);
	// useRouteRaplace();

	return (
		<Routes>
			<Route index element={<HomePage />} />
			{/* <Route path="*" element={<NotFound />} /> */}
			<Route path={routes.login} element={authenticated ? <Navigate to="/" /> : <Login />} />
			<Route
				path={routes.register}
				element={authenticated ? <Navigate to="/" /> : <Registration />}
			/>
			<Route
				path={routes.emailConfirmation}
				element={authenticated ? <Navigate to="/" /> : <EmailConfirm />}
			/>
			<Route
				path={routes.forgot}
				element={authenticated ? <Navigate to="/" /> : <ResetPassword />}
			/>
			<Route
				path={routes.createNewPassword}
				element={authenticated ? <Navigate to="/" /> : <CreateNewPassword />}
			/>
			<Route
				path={routes.checkEmail}
				element={authenticated ? <Navigate to="/" /> : <CheckEmailResetPassword />}
			/>
			<Route path={routes.profile} element={authenticated ? <ProfilePage /> : <Login />} />
			<Route
				path={routes.profileSettings}
				element={authenticated ? <AccountSettingsPage /> : <Login />}
			/>
			<Route
				path={routes.accountLanguage}
				element={authenticated ? <AccountLanguagePage /> : <Login />}
			/>
			<Route
				path={routes.profileDetails}
				element={authenticated ? <AccountDetailsPage /> : <Login />}
			/>
			<Route path={routes.security} element={authenticated ? <SecurityPage /> : <Login />} />
			<Route path={routes.information} element={authenticated ? <InformationPage /> : <Login />} />
			<Route path={routes.twoFactor} element={authenticated ? <TwoFactorPage /> : <Login />} />
			<Route path={routes.referral} element={authenticated ? <ReferralPage /> : <Login />} />
		</Routes>
	);
};
export default routesList;
