import { goBack } from 'connected-react-router';
import { FC, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
// import { HistoryModal } from 'components/Bridge/HistoryModal';
import { ITopBlock } from './type';
/* eslint-disable no-debugger, no-console */

export const TopBlock: FC<ITopBlock> = ({ link, text, history = '' }) => {
	const [openModal, setOpenModal] = useState(false);

	const handleOpenModal = () => {
		setOpenModal(!openModal);
	};

	const navigate = useNavigate();
	const handleNavigate = () => {
		if (link === '') return;
		navigate(-1);
	};

	return (
		<div className="top-page-block">
			{history ? (
				<div className="container container--relative">
					<button type="button" onClick={handleNavigate} className="back-btn">
						{link === '' ? (
							text
						) : (
							<>
								<span className="back-btn__arrow">
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M12.5742 3.4001L7.14089 8.83343C6.49922 9.4751 6.49922 10.5251 7.14088 11.1668L12.5742 16.6001"
											stroke="#8192AA"
											strokeWidth="2"
											strokeMiterlimit="10"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
								{text}
							</>
						)}
					</button>
					<button type="button" onClick={handleOpenModal} className="back-history">
						<span className="back-history__icon">
							<svg
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M14 5.16683V11.8335C14 13.8335 13 15.1668 10.6667 15.1668H5.33333C3 15.1668 2 13.8335 2 11.8335V5.16683C2 3.16683 3 1.8335 5.33333 1.8335H10.6667C13 1.8335 14 3.16683 14 5.16683Z"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M9.66797 3.5V4.83333C9.66797 5.56667 10.268 6.16667 11.0013 6.16667H12.3346"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.33203 9.1665H7.9987"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.33203 11.8335H10.6654"
									stroke="#8E8EA9"
									strokeWidth="1.2"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
						History
					</button>
				</div>
			) : (
				<button type="button" onClick={handleNavigate} className="back-btn">
					{link === '' ? (
						text
					) : (
						<>
							<span className="back-btn__arrow">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.5742 3.4001L7.14089 8.83343C6.49922 9.4751 6.49922 10.5251 7.14088 11.1668L12.5742 16.6001"
										stroke="#8192AA"
										strokeWidth="2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							{text}
						</>
					)}
				</button>
			)}
			{/* <HistoryModal openModal={openModal} setOpenModal={setOpenModal} /> */}
		</div>
	);
};
