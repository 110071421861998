import { FC } from 'react';
import { Modal } from 'ui/Popups/ModalsDropdown';
import { Link } from 'react-router-dom';
import clock from 'assets/images/gate/clockSucc.png';

export const VerificationModal: FC<any> = ({ openModal, setOpenModal, activeAnim, classIn }) => {
	return (
		<Modal isOpen={openModal} onClose={setOpenModal} activeAnim={activeAnim} classIn={classIn}>
			<div className="popup-clock">
				<img src={clock} alt="" />
			</div>
			<div className="popup-content">
				<h1 className="title">Verification completed</h1>
				<p className="sub-title">Now you can Log In to your account.</p>
				<Link to="/" className="button button--full-width button--big-height button--margin-top">
					Go to Log In
				</Link>
			</div>
		</Modal>
	);
};
