import { FC, useEffect, useState } from 'react';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import download from 'assets/images/gate/download.png';
import pink from 'assets/images/gate/pink-puzzle.png';
import useWindowResize from 'hooks/useWindowResize';
import { cardsArr } from '../utils';
import { ItemCard } from '../Downloads/ItemCard';
import { IItemP } from '../types';

export const CardBlockchain: FC<IItemP> = ({ data, setOpenCard, openCard }) => {
	const { img, text, stage, name, puzzleImg, id, planet } = data;
	const handleOpen = () => {
		setOpenCard?.(String(name));
		window.document.body.classList.add('popup-card');
	};
	const { width } = useWindowResize();
	const [resize, setResize] = useState(false);

	useEffect(() => {
		if (width && Number(width) <= 780) {
			setResize(true);
		} else {
			setResize(false);
		}
	}, [width]);

	return (
		<div
			style={{ zIndex: openCard === String(name) ? '5' : '1' }}
			className={`pazzle-block blockchain pazzle__${String(name)}`}
		>
			<div className="pazzle-block__content">
				<button type="button" onClick={() => handleOpen()} className="pazzle-block__download-block">
					<div className="pazzle-block_img-down">
						<img src={img} alt="" />
					</div>
					<p>Download Doc</p>
				</button>
				<div className="pazzle-block__text">
					<div className="pazzle-block__stage">{stage}</div>
					<div className="pazzle-block__title">{text}</div>
				</div>
			</div>
			<div className="pazzle-block__planet">
				<img src={planet} alt="" />
			</div>
			<div className="pazzle-block__img">
				<img src={puzzleImg} alt="" />
			</div>
			{openCard === String(name) && !resize ? (
				<ItemCard
					data={cardsArr[id]}
					img_link={img}
					handleClick={() => {
						setOpenCard?.('');
						window.document.body.classList.remove('popup-card');
					}}
					classN="download-card--jorney-one"
				/>
			) : null}
			{resize ? (
				<ModalWarapper openModal={openCard === String(name)} setOpenModal={() => setOpenCard?.('')}>
					<ItemCard
						data={cardsArr[id]}
						img_link={img}
						handleClick={() => {
							setOpenCard?.('');
							window.document.body.classList.remove('popup-card');
						}}
						classN="download-card--jorney-one"
					/>
				</ModalWarapper>
			) : null}
		</div>
	);
};
