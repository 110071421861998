import { FC, useEffect, useState } from 'react';
import blPuzzle from 'assets/images/gate/cardAmb/pzBlure.svg';
import planetBl from 'assets/images/gate/cardAmb/planetBlure.svg';
import starLeft from 'assets/images/gate/cardAmb/starLeft.png';
import puzzleL1 from 'assets/images/ecosystem/puzzleEco1.png';
import puzzleR1 from 'assets/images/ecosystem/puzzleEco2.png';
import { useDispatch, useSelector } from 'react-redux';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { cardContent } from './constant';
import { CardContainer } from './CardContainer/CardContainer';

/* eslint-disable no-debugger */

export const GatoCoin: FC = () => {
	const dispatch = useDispatch();
	const qrcode = useSelector(getQrcode);

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		// setIsOpen?.(true);
	};

	return (
		<section id="ecosystem--coin" className="eco-section">
			<div className="eco-puzzle eco-puzzle1">
				<img src={puzzleL1} alt="" />
			</div>
			<div className="eco-puzzle eco-puzzle2">
				<img src={puzzleR1} alt="" />
			</div>
			<div className="radial-blur radial-blur1" />
			<div className="radial-blur radial-blur2" />
			<div className="bg-dessey" />
			<div className="background-box__foreground">
				<img className="pz-odessey5" src={starLeft} alt="" />
				<img className="pz-odessey2" src={blPuzzle} alt="" />
				<img className="pz-odessey3" src={planetBl} alt="" />
			</div>
			{cardContent.map((item, index) => (
				<CardContainer data={item} />
			))}
		</section>
	);
};
