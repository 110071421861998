import { FC, useState, MouseEvent } from 'react';
import { Formik, Form, Field } from 'formik';
import { useLocation } from 'react-router';
import yup from 'services/utils/capsLockValidate';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Input from 'ui/Formik/Input';
import { IRegistrValues, IRegistrForm } from './types';

export const RegistrForm: FC<IRegistrForm> = ({ registrSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const [currentTab, setCurrentTab] = useState('email');
	const location = useLocation();
	const { search } = location;

	const referral = queryString.parse(search).referral || null;
	const diasableRef = !referral;

	const initialValues = {
		[`${currentTab}`]: '',
		firstname: '' || null,
		lastname: '' || null,
		password: '',
		confirmPassword: '',
		type: currentTab,
		captcha: '00000000',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.capsLockValidate(isCapsLockOn, 'CapsLock is on')
			.required('required email')
			.email('Invalid email format')
			.max(60, 'email max characters'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length 25'),
		confirmPassword: yup
			.string()
			.required('Confirm password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.oneOf([yup.ref('password'), null], `Password doesn't match`)
			.max(25, 'Password max length'),
	});
	const validationSchema2 = yup.object().shape({
		phone: yup.string().required('required phone').max(15, 'Password max length'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	const handleCurrentTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={currentTab === 'email' ? validationSchema : validationSchema2}
			onSubmit={(values: IRegistrValues, { resetForm, setSubmitting }) => {
				registrSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			enableReinitialize
			validateOnBlur
		>
			{({ isSubmitting, isValid, dirty, handleChange }) => {
				return (
					<Form>
						<div className="authorization-form__header">
							<p className="form-title form-title--center">Hi ! Welcome</p>
							<div className="form-text form-text--center">
								<p>Let’s create an account</p>
							</div>
						</div>
						<div className="authorization-form__body">
							<div className="authorization-type">
								<button
									type="button"
									className={`authorization-type__item ${currentTab === 'email' ? 'active' : ''}`}
									name="email"
									onClick={handleCurrentTab}
								>
									Email
								</button>
								<button
									type="button"
									className={`authorization-type__item ${currentTab === 'phone' ? 'active' : ''}`}
									name="phone"
									onClick={handleCurrentTab}
								>
									Phone Number
								</button>
							</div>

							<div className="authorization-form__group">
								<Field
									type="text"
									placeholder="First Name"
									name="firstname"
									required
									onChange={handleChange}
									component={Input}
									values
								/>
								<Field
									type="text"
									placeholder="Last Name"
									name="lastname"
									required
									onChange={handleChange}
									component={Input}
									values
								/>
								{currentTab === 'email' ? (
									<Field type="email" placeholder="Email" name="email" required component={Input} />
								) : (
									<Field
										type="phone"
										placeholder="Phone"
										name="phone"
										required
										onChange={handleChange}
										component={Input}
										values
									/>
								)}
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="Password"
									name="password"
									required
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
								/>
								<Field
									type={isShowConfirmPass ? 'text' : 'password'}
									placeholder="Confirm password"
									name="confirmPassword"
									required
									component={Input}
									ariaLabel="show-confirm-password"
									showPass={isShowConfirmPass}
									setIsShowPass={handleConfirmPassDisplay}
								/>
							</div>
						</div>
						<div className="form-footer">
							<button
								// disabled={diasableRef || !(isValid && dirty) || isSubmitting}
								disabled={!(isValid && dirty) || isSubmitting}
								type="submit"
								className="button button--big-height button--full-width"
							>
								Create Account
							</button>
						</div>
						<div className="form--bottom">
							Have an account ? <Link to="/login">Log In</Link>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
