import { FC, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { getQrcodeImgRequest, setBackgroundTrue } from 'redux/reducers/auth/reducer';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { http } from 'services/http';
import { getQrcode, getImgQrcode, blockBg } from 'redux/reducers/auth/selectors';
import { CardsDefault } from '../CardDifferent/CardsDefault';
import { CardsQrCode } from '../CardDifferent/CardsQrCode';
import { CardsSoon } from '../CardDifferent/CardsSoon';
import { CardsEcosystem } from '../CardDifferent/CardsEcosystemt/CardsEcosystem';
import { TCard } from './types';
import { SharePopup } from '../sharePopup';

const REACT_APP_WEB = process.env.REACT_APP_WEB_API_URL;

/* eslint-disable */
export const Cards: FC<TCard> = ({ data, handlaClick, scale, setScale, activeSlide }) => {
	const dispatch = useDispatch();
	const {
		title,
		footer_img,
		footer_text,
		center_img,
		background,
		bg_text,
		name,
		id_img,
		share_img,
	} = data;
	const qrcode = useSelector(getQrcode);
	const [isOpen, setIsOpen] = useState(false);
	const close = () => setIsOpen(false);
	const qrcodeImg = useSelector(getImgQrcode);
	const [imageUrl, setImageUrl] = useState(null);
	const [scaleCard, setcaleCard] = useState(false);

	const handleDownload = () => {
		dispatch(
			getQrcodeImgRequest({
				is_download: 1,
				onOpenPdf: (data) => {
					if (data && REACT_APP_WEB) {
						window.location.replace(`${REACT_APP_WEB}download-img?image=${data}`);
					}
				},
			}),
		);
	};

	// useEffect(() => {
	// 	const slides = document.querySelectorAll('.card.active');
	// 	debugger;
	// 	if (slides.length) {
	// 		dispatch(setBackgroundTrue(true));
	// 	} else {
	// 		dispatch(setBackgroundTrue(false));
	// 	}
	// }, [scaleCrad]);

	const toggleSacle = () => {
		setcaleCard(!scaleCard);
	};

	const switchCards = (nameOfCard: string) => {
		const ecoTrue = nameOfCard.split('--') ? nameOfCard.split('--')[0] : nameOfCard;
		switch (ecoTrue) {
			case 'odyssey':
				return (
					<CardsQrCode
						data={data}
						handlaClick={handlaClick}
						setIsOpen={setIsOpen}
						activeSlide={activeSlide}
					/>
				);

			case 'soon':
				return <CardsSoon data={data} />;
			case 'ecosystem':
				return (
					<CardsEcosystem
						data={data}
						handlaClick={handlaClick}
						setIsOpen={setIsOpen}
						activeSlide={activeSlide}
					/>
				);

			default:
				return <CardsDefault data={data} handlaClick={handlaClick} setIsOpen={setIsOpen} />;
		}
	};

	return (
		<div
			// onClick={toggleSacle}
			className={`card card__${String(name)} swiper-slide ${scaleCard ? 'active' : ''}`}
		>
			{switchCards(name)}
			<ModalWarapper openModal={isOpen} setOpenModal={close}>
				<SharePopup close={close} share_img={share_img} name={name} />
			</ModalWarapper>
		</div>
	);
};
