import { FC } from 'react';

import { PageProfile } from 'layouts/page/Profile';
import { AccountSettings } from 'components/Profile/AccountSettings';

export const AccountSettingsPage: FC = () => {
	return (
		<PageProfile title="Account Settings" text="Account Settings" link="/profile">
			<AccountSettings />
		</PageProfile>
	);
};
