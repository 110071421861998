/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import PhoneInput from 'react-phone-input-2';
import { IAdminInput } from './types';
/* eslint-disable no-debugger */
// ==========================================:
const Input: FC<IAdminInput> = (props) => {
	const {
		ariaLabel,
		type,
		name,
		field,
		placeholder,
		inputMode,
		onKeyUp,
		onChange,
		form,
		someName,
	} = props;
	const onValueChange = (phoneNumber: string): void => {
		form.setFieldValue(field?.name, phoneNumber);

		if (onChange !== null) {
			onChange(phoneNumber);
		}
	};

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'phone' ? (
				<input
					className={`input-item ${name === 'password' ? 'input-item--right-icon' : ''}`}
					{...field}
					type={type}
					placeholder={placeholder}
					autoComplete={name === 'password' ? 'current-password' : name}
					onKeyUp={onKeyUp}
					readOnly={someName === 'disable'}
					inputMode={inputMode || null}
				/>
			) : (
				<PhoneInput
					placeholder={placeholder}
					country="us"
					{...field}
					type={type}
					value={field?.value}
					inputClass={`input-item ${someName ? 'phone-block--disable' : ''}`}
					onChange={onValueChange}
					disabled={someName && 'disabled'}
					onKeyUp={onKeyUp}
					buttonClass="custom-button"
					inputMode={inputMode || null}
					name={field?.name}
				/>
			)}
		</FormControl>
	);
};

export default Input;
