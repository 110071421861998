import { FC, useState } from 'react';
import { ItemCard } from './ItemCard';
import { cardsArr } from '../utils';
import { Downloads } from '../types';
/*eslint-disable */

export const ChooseDownloads: FC<Downloads> = ({ setOpne, handleClick }) => {
	const [checkbox, setCheckbox] = useState(false);
	const arrDownloads: Array<string> = ['0', '1', '2', '3', '4'];
	const [countDoc, setCountDoc] = useState<Array<string>>(arrDownloads);
	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked, name } = e.currentTarget;
		const Id = countDoc.includes(name);
		if (!Id && checked) {
			setCountDoc([...countDoc, name]);
		} else {
			const arrafilter = countDoc.filter((item) => item !== name);
			setCountDoc([...arrafilter]);
		}
	};

	return (
		<section className="download-container">
			<div className="container container--width">
				<button
					className="popup-close popup-close--type2"
					type="button"
					onClick={() => setOpne(false)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="31"
						height="31"
						viewBox="0 0 31 31"
						fill="none"
					>
						<path
							d="M9.35142 22.5145C9.09402 22.5145 8.83662 22.4139 8.63517 22.2125C8.23229 21.8098 8.23229 21.1722 8.63517 20.7807L20.9344 8.47586C21.3373 8.07315 21.9864 8.07315 22.3781 8.47586C22.781 8.87856 22.781 9.52736 22.3781 9.90769L10.0677 22.2125C9.87741 22.4139 9.60882 22.5145 9.34022 22.5145H9.35142Z"
							fill="white"
						/>
						<path
							d="M21.6804 22.5145C21.4118 22.5145 21.1656 22.4139 20.953 22.2125L8.65373 9.90769C8.25084 9.51617 8.25084 8.86737 8.65373 8.47586C9.04542 8.07315 9.69452 8.07315 10.0862 8.47586L22.3966 20.7807C22.7883 21.1834 22.7883 21.821 22.3966 22.2125C22.1952 22.4139 21.9378 22.5145 21.6692 22.5145H21.6804Z"
							fill="white"
						/>
						<path
							d="M15.5064 30.1102C11.5671 30.1102 7.86278 28.5777 5.06496 25.7924C2.27833 23.007 0.745117 19.2932 0.745117 15.3556C0.745117 11.4069 2.27833 7.70427 5.06496 4.9189C7.85159 2.13354 11.5671 0.589844 15.5064 0.589844C19.457 0.589844 23.1613 2.12235 25.9479 4.9189C28.7346 7.69308 30.279 11.4069 30.279 15.3556C30.279 19.2932 28.7457 22.9958 25.9479 25.7924C23.1613 28.5665 19.457 30.1102 15.5064 30.1102ZM15.5064 2.63692C8.50069 2.63692 2.78193 8.35307 2.78193 15.3556C2.78193 22.3694 8.50069 28.0855 15.5064 28.0855C22.5234 28.0855 28.2309 22.3694 28.2309 15.3556C28.2309 8.35307 22.5234 2.63692 15.5064 2.63692Z"
							fill="white"
						/>
					</svg>
				</button>
				<div className="download-section__wrapper">
					<div className="download-container__title">Choose to Download</div>
					<div className="download-container__cards-wrapper">
						{cardsArr.map((item) => (
							<ItemCard
								key={item.id}
								data={item}
								setCheckbox={setCheckbox}
								checkbox={checkbox}
								handleClick={handleClick}
								handleCheckboxChange={handleCheckboxChange}
							/>
						))}
					</div>
				</div>
				<button type="button" className="downloads__button">
					({countDoc.length}) All Docs
				</button>
			</div>
		</section>
	);
};
