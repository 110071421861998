import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';
import auth from './reducers/auth/reducer';
import settings from './reducers/settings/reducer';
import connectWallet from './reducers/connectWallet/reducer';
import wallets from './reducers/wallets/reducer';
import coins from './reducers/coins/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		settings,
		coins,
		auth,
		connectWallet,
		wallets,
	});

export default createRootReducer;
