import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getIs2FAEnabled } from 'redux/reducers/settings/selectors';

export const Security: FC = () => {
	const is2FAEnabled = useSelector(getIs2FAEnabled);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSelect = (): void => {
		dispatch(logoutRequest({ navigate }));

		// navigate(link);
	};
	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="general-block">
						<Link to="/information" className="account-change account-change--security">
							<div className="account-change__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M18.3281 5.67L6.58813 17.41C6.14813 17.85 5.40813 17.79 5.04813 17.27C3.80813 15.46 3.07812 13.32 3.07812 11.12V6.73C3.07812 5.91 3.69813 4.98 4.45813 4.67L10.0281 2.39C11.2881 1.87 12.6882 1.87 13.9482 2.39L17.9881 4.03999C18.6581 4.30999 18.8281 5.17 18.3281 5.67Z"
										fill="#D9F343"
									/>
									<path
										d="M19.27 7.04012C19.92 6.49012 20.91 6.96011 20.91 7.81011V11.1201C20.91 16.0101 17.36 20.5901 12.51 21.9301C12.18 22.0201 11.82 22.0201 11.48 21.9301C10.06 21.5301 8.74 20.8601 7.61 19.9801C7.13 19.6101 7.08001 18.9101 7.50001 18.4801C9.68001 16.2501 16.06 9.75012 19.27 7.04012Z"
										fill="#D9F343"
									/>
								</svg>
							</div>
							<div className="account-change__info">
								<p className="account-change__item">Login Information</p>
								<p className="account-change__subtitle">
									Change your password or see account details.
								</p>
							</div>
							<div className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</Link>
						<div className="account-change-line line--rocket">
							<svg
								width="312"
								height="17"
								viewBox="0 0 312 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.5 8.50488H140.5H311.5"
									stroke="white"
									strokeOpacity="0.5"
									strokeDasharray="2.1 4.1"
								/>
								<path
									d="M166.551 8.65043C166.577 8.6117 166.591 8.5665 166.591 8.52015C166.592 8.47379 166.579 8.42819 166.555 8.3887C164.949 5.74272 158.184 2.68254 152.862 2.68145C151.916 2.67762 150.972 2.7562 150.04 2.91632C149.292 2.02295 148.41 1.26281 147.406 0.868217C144.564 -0.236787 141.722 1.4552 140.587 2.27016C140.525 2.31445 140.473 2.37262 140.437 2.4401C140.401 2.50759 140.381 2.58254 140.379 2.65906C140.377 2.73559 140.392 2.8116 140.424 2.88112C140.456 2.95064 140.504 3.01177 140.564 3.05971L144.083 5.87957C143.846 6.12079 143.622 6.3739 143.411 6.6379C143.262 6.81877 143.181 7.04611 143.182 7.28043L143.185 9.7322C143.184 9.96648 143.265 10.1937 143.414 10.3747C143.623 10.6373 143.846 10.8891 144.082 11.1291L140.568 13.9468C140.509 13.9948 140.461 14.0559 140.429 14.1254C140.397 14.1949 140.382 14.2709 140.384 14.3474C140.386 14.4239 140.406 14.4988 140.442 14.5663C140.478 14.6338 140.53 14.6919 140.592 14.7363C141.721 15.5534 144.567 17.2494 147.404 16.1393C148.408 15.7447 149.287 14.9904 150.034 14.1032C150.967 14.2646 151.913 14.3444 152.86 14.3417C158.186 14.3551 164.831 11.3941 166.551 8.65043ZM153.365 8.50886C153.365 8.02142 153.509 7.54488 153.78 7.13952C154.051 6.73416 154.436 6.41819 154.886 6.23159C155.336 6.04498 155.832 5.99611 156.31 6.09116C156.788 6.18622 157.227 6.42093 157.572 6.7656C157.917 7.11027 158.151 7.54943 158.246 8.02751C158.341 8.50559 158.292 9.00112 158.106 9.45143C157.919 9.90174 157.603 10.2866 157.198 10.5573C156.793 10.828 156.316 10.9724 155.829 10.9722C155.505 10.9725 155.185 10.9089 154.886 10.7852C154.587 10.6615 154.315 10.48 154.086 10.2513C153.857 10.0225 153.676 9.7508 153.552 9.45183C153.429 9.15285 153.365 8.83242 153.365 8.50886Z"
									fill="white"
								/>
								<path
									d="M142.215 10.5355C141.816 10.5359 141.42 10.2945 140.969 9.97687C139.959 9.26102 139.943 7.75343 140.97 7.03613C141.36 6.76459 141.999 6.476 142.216 6.476C142.264 6.47646 142.31 6.46205 142.348 6.43483C142.387 6.4076 142.416 6.36894 142.431 6.32433C142.446 6.27972 142.447 6.23144 142.433 6.18633C142.419 6.14122 142.392 6.10159 142.354 6.07306C141.813 5.64957 141.145 5.41943 140.458 5.41928C138.75 5.42 135.5 8.50886 135.5 8.50886C135.5 8.50886 138.752 11.5995 140.459 11.5988C141.148 11.6 141.816 11.3694 142.358 10.9443C142.523 10.806 142.428 10.5308 142.215 10.5355Z"
									fill="white"
								/>
							</svg>
						</div>
						<Link to="/two-factor" className="account-change account-change--security">
							<div className="account-change__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M19.7887 4.22007C16.8287 1.27007 12.0287 1.27007 9.08868 4.22007C7.01868 6.27007 6.39868 9.22007 7.19868 11.8201L2.49868 16.5201C2.16868 16.8601 1.93868 17.5301 2.00868 18.0101L2.30868 20.1901C2.41868 20.9101 3.08868 21.5901 3.80868 21.6901L5.98868 21.9901C6.46868 22.0601 7.13868 21.8401 7.47868 21.4901L8.29868 20.6701C8.49868 20.4801 8.49868 20.1601 8.29868 19.9601L6.35868 18.0201C6.06868 17.7301 6.06868 17.2501 6.35868 16.9601C6.64868 16.6701 7.12868 16.6701 7.41868 16.9601L9.36868 18.9101C9.55868 19.1001 9.87868 19.1001 10.0687 18.9101L12.1887 16.8001C14.7787 17.6101 17.7287 16.9801 19.7887 14.9301C22.7387 11.9801 22.7387 7.17007 19.7887 4.22007ZM14.4987 12.0001C13.1187 12.0001 11.9987 10.8801 11.9987 9.50007C11.9987 8.12007 13.1187 7.00007 14.4987 7.00007C15.8787 7.00007 16.9987 8.12007 16.9987 9.50007C16.9987 10.8801 15.8787 12.0001 14.4987 12.0001Z"
										fill="#D9F343"
									/>
									<path
										d="M14.5 12C15.8807 12 17 10.8807 17 9.5C17 8.11929 15.8807 7 14.5 7C13.1193 7 12 8.11929 12 9.5C12 10.8807 13.1193 12 14.5 12Z"
										fill="#D9F343"
									/>
								</svg>
							</div>

							<div className="account-change__info">
								<p className="account-change__item">Two-Factor Authentication</p>
								<p className="account-change__subtitle">
									Used for withdrawals and changes to security settings.
								</p>

								{is2FAEnabled && (
									<p className="account-change__enabled">
										<span className="account-change__enabled-success">
											<svg
												width="18"
												height="18"
												viewBox="0 0 18 18"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													opacity="0.4"
													d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
													fill="#D9F343"
												/>
												<path
													d="M7.93312 11.685C7.78312 11.685 7.64063 11.625 7.53563 11.52L5.41312 9.39745C5.19563 9.17995 5.19563 8.81995 5.41312 8.60245C5.63062 8.38495 5.99062 8.38495 6.20812 8.60245L7.93312 10.3275L11.7881 6.47245C12.0056 6.25495 12.3656 6.25495 12.5831 6.47245C12.8006 6.68995 12.8006 7.04995 12.5831 7.26745L8.33062 11.52C8.22562 11.625 8.08312 11.685 7.93312 11.685Z"
													fill="#D9F343"
												/>
											</svg>
										</span>
										Enabled
									</p>
								)}
							</div>
							<div className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</div>
						</Link>
					</div>
					<button
						onClick={() => handleSelect()}
						type="button"
						className="button button--big-height button--full-width button--mt button--transparent"
					>
						Log Out
					</button>
				</div>
			</div>
		</section>
	);
};
