import lock from 'assets/images/gate/card/lock.png';
import settings from 'assets/images/gate/card/settings.png';
import plus_f from 'assets/images/gate/card/plus.svg';
import search_eco from 'assets/images/search-eco.png';
import planet from 'assets/images/gate/card/brand_icon.png';
import rubinGreen from 'assets/custom-icons/background/rubinGreen.png';
import mineBack from 'assets/custom-icons/background/mineBack.png';
import airpods from 'assets/custom-icons/cards/airpods.png';
import domino from 'assets/custom-icons/cards/domino.png';
import mine from 'assets/images/ecosystem/21mine.png';
import fartiBack from 'assets/custom-icons/background/FRACTIONAIRE.png';
import bg_puzzle from 'assets/images/gate/card/bg_puzzl_card.svg';
import cryptoEst from 'assets/custom-icons/background/cryptoEst.png';
import gudsBack from 'assets/custom-icons/background/guds.png';
import coins from 'assets/custom-icons/cards/menyCoin.png';
import buty1Art from 'assets/custom-icons/background/beautifulAbstr1.png';
import desert from 'assets/custom-icons/background/desert.png';
import fartiCenter from 'assets/custom-icons/cards/fartiCenter.png';
import puzzle from 'assets/images/gate/card/puzzle.svg';
import lock1 from 'assets/custom-icons/cards/lock1.png';
import coinCneter from 'assets/custom-icons/cards/coinCardsCenter.png';
import desertImg from 'assets/custom-icons/background/desertImg.png';
import gatoBack from 'assets/custom-icons/background/GATOCOINBACK.png';

import brain from 'assets/images/gate/card/brin.svg';
import plus_tire from 'assets/images/gate/card/plus_tire.png';
import search from 'assets/images/gate/card/search.png';
import cep from 'assets/images/gate/card/cep.png';
import astroSerf from 'assets/images/gate/card/astro-serf.png';
import download from 'assets/images/gate/card/download_fot.png';
import downloadCenter from 'assets/images/gate/card/download_center.svg';
import QX from 'assets/custom-icons/background/QX.png';
import soon from 'assets/custom-icons/background/SOON.png';
import soontxt from 'assets/images/ecosystem/soon-text.png';
import exchangeImg from 'assets/images/ecosystem/oxExchange.png';
import logoSoon from 'assets/images/gate/logo-soon.png';
import reload from 'assets/images/gate/card/reload.png';
import gatoFull from 'assets/images/gate/cardAmb/gatoFull.svg';
import pig from 'assets/images/gate/card/pig.svg';
import rocket from 'assets/images/gate/card/rocket.svg';
import bgSoon from 'assets/images/gate/bg_soon.png';
import plus from 'assets/images/gate/card/plus-pink.svg';
import coinRocket from 'assets/images/gate/card/coin-rocket.svg';
// import cosmo from 'assets/images/cards/cosmo.png';
import curency from 'assets/images/gate/card/crypto.svg';
import telegram from 'assets/images/gate/icon/Telegram.png';
import another from 'assets/images/gate/icon/another.png';
import instagram from 'assets/images/gate/icon/Instagram.png';
import facebook from 'assets/images/gate/icon/Facebook.png';
import twitter from 'assets/images/gate/icon/Twitter.png';
import whatsapp from 'assets/images/gate/icon/Whatsapp.png';
import { iconElement } from './data';

export const cardsItem = [
	{
		id: 0,
		title: 'Invite Friends & Co-Earn',
		footer_text: 'Invest in Legacy and Build Your Circle',
		footer_img: reload,
		center_img: plus,
		background: 'linear-gradient(250deg, #30FD1E 2.08%, #00656A 106.4%)',
		bg_text: iconElement('Plus'),
		name: 'plus',
		share_img: `card-plus.png`,
	},
	// {
	// 	id: 1,
	// 	title: 'GATO Coin  Kickstarter',
	// 	footer_text: 'Buy GATO Coins on Exclusive Terms',
	// 	footer_img: cep,
	// 	center_img: coinRocket,
	// 	background: `url(${bgCoin}) center center`,
	// 	bg_text: iconElement('Coin'),
	// 	name: 'coin',
	// 	share_img: `card-odissey.png`,
	// },
	{
		id: 1,
		title: 'Wallet Connect Now',
		footer_text: 'Connect to Digital Wallet',
		footer_img: cep,
		center_img: pig,
		background: 'linear-gradient(257deg, #FE5D5D 7.15%, #F91BCD 87.2%)',
		bg_text: iconElement('Pig'),
		name: 'pig',
		share_img: `card-pig.png`,
	},
	{
		id: 2,
		title: 'COMING SOON',
		footer_text: 'GATO COIN  ICO',
		footer_img: cep,
		center_img: astroSerf,
		background: `url(${bgSoon}) center center`,
		bg_text: iconElement('Explorer'),
		name: 'soon',
		share_img: `card-odissey.png`,
		id_img: logoSoon,
	},
	{
		id: 3,
		title: 'COMING SOON',
		text_small: 'medium',
		footer_text: `GLAM TOKEN SALES`,
		footer_img: cep,
		center_img: astroSerf,
		background: `url(${bgSoon}) center center`,
		bg_text: iconElement('Explorer'),
		name: 'soon',
		share_img: `card-odissey.png`,
		id_img: logoSoon,
	},
	{
		id: 4,
		title: 'COMING SOON',
		text_small: 'small',
		footer_text: 'LAUNCHPADS- Partners, Dates and more.',
		footer_img: cep,
		center_img: astroSerf,
		background: `url(${bgSoon}) center center`,
		bg_text: iconElement('Explorer'),
		name: 'soon',
		share_img: `card-odissey.png`,
		id_img: logoSoon,
	},
	// {
	// 	id: 3,
	// 	title: 'Get Web3 ID & Cashbacks',
	// 	footer_text: 'Earn as an Ambassador: Referral Invite',
	// 	footer_img: plus_f,
	// 	center_img: puzzle,
	// 	background: `url(${bg_puzzle})`,
	// 	bg_text: iconElement('Puzzle'),
	// 	name: 'puzzele',
	// 	share_img: `card-pazzle.png`,
	// },
	// {
	// 	id: 4,
	// 	title: 'Go Smart Investments',
	// 	footer_text: 'Buy GATO Sustainable Financial Assets ',
	// 	footer_img: plus_tire,
	// 	center_img: brain,
	// 	background: 'linear-gradient(275deg, #53EF0A 4.63%, #D9EF0A 102.18%)',
	// 	bg_text: iconElement('Brain'),
	// 	name: 'brain',
	// 	share_img: `card-brain.png`,
	// },
	// {
	// 	id: 5,
	// 	title: 'GATO Blockchain',
	// 	footer_text: 'Click  to Get Redirected ',
	// 	footer_img: search,
	// 	center_img: rocket,
	// 	background: '#e8e8e8',
	// 	bg_text: iconElement('Rocket'),
	// 	name: 'rocket',
	// 	share_img: `card-rocket.png`,
	// },
	// {
	// 	id: 6,
	// 	title: 'White Paper To-Go',
	// 	footer_text: 'Download E-book & Read Later',
	// 	footer_img: download,
	// 	center_img: downloadCenter,
	// 	background: 'linear-gradient(275deg, #5E18EB 25.46%, #1EF4FD 102.18%)',
	// 	bg_text: iconElement('Download'),
	// 	name: 'download',
	// 	share_img: `card-odissey.png`,
	// },
	// {
	// 	id: 7,
	// 	title: 'CryptoCurrency Commodities Exchange',
	// 	footer_text: 'Coming Soon- Stay Tuned',
	// 	footer_img: lock,
	// 	center_img: curency,
	// 	background: `linear-gradient(275deg, #100F0D 25.46%, #1EF4FD 102.18%)`,
	// 	bg_text: iconElement('Crypto'),
	// 	name: 'crypto',
	// 	share_img: `card-cryptocurency.png`,
	// },

	{
		id: 5,
		title: 'CryptoCurrency Commodities Exchange',
		footer_text: 'https://accou...f',
		footer_img: lock,
		center_img: curency,
		background: 'linear-gradient(275deg, #2D2CAF 4.63%, #0A0A29 102.18%)',
		scan: '',
		bg_text: iconElement('Explorer'),
		name: 'odyssey',
		id_img: gatoFull,
	},
	{
		id: 6,
		title: 'Explore the Odyssey',
		footer_text: 'Explore the GATO Roadmap',
		footer_img: settings,
		center_img: planet,
		background: 'linear-gradient(275deg, #2D2CAF 4.63%, #0A0A29 102.18%)',
		bg_text: iconElement('Explorer'),
		name: 'explore',
		share_img: `card-odissey.png`,
	},
	{
		id: 7,
		title: 'GATO GATE',
		footer_text: 'Explore more',
		footer_description: 'Explore Tools and Projects',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Beta Version released',
		footer_img: search_eco,
		center_img: puzzle,
		background: `url(${buty1Art})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--gate',
		share_img: `card-odissey.png`,
	},
	{
		id: 8,
		title: 'Contact Us',
		footer_text: 'Explore more',
		footer_description: 'Connect with us for assistance',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Active',
		footer_img: search_eco,
		center_img: airpods,
		background: `url(${rubinGreen})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--contacts',
		share_img: `card-odissey.png`,
	},
	{
		id: 9,
		title: '21MINE',
		footer_text: 'Download pdf',
		footer_description: 'UAE`s most trusted BTC mining platform',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Active',
		footer_img: download,
		center_img: mine,
		background: `url(${mineBack})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--mine',
		share_img: `card-odissey.png`,
	},
	{
		id: 10,
		title: 'Qx Exchange',
		footer_text: 'Explore more',
		footer_description: 'Your go-to platform for top coins and tokens',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Active',
		footer_img: download,
		center_img: exchangeImg,
		background: `url(${QX})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--exchange',
		share_img: `card-odissey.png`,
	},
	{
		id: 11,
		title: 'SOON',
		footer_text: 'DOWNLOAD SOON PROFILE',
		footer_description: 'Earn big through gaming',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Coming Soon',
		footer_img: download,
		center_img: soontxt,
		background: `url(${soon})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--soon',
		share_img: `card-odissey.png`,
	},
	{
		id: 12,
		title: 'CRYPTO ESTATE TECHONOLOGY',
		footer_text: 'Explore more',
		footer_description: 'Experience the future today',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Master Label released',
		footer_img: search_eco,
		center_img: domino,
		background: `url(${cryptoEst})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--domino',
		share_img: `card-odissey.png`,
	},
	{
		id: 13,
		title: 'GUDS STABLE COIN',
		footer_text: 'Explore more',
		footer_description: 'Maintain your personal wealth',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Active',
		footer_img: search_eco,
		center_img: coins,
		background: `url(${gudsBack})`,
		name: 'ecosystem--guds',
		share_img: `card-odissey.png`,
	},
	{
		id: 14,
		title: 'DESERT TECH DEVELOPMENT',
		footer_text: 'Explore more',
		footer_description: 'UAE`s Advanced Hardware and Cloud Solutions Provider',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Active',
		footer_img: search_eco,
		center_img: desertImg,
		background: `url(${desert})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--desert',
		share_img: `card-odissey.png`,
	},
	{
		id: 15,
		title: 'FRACTIONAIRE',
		footer_text: 'comming soon',
		footer_description: 'Your Gateway to Fractional Ownership',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'On development',
		footer_img: lock1,
		center_img: fartiCenter,
		background: `url(${fartiBack})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--farti',
		share_img: `card-odissey.png`,
	},
	{
		id: 16,
		title: 'GATO COIN Private Sale is live!',
		footer_text: 'Explore more',
		footer_description: 'GATO COIN Private Sale is live!',
		footer_text_bottom: 'Project Status:',
		footer_text_sub_bottom: 'Private sale open',
		footer_img: search_eco,
		center_img: coinCneter,
		background: `url(${gatoBack})`,
		bg_text: iconElement('Explorer'),
		name: 'ecosystem--coin',
		share_img: `card-odissey.png`,
	},

	// { id: 10, title: 'jornay', img: pazzle, name: 'explore' },
];
export const popupLinks = [
	{
		id: 0,
		name: 'Telegram',
		img: telegram,
	},
	{
		id: 1,
		name: 'Facebook',
		img: facebook,
	},

	{
		id: 2,
		name: 'Twitter',
		img: twitter,
	},
	// {
	// 	id: 3,
	// 	name: 'Instagram',
	// 	img: instagram,
	// },
	{
		id: 3,
		name: 'whatsapp',
		img: whatsapp,
	},
	// {
	// 	id: 4,
	// 	name: 'Another',
	// 	img: another,
	// },
];
