import {
	ExploreText,
	PuzzleText,
	BrainText,
	RocketText,
	PigText,
	DownloadText,
	PlusText,
	Cryptotext,
} from 'assets/custom-icons';
import puzzle from 'assets/custom-icons/puzzle_text.svg';

export const iconElement = (name: string): any => {
	switch (name) {
		case 'Explorer':
			return <ExploreText />;
		case 'Puzzle':
			return <PuzzleText />;
		case 'Brain':
			return <BrainText />;
		case 'Rocket':
			return <RocketText />;
		case 'Pig':
			return <PigText />;
		case 'Download':
			return <DownloadText />;
		case 'Plus':
			return <PlusText />;
		case 'Crypto':
			return <Cryptotext />;

		default:
			return '';
	}
};
