import { FC, useEffect, useState, ChangeEvent } from 'react';
import { textSearchValid } from 'services/utils/textSearch';
import { TGetNationalityData, TNationality } from 'redux/reducers/settings/types';
import TextError from 'ui/Formik/TextError';
import { TSearchInputProps } from './types';
/* eslint-disable no-debugger */
export const CSearchInput: FC<TSearchInputProps> = (props) => {
	const { listData, onSelect, dataItem } = props;
	const [dropdown, setDropdown] = useState(false);
	const [search, setSearch] = useState('');
	// const [errors, setErrors] = useState(false);
	const changeSearch = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length < 15) {
			setSearch(e.target.value);
		}
	};
	useEffect(() => {
		const coincidences = listData?.find((item: any) => item?.name === dataItem);
		if (!dropdown && dataItem && coincidences) {
			const { name } = coincidences;
			setSearch(name);
			// setErrors(false);
		}
	}, [dataItem, dropdown, listData]);
	const handleFocus = () => {
		setDropdown(true);
		setSearch('');
	};

	const handleBlur = () => {
		// without setTimeout the dropdown will close before we select asset
		// if (!dataItem) {
		// 	setErrors(true);
		// }
		setTimeout(() => {
			setDropdown(false);
		}, 300);
	};

	const filterData = (searchCoin: string, data: TGetNationalityData) => {
		return data?.filter((item: TNationality) => textSearchValid(item?.name, searchCoin));
	};

	const data = filterData(search, listData);

	return (
		<>
			<div className="select-block">
				{/* <p className="select-block__name">Nationality</p> */}
				<div
					className={`select select--regular select--pointer details-list__select ${
						dropdown ? 'active' : ''
					}`}
				>
					<div className="select__current current--position">
						<input
							className="input-item"
							placeholder="Nationality"
							value={search}
							onChange={changeSearch}
							onFocus={handleFocus}
							onBlur={handleBlur}
						/>
						<span className="select__arrow icon-arrow2 arrow--position">
							<svg
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M13.2807 5.9668L8.93404 10.3135C8.4207 10.8268 7.5807 10.8268 7.06737 10.3135L2.7207 5.9668"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</span>
					</div>

					{dropdown && (
						<div className="select__drop">
							<div className="select__drop-scroll">
								{data?.length ? (
									data?.map((item: TNationality) => (
										<div key={item.id} className="select__drop-item">
											<button
												type="button"
												className="select__drop-link"
												data-code={item?.name}
												onClick={() => onSelect(item?.name)}
											>
												<span className="">{item?.name}</span>
											</button>
										</div>
									))
								) : (
									<span className="val__name">Global nothing</span>
								)}
							</div>
						</div>
					)}
				</div>
				{/* {errors ? <TextError>Mandatory field</TextError> : null} */}
			</div>
		</>
	);
};
