import { FC } from 'react';
import { IAdminFormConrol } from './types';
/* eslint-disable no-debugger */
// ==========================================:
const FormControl: FC<IAdminFormConrol> = (props) => {
	const {
		form,
		field,
		isShowPass,
		setIsShowPass,
		title,
		children,
		ariaLabel,
		disableGreen,
		someName,
	} = props;

	const errors: string = form?.errors?.[field?.name];
	const touched: string = form?.touched?.[field.name];

	const handlePassDisplay = (): void => {
		if (setIsShowPass) {
			setIsShowPass(!isShowPass);
		}
	};

	let inputStatus = '';

	if (errors && touched) {
		inputStatus = 'input--error';
	} else if (!errors && touched) {
		inputStatus = disableGreen === 'none' ? '' : 'input--success';
	} else if (errors && field.name === 'phone' && touched === undefined) {
		inputStatus = 'input--error';
	} else if (!errors && field.name === 'phone' && touched === undefined) {
		inputStatus = disableGreen === 'none' ? '' : 'input--success';
	} else {
		inputStatus = '';
	}

	return (
		<div className={`input ${someName ? 'input--disable' : ''} ${inputStatus}`}>
			<label htmlFor={title}>
				{title && <p className="input__name">{title}</p>}
				<div
					className={`input-wrapper ${errors && touched ? 'input--error' : ''}${
						errors && field.name === 'phone' && touched === undefined ? 'input--error' : ''
					}`}
				>
					{children}
					{/* <span className="input-icon input-icon--more-right input-icon--success icon-Checkbox" />
					<span className="input-icon input-icon--more-right input-icon--error icon-close" /> */}
					{setIsShowPass && (
						<button
							type="button"
							onClick={handlePassDisplay}
							aria-label={ariaLabel}
							className="show-pass"
						>
							<svg
								className="text-type"
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.3866 8.49995C10.3866 9.81995 9.31995 10.8866 7.99995 10.8866C6.67995 10.8866 5.61328 9.81995 5.61328 8.49995C5.61328 7.17995 6.67995 6.11328 7.99995 6.11328C9.31995 6.11328 10.3866 7.17995 10.3866 8.49995Z"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M7.9999 14.0138C10.3532 14.0138 12.5466 12.6271 14.0732 10.2271C14.6732 9.28714 14.6732 7.70714 14.0732 6.76714C12.5466 4.36714 10.3532 2.98047 7.9999 2.98047C5.64656 2.98047 3.45323 4.36714 1.92656 6.76714C1.32656 7.70714 1.32656 9.28714 1.92656 10.2271C3.45323 12.6271 5.64656 14.0138 7.9999 14.0138Z"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
							<svg
								className="password-type"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M9.68661 6.31328L6.31328 9.68661C5.87995 9.25328 5.61328 8.65995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C8.65995 5.61328 9.25328 5.87995 9.68661 6.31328Z"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M11.8799 3.84633C10.7132 2.96633 9.3799 2.48633 7.9999 2.48633C5.64656 2.48633 3.45323 3.87299 1.92656 6.27299C1.32656 7.21299 1.32656 8.793 1.92656 9.733C2.45323 10.5597 3.06656 11.273 3.73323 11.8463"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M5.61328 13.0204C6.37328 13.3404 7.17995 13.5138 7.99995 13.5138C10.3533 13.5138 12.5466 12.1271 14.0733 9.72711C14.6733 8.78711 14.6733 7.20711 14.0733 6.26711C13.8533 5.92044 13.6133 5.59378 13.3666 5.28711"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.3409 8.4668C10.1676 9.4068 9.40094 10.1735 8.46094 10.3468"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6.31398 9.68652L1.33398 14.6665"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.6675 1.33301L9.6875 6.31301"
									stroke="#C0C0CF"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</button>
					)}
				</div>
				{field.name === 'confirmPassword' || field.name === 'password' ? (
					<span>Must contain number and least of 6 characters </span>
				) : null}

				{errors && touched === undefined && field.name === 'phone' && (
					<div className="input-notify">
						<span className="input-notify__char">*</span>
						<span className="input-notify__text">{errors}</span>
					</div>
				)}
				{errors && touched && (
					<div className="input-notify">
						<span className="input-notify__char">*</span>
						<span className="input-notify__text">{errors && touched && errors}</span>
					</div>
				)}
			</label>
		</div>
	);
};

export default FormControl;
