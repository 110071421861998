import { FC, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { TTheme } from 'redux/reducers/settings/types';
// import { changeTheme } from 'redux/reducers/settings/reducer';
// import { THEME_DARK, THEME_LIGHT } from 'redux/reducers/settings/constants';
// import { getTheme } from 'redux/reducers/settings/selectors';
// import { WalletsModal } from 'components/Wallets/WalletsModal';
// import { IconDog } from 'assets/custom-icons';
import IconDog from 'assets/custom-icons/cards/iconDog.svg';
import IconPlanet from 'assets/custom-icons/cards/iconPlanet.svg';
import IconWallet from 'assets/custom-icons/cards/iconWallet.svg';
import IconList from 'assets/custom-icons/cards/iconList.svg';
import logos_metamask from 'assets/images/logos_metamask-icon.svg';
import gatotext from 'assets/images/ecosystemtext.png';
import { NavLink } from 'react-router-dom';

const Footer: FC = () => {
	// const dispatch = useDispatch();
	// const switchTheme = useSelector(getTheme);
	// const changeThemes = (theme: TTheme) => {
	// 	dispatch(changeTheme(theme));
	// };

	const [openModal, setOpenModal] = useState(false);

	const handlerConnection = () => {
		setOpenModal(!openModal);
	};

	return (
		<footer className="footer">
			<div className="container">
				<div className="footer-main">
					<div className="footer-content">
						<div className="footer-top-panel">
							<NavLink to="/" className="footer-logo">
								<span className="footer-logo__icon">
									<img src={gatotext} alt="" />
								</span>
							</NavLink>
							<div className="footer-social">
								<NavLink to="/" className="footer-social__item">
									<img src={IconDog} alt="" />
								</NavLink>
								<NavLink to="/" className="footer-social__item">
									<img src={IconPlanet} alt="" />
								</NavLink>
								<NavLink to="/" className="footer-social__item">
									<img src={IconWallet} alt="" />
								</NavLink>
								<NavLink to="/" className="footer-social__item">
									<img src={IconList} alt="" />
								</NavLink>
							</div>
						</div>

						<div className="footer-text">
							<p>
								Gato Gate, as the central hub, offering a fast track to efficient operations on
								Gatochain, turning ideas and startups into a dynamic Web 3 Space for ReFi, wealth
								management & endless legacy building.
							</p>
						</div>

						<div className="footer-nav footer-nav--horizontal">
							<ul>
								<li>
									<NavLink to="/">Services agreement</NavLink>
								</li>
								<li>
									<NavLink to="/" style={{ color: '#D9F343' }}>
										Sustainability
									</NavLink>
								</li>
								<li>
									<NavLink to="/">Privacy policy</NavLink>
								</li>
							</ul>
						</div>
					</div>
					{/* <div className="footer-main__group">
						<div className="footer-content">
							<div className="footer-nav">
								<p className="footer-nav__title">Gato Tank 5</p>
								<ul>
									<li>
										<NavLink to="/">Log In</NavLink>
									</li>
									<li>
										<NavLink to="/">VC Funding </NavLink>
									</li>
									<li>
										<button type="button" onClick={handlerConnection}>
											<span className="chain-icon">
												<img src={logos_metamask} alt="" />
											</span>
											Add Gato Chain
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="footer-content">
							<div className="footer-nav">
								<p className="footer-nav__title">GUDS</p>
								<ul>
									<li>
										<NavLink to="/">Diamond Intel</NavLink>
									</li>
								</ul>
							</div>
							<div className="footer-nav">
								<p className="footer-nav__title">GUSD Stable Coin Protocol</p>
								<ul>
									<li>
										<NavLink to="/">Forge your own stable coin </NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="footer-content">
							<div className="footer-nav">
								<p className="footer-nav__title">GUSD Stable Coin Protocol</p>
								<ul>
									<li>
										<NavLink to="/">Forge your own stable coin </NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="footer-content">
							<div className="footer-nav">
								<p className="footer-nav__title">Join US</p>
								<ul>
									<li>
										<NavLink to="/">KYS</NavLink>
									</li>
									<li>
										<NavLink to="/">Legal</NavLink>
									</li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>
				<div className="footer-copyright">
					<p>© Copyright 2024</p>
					<p>|</p>
					<p>Nebula Foundation</p>
					<p>|</p>
					<p>Saint Kitts and Nevis</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
