import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IGenerate2faKeyResponse,
	ISettingsStore,
	IUserSettingsData,
	INotifications,
	TDefaultTransactionSpeed,
	TTheme,
	TReferralsData,
	TGetNationalityData,
	TWalletsData,
} from './types';

// ==========================================:
const getSettingsState = (state: TStoreState): ISettingsStore => state.settings;
export const getSettings = createSelector(
	[getSettingsState],
	(settings: ISettingsStore) => settings,
);

// ====================================================:
export const get2faIsLoad = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.account2faLoader,
);

// ====================================================:
export const getDisplayDisable2faForm = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.displayDisable2faForm,
);

// ====================================================:

export const getGoogle2FAKey = (state: TStoreState) => getSettingsState(state).google2FAKey;

export const get2faData = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | IGenerate2faKeyResponse => settings.data2fa,
);

// ====================================================:
export const getCheck2faEnablesLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.check2faEnablesLoader,
);

// ====================================================:
export const getChangePassLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.changePassLoader,
);

// ====================================================:
export const getDisplayDisableBlock = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | string => settings.check2faEnables,
);

// ====================================================:
export const getUserSettingsData = createSelector(
	[getSettings],
	(settings: ISettingsStore): IUserSettingsData | undefined => settings.userSettingsData?.user,
);

export const getIs2FAEnabled = (state: TStoreState) => {
	const { userSettingsData } = getSettingsState(state);
	return userSettingsData?.user?.google2fa_enabled || userSettingsData?.user?.email_2fa_enabled;
};

// ====================================================:
export const getUserSettingsDataLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.userSettingsDataLoader,
);
// ====================================================:
export const getCheckNotification = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | INotifications => settings.notifications,
);

// ====================================================:
export const getCheckNotificationLoader = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.setNotificationLoader,
);

// ====================================================:
export const getTheme = createSelector(
	[getSettings],
	(settingsTheme: ISettingsStore): TTheme => settingsTheme.theme,
);

// ====================================================:
export const getSlippageTolerance = createSelector(
	[getSettings],
	(settings: ISettingsStore): number => settings.slippageTolerance,
);

// ====================================================:
export const getDefaultTransactionSpeed = createSelector(
	[getSettings],
	(settings: ISettingsStore): TDefaultTransactionSpeed => settings.defaultTransactionSpeed,
);

// ====================================================:
export const getPartialOrderExecution = createSelector(
	[getSettings],
	(settings: ISettingsStore): boolean => settings.partialOrderExecution,
);

// =====================Gato===============================:
export const getReferrals = createSelector(
	[getSettings],
	(settings: ISettingsStore): null | TReferralsData => settings.referrals,
);

export const getActions2FALoading = (state: TStoreState) => {
	return getSettingsState(state).actions2FALoading;
};

// ====================================================:
export const getNationalities = createSelector(
	[getSettingsState],
	(settings: ISettingsStore): TGetNationalityData | null => settings.nationality,
);

// ====================================================:
export const getWallets = createSelector(
	[getSettingsState],
	(settings: ISettingsStore): Array<TWalletsData> | undefined =>
		settings.userSettingsData?.user.wallets,
);

// ====================================================:
export const getSlider = createSelector(
	[getSettingsState],
	(settings: ISettingsStore): any => settings.dataSlider,
);
