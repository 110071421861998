export const cardContent = [
	{
		id: 0,
		text: 'We bring you the most reliable payment method that ensures market stability. Guds stable coin that has the features of a diamond, is the next-generation solution to keep your investments growing with minimal impact on the market behavior.',
		btn: true,
		btn_text: 'buy guds',
	},
	{
		id: 1,
		text: 'The Guds stablecoin promotes an eco-friendly diamond investment ensuring that your returns never drop unlike other markets. Combining the essence of diamond DNA and a blockchain-driven technology, Guds is the payment choice to maintain a stable income for your digital investments without the fear of market inflation. Own your Guds Coin today!',
		btn: true,
		btn_text: 'buy guds',
	},
	{
		id: 2,
		text: 'Guds acts as a source to generate long term passive income when staked. This stable coin is available for fractional ownership for sensitive consumers, along with its utilization for real businesses and social funding. Being part of a re-investment ecosystem, Guds assures value retention and high returns.',
		btn: true,
		btn_text: 'buy guds',
	},
];
