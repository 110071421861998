import check from 'assets/images/ecosystem/contact-mes.png';
import shopping from 'assets/custom-icons/cards/shopping.png';

export const cardContent = [
	{
		id: 0,
		text: 'We are proudly based in the UAE, and are at the forefront of blockchain development and advanced cloud solutions. We specialize in providing exclusive hardware for blockchain and crypto mining, as well as offering scalable cloud services tailored to your business needs. Our commitment to innovation and excellence ensures that we deliver reliable, state-of-the-art technology solutions.',
		btn: true,
		btn_text: 'contact desert tech',
		f_img: check,
	},
	{
		id: 1,
		text: 'Desert Tech Development is your premier destination for cutting-edge mining and computer hardware, as well as top-tier cloud services in the UAE. Whether you`re looking for high-performance components for crypto mining or advanced cloud solutions, we have the expertise and technology to meet your needs. Our e-shop offers an exclusive range of hardware and other components for mining purposes. Trust us for reliable, innovative, and efficient technology solutions.',
		btn: true,
		btn_text: 'go to e-shop',
		f_img: shopping,
	},
];
