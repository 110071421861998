import { FC } from 'react';
import { Modal } from 'ui/Popups/ModalsDropdown';
import { Link } from 'react-router-dom';
import success from 'assets/images/gate/popupSuccess.svg';

export const SuccessPassReset: FC<any> = ({ openModal, setOpenModal, activeAnim }) => {
	return (
		<Modal isOpen={openModal} onClose={setOpenModal} activeAnim={activeAnim}>
			<div className="popup-clock">
				<img src={success} alt="" />
			</div>
			<div className="popup-content">
				<h1 className="title">Success! Password reseted!</h1>
				<p className="sub-title">Now you can Log In to your account.</p>
				<Link
					to="/login"
					className="button button--full-width button--big-height button--margin-top"
				>
					Go to Log in
				</Link>
			</div>
		</Modal>
	);
};
