import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import GBig from 'assets/custom-icons/cards/gBig.svg';
import { notificationContainer } from 'services/utils/notificationContainer';
import copy from 'assets/images/copied.svg';
import { shortNotation } from 'services/utils/textSlice';
import Astor from 'assets/images/gate/cardAmb/astro2.png';
import AstorMob from 'assets/images/gate/cardAmb/astroMob.png';
import leftTel from 'assets/images/gate/cardAmb/left-tel.svg';
import rightTel from 'assets/images/gate/cardAmb/right-reaclam.svg';
import astro from 'assets/images/gate/cardAmb/Astro-fly.svg';
import rocket from 'assets/images/gate/cardAmb/refRocket.svg';
import puzzlet from 'assets/images/gate/cardAmb/Turquoise-puzzle.svg';
import starR from 'assets/images/gate/cardAmb/starRight.png';
import blPuzzle from 'assets/images/gate/cardAmb/pzBlure.svg';
import planetBl from 'assets/images/gate/cardAmb/planetBlure.svg';
import plusLeft from 'assets/images/gate/cardAmb/plusLeft.svg';
import starLeft from 'assets/images/gate/cardAmb/starLeft.png';
import cash from 'assets/images/gate/cardAmb/cash.png';
import cashTen from 'assets/images/gate/cardAmb/cashTen.png';
import { Table } from './Table';
/* eslint-disable no-debugger */

export const Odyssey: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		dispatch(getUserReferralsInfoRequest());
	}, [dispatch]);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<section id="odyssey" className="refer-section">
			<div className="bg-dessey" />
			<div className="background-box__foreground">
				<img className="pz-odessey5" src={starLeft} alt="" />
				<img className="pz-odessey2" src={blPuzzle} alt="" />
				<img className="pz-odessey3" src={planetBl} alt="" />
			</div>
			{/* <img src={bgOdesseyStart} alt="" /> */}
			<div className="container container--relative">
				<div className="cash-back">
					<img className="pz-odessey6" src={plusLeft} alt="" />
					<img className="pz-odessey7" src={cash} alt="" />
					<img className="pz-odessey8" src={cashTen} alt="" />
				</div>
				<div className="refer-box">
					<div className="refer-top">
						<img className="pz-odessey4" src={starR} alt="" />
						<div className="astro-wrapper">
							<img src={Astor} alt="" />
						</div>
						<div className="refer-block-wrapper">
							<div className="refer-block">
								<div className="astro-wrapper astro-wrapper--mob">
									<img src={AstorMob} alt="" />
								</div>
								<p className="refer-top__title">GET YOUR WEB3 ID</p>
								<p className="refer-top__sub-title type1">
									CONNECT YOUR NETWORK TO BUILD WEB3 LEGACY
								</p>
								<div className="referral-link">
									{shortNotation(String(user?.referral_link), 15, 3)}
									{isCopied ? (
										<img className="refer-copy-img" src={copy} alt="copy" />
									) : (
										<CopyToClipboard text={String(user?.referral_link)} onCopy={handleCopy}>
											<button type="button" className="qrcode-block">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="17"
													height="16"
													viewBox="0 0 17 16"
													fill="none"
												>
													<path
														d="M16.9104 3.31353V11.266C16.9104 12.2203 16.3113 13.042 15.4416 13.4397C15.4513 13.3071 15.4609 13.1746 15.4609 13.0332V5.08074C15.4609 3.0131 13.6153 1.32541 11.3541 1.32541H3.26612C3.71063 0.539 4.59963 0 5.63358 0H13.2868C15.287 0 16.9104 1.48446 16.9104 3.31353ZM11.3541 2.65082H2.65735C1.19146 2.65082 0 3.74031 0 5.08074V13.0332C0 14.3736 1.19146 15.4631 2.65735 15.4631H11.3541C12.82 15.4631 14.0115 14.3736 14.0115 13.0332V5.08074C14.0115 3.74031 12.82 2.65082 11.3541 2.65082Z"
														fill="black"
													/>
												</svg>
											</button>
										</CopyToClipboard>
									)}
								</div>
								<div className="left-tel-wrapper">
									<img src={leftTel} alt="" />
								</div>
							</div>
							<div className="referral-link referral-mob">
								{shortNotation(String(user?.referral_link), 15, 10)}
								{isCopied ? (
									<img className="refer-copy-img" src={copy} alt="copy" />
								) : (
									<CopyToClipboard text={String(user?.referral_link)} onCopy={handleCopy}>
										<button type="button" className="qrcode-block">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="17"
												height="16"
												viewBox="0 0 17 16"
												fill="none"
											>
												<path
													d="M16.9104 3.31353V11.266C16.9104 12.2203 16.3113 13.042 15.4416 13.4397C15.4513 13.3071 15.4609 13.1746 15.4609 13.0332V5.08074C15.4609 3.0131 13.6153 1.32541 11.3541 1.32541H3.26612C3.71063 0.539 4.59963 0 5.63358 0H13.2868C15.287 0 16.9104 1.48446 16.9104 3.31353ZM11.3541 2.65082H2.65735C1.19146 2.65082 0 3.74031 0 5.08074V13.0332C0 14.3736 1.19146 15.4631 2.65735 15.4631H11.3541C12.82 15.4631 14.0115 14.3736 14.0115 13.0332V5.08074C14.0115 3.74031 12.82 2.65082 11.3541 2.65082Z"
													fill="black"
												/>
											</svg>
										</button>
									</CopyToClipboard>
								)}
							</div>
							<div className="refer-block refer-block--center">
								<div className="cash-back cash-back--mob">
									<img className="pz-odessey6" src={plusLeft} alt="" />
									<img className="pz-odessey7" src={cash} alt="" />
									<img className="pz-odessey8" src={cashTen} alt="" />
								</div>

								<div className="refer-block__center-img-wrapper">
									<img src={GBig} alt="" />
								</div>
							</div>
							<div className="refer-block">
								<p className="refer-top__title type1-right">JOIN GATO ODYSSEY</p>
								<p className="refer-top__sub-title type1-right">
									UNLOCK PERPETUAL DEFI EARNINGS & INSTANT CASH BACK
								</p>
								<div className="right-tel-wrapper">
									<img src={rightTel} alt="" />
								</div>
							</div>
						</div>
						<img src="" alt="" />
						<div className="refer-top__title-bottom"> NEXT LEVEL DeFi REVENUES</div>
					</div>
					<div className="refer-bottom">
						<Table />
					</div>
				</div>
			</div>
			<img className="astor-fly" src={astro} alt="" />
			<img className="ref-rocket" src={rocket} alt="" />
			<img className="ref-puzle" src={puzzlet} alt="" />
		</section>
	);
};
