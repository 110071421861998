import React from 'react';
import leftBackPuzzle from 'assets/images/puzzle3.png';
import rigthBackPuzzle from 'assets/images/puzzle31.png';
import leftFrontPuzzle from 'assets/images/pazzle2.png';
import rightFrontPuzzle from 'assets/images/puzzel21.png';
import puzzleMob from 'assets/images/puzzleMob.png';
import { BackLogin } from 'assets/custom-icons';

export const BackgroundJornay = () => {
	return (
		<div className="background-box">
			<div className="start--shine">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="90"
					height="90"
					viewBox="0 0 90 90"
					fill="none"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M61.0884 45.7992L89.2812 44.6082L61.0884 43.4139C52.4621 43.0634 45.7992 36.3324 45.3773 27.7028L43.8358 0L42.6415 27.7028C42.2909 36.3324 35.56 43.1348 26.9337 43.4139L0 44.3972L26.9337 45.7992C35.56 46.2211 42.2228 52.884 42.6415 61.5103L44.1863 89.2131L45.3092 61.5103C45.7278 52.884 52.4621 46.1497 61.0884 45.7992Z"
						fill="#7AEEF0"
					/>
				</svg>
			</div>
			<div className="start--shine type2">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="90"
					height="90"
					viewBox="0 0 90 90"
					fill="none"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M61.0884 45.7992L89.2812 44.6082L61.0884 43.4139C52.4621 43.0634 45.7992 36.3324 45.3773 27.7028L43.8358 0L42.6415 27.7028C42.2909 36.3324 35.56 43.1348 26.9337 43.4139L0 44.3972L26.9337 45.7992C35.56 46.2211 42.2228 52.884 42.6415 61.5103L44.1863 89.2131L45.3092 61.5103C45.7278 52.884 52.4621 46.1497 61.0884 45.7992Z"
						fill="#7AEEF0"
					/>
				</svg>
			</div>
			<BackLogin className="network" />
			<div className="network-mob" />
			<img className="puzzleMob" src={puzzleMob} alt="" />
			<div className="background-box__back">
				<img className="puzzle3" src={leftBackPuzzle} alt="" />
				<img className="puzzle31" src={rigthBackPuzzle} alt="" />
			</div>
			<div className="background-box__foreground">
				<img className="puzzle21" src={leftFrontPuzzle} alt="" />
				<img className="puzzle2" src={rightFrontPuzzle} alt="" />
			</div>
		</div>
	);
};
