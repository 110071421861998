import download from 'assets/images/gate/download.png';
import downlBlue from 'assets/images/gate/download-blue.svg';
import downlGreen from 'assets/images/gate/green-download.svg';
import downlPurpure from 'assets/images/gate/purpure-download.svg';
import downlBigBlue from 'assets/images/gate/big-blue-download.svg';
import pink from 'assets/images/gate/pink-puzzle.png';
import planetImg from 'assets/images/gate/planet/planet.png';
import blueBig from 'assets/images/gate/green-puzzle.svg';
import purpure from 'assets/images/gate/purpur-puzzle.svg';
import blue from 'assets/images/gate/blure-puzzle.svg';
import green from 'assets/images/gate/green-puzz.svg';

export const puzzlesArr = [
	{
		id: 0,
		img: download,
		stage: 'Stage 1',
		text: 'GATO Blockchain',
		puzzleImg: pink,
		name: 'stage1',
		planet: planetImg,
	},
	{
		id: 1,
		img: downlGreen,
		stage: 'Stage 2',
		text: 'GATO Business: Web 3 to Go',
		puzzleImg: green,
		name: 'stage2',
		planet: planetImg,
	},
	{
		id: 2,
		img: downlBlue,
		stage: 'Stage 3',
		text: 'GATO Web 3 Community Gatoverse',
		puzzleImg: blueBig,
		name: 'stage3',
		planet: planetImg,
	},
	{
		id: 3,
		img: downlPurpure,
		stage: 'Stage 4',
		text: 'GATO Financial Assets',
		puzzleImg: purpure,
		name: 'stage4',
		planet: planetImg,
	},
	{
		id: 4,
		img: downlBigBlue,
		stage: 'Stage 5',
		text: 'G5 TANK Sustainable VC & Incube',
		puzzleImg: blue,
		name: 'stage5',
		planet: planetImg,
	},
];

export const cardsArr = [
	{
		id: 0,
		styleMob: {
			background: 'rgba(62, 10, 54, 0.82)',
		},
		stage: 'Stage 1',
		text: 'GATO Odyssey: Building SUSTINABLE Legacy',
		puzzleImg: pink,
		name: 'pink',
		style: { background: 'rgba(90, 25, 80, 0.60)', backdropFilter: 'blur(12px)' },
	},
	{
		id: 1,
		styleMob: {
			background: 'rgba(37, 40, 8, 0.63)',
		},
		stage: 'Stage 2',
		text: 'GATO Business: Web To GO & ESG On GATOCHAIN',
		puzzleImg: green,
		name: 'green',
		style: { background: 'rgba(87, 96, 28, 0.60)', backdropFilter: 'blur(12px)' },
	},
	{
		id: 2,
		styleMob: {
			background: '#0A1C20',
		},
		stage: 'Stage 3',
		text: 'The GATO Web3 White Labels & User Cases',
		puzzleImg: blueBig,
		name: 'blue',
		style: { background: 'rgba(26, 81, 92, 0.60)', backdropFilter: 'blur(12px)' },
	},
	{
		id: 3,
		styleMob: {
			background: 'rgba(0, 0, 0, 0.77)',
		},
		stage: 'Stage 4',
		text: 'GATO Financial Assets & Opportunities',
		puzzleImg: purpure,
		name: 'purpure',
		style: { background: 'rgba(72, 33, 136, 0.54)', backdropFilter: 'blur(12px)' },
	},
	{
		id: 4,
		styleMob: {
			background: 'rgba(0, 0, 0, 0.77)',
		},
		stage: 'Stage 5',
		text: 'GS TANK: Platform for Sustainable Startups on GATO',
		puzzleImg: blue,
		name: 'blue-black',
		style: { background: 'rgba(10, 75, 142, 0.64)', backdropFilter: 'blur(12px)' },
	},
];
