import coinBig from 'assets/images/ecosystem/21mine/coinBig.png';
import coinHalf from 'assets/images/ecosystem/21mine/haif-coin.png';
import coin from 'assets/images/ecosystem/21mine/coin.png';

export const cardContent = [
	{
		id: 0,
		text: 'Invest in 21 Mine, our Bitcoin mining host, to get guaranteed rewards on your investments. With over 500 topclass mining machines, we offer unmatched security and efficiency to maximize your returns. Take control over your earnings through our real-time tracker, and make sure you`re one of those elite Bitcoin holders.',
		btn: false,
		img: { coin1: coinHalf, coin2: coinBig },
	},
	{
		id: 1,
		text: 'Don`t miss this opportunity to be a vital part of the Bitcoin community and reap huge rewards with minimum investments. Join us and explore the vast possibilities of mining Bitcoin.',
		btn: true,
		img: { coin1: coinHalf, coin2: coin },
	},
];
