import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getQrcode, getImgQrcode } from 'redux/reducers/auth/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
// import cosmo from 'assets/images/cards/cosmo.png';
/* eslint-disable */
import {
	FacebookShareButton,
	TwitterShareButton,
	TelegramShareButton,
	WhatsappShareButton,
	FacebookMessengerShareButton,
} from 'react-share';
import { popupLinks } from '../constants';
import { IShare } from '../types';

const url_global = String(process.env.REACT_APP_WEB_URL);

export const ShareItems: FC<IShare> = ({ share_img, name }) => {
	const qrcode = useSelector(getQrcode);
	const qrcodeImg = useSelector(getImgQrcode);
	const user = useSelector(getUserSettingsData);
	const text = 'blalbla';
	const share_url = `https://t.me/share/url?url=${qrcodeImg}&text=${text}`;
	const share_url_w = `https://wa.me/?text=${encodeURIComponent(String(qrcodeImg))}`;
	console.log(qrcodeImg);
	console.log(url_global);
	console.log(share_img);

	return (
		<div className="inner__wrapper">
			{/* <a href={`${share_url_w}`}>Share</a> */}
			<TelegramShareButton
				className="inner__item"
				url={qrcodeImg && name === 'odyssey' ? qrcodeImg : `${url_global}${String(share_img)}`}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${
					user?.referral_link
				}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[0].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[0].name}</p>
			</TelegramShareButton>
			<TelegramShareButton
				className="inner__item"
				url={String(user?.referral_link)}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${qrcodeImg}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[0].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[0].name}</p>
			</TelegramShareButton>
			<TelegramShareButton
				className="inner__item"
				url={String(user?.referral_link)}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${
					user?.referral_link
				}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[0].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[0].name}</p>
			</TelegramShareButton>
			<TelegramShareButton className="inner__item" url={String(user?.referral_link)}>
				<div className="inner__item-img">
					<img src={popupLinks[0].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[0].name}</p>
			</TelegramShareButton>
			<FacebookShareButton
				className="inner__item"
				url={qrcodeImg && name === 'odyssey' ? qrcodeImg : `${url_global}${String(share_img)}`}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${
					user?.referral_link
				}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[1].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[1].name}</p>
			</FacebookShareButton>
			<TwitterShareButton
				className="inner__item"
				url={qrcodeImg && name === 'odyssey' ? qrcodeImg : `${url_global}${String(share_img)}`}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${
					user?.referral_link
				}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[2].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[2].name}</p>
			</TwitterShareButton>
			{/* <div className="inner__item">
				<div className="inner__item-img">
					<img src={popupLinks[3].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[3].name}</p>
			</div> */}
			<WhatsappShareButton
				className="inner__item"
				url={qrcodeImg && name === 'odyssey' ? qrcodeImg : `${url_global}${String(share_img)}`}
				title={`${name === 'odyssey' ? 'Scan QR code or ' : 'click to referral link'} ${
					user?.referral_link
				}`}
			>
				<div className="inner__item-img">
					<img src={popupLinks[3].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[3].name}</p>
			</WhatsappShareButton>
			{/* <a
				href={`https://www.instagram.com/?url=${String(qrcode)}`}
				target="_blank"
				rel="noreferrer"
				className="inner__item"
			>
				<div className="inner__item-img">
					<img src={popupLinks[5].img} alt="" />
				</div>
				<p className="inner__text">{popupLinks[5].name}</p>
			</a> */}
		</div>
	);
};
