import { FC, useState, MouseEvent, useEffect } from 'react';
// import { WalletsModal } from 'components/Wallets/WalletsModal';
// import useAuth from 'hooks/useAuth';
// import { Web3Provider } from '@ethersproject/providers';
import { setAddress } from 'redux/reducers/connectWallet/reducer';
import { useAccount } from 'wagmi';
// import { useWeb3React } from '@web3-react/core';
import { notificationContainer } from 'services/utils/notificationContainer';
import { useSelector, useDispatch } from 'react-redux';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { useWeb3Modal } from '@web3modal/react';
/* eslint-disable */

export const ConnectWalletButton: FC = () => {
	const [openModal, setOpenModal] = useState(false);
	// const { logout } = useAuth();
	const adressAccount = useSelector(getAddress);
	const dispatch = useDispatch();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');
	const { address, isConnected, connector } = useAccount();
	const { open, close } = useWeb3Modal();
	// const { account, active, error, library } = useWeb3React<Web3Provider>();
	const handlerConnection = (e: MouseEvent<HTMLButtonElement>) => {
		const nameConnection: string = e.currentTarget.name;

		if (nameConnection === 'disconnect') {
			// close();
			notificationContainer('Disconnect wallet!', 'info');
			return;
		}
		// await open();
	};
	// useEffect(() => {
	// 	if (isConnected && !adressAccount.active && !connectorName) {
	// 		const payload = {
	// 			account: address,
	// 			active: isConnected,
	// 		};

	// 		dispatch(setAddress(payload));
	// 	}
	// }, [dispatch, adressAccount, isConnected, address, connectorName]);
	return (
		<>
			<button
				type="button"
				className={`button button--big-height button--full-width button--mt ${
					isConnected ? 'button--transparent' : ''
				}`}
				name={isConnected ? 'disconnect' : 'connect'}
				onClick={() => open()}
			>
				{isConnected ? 'Disconnect Wallet' : 'Connect Wallet'}
			</button>
			{/* <WalletsModal openModal={openModal} setOpenModal={setOpenModal} /> */}
		</>
	);
};
