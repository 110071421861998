import check from 'assets/images/ecosystem/contact-mes.png';
import eye from 'assets/custom-icons/cards/eye.png';

export const cardContent = [
	{
		id: 0,
		text: 'Fractionaire opens a world of fractional ownership of premium digital assets through buying and selling of fractions. From real estate, premium cars and yachts, we provide fractional ownership services for assets like diamonds, watches, NFTs and more. Be a Fractionaire owner and see your investments grow as the assets get sold or rented on the platform.',
		btn: false,
		btn_text: 'Download pdf',
	},
	{
		id: 1,
		text: 'Whether you’re an investor looking to invest in a luxury asset, or an owner of a premium digital asset, Fractionaire provides you with an easily navigable platform for your assets. Owners can decide on the number of fractions or blocks of their assets along with the quoted price, and investors get the opportunity to partially or fully own the assets in fractions.',
		btn: true,
		btn_text: 'See platform preview',
		f_img: check,
	},
	{
		id: 2,
		text: 'Fractionaire offers an exclusive platform for investors to be partial or complete owners of premium digital assets. To know more, connect with us today and find what`s in store for you.',
		btn: true,
		btn_text: 'contact fractionaire',
		f_img: eye,
	},
];
