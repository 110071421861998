import pinkpuzzle from 'assets/images/gate/cardAmb/pinkpuzzle.svg';
import o2 from 'assets/images/gate/cardAmb/o2Bloore.svg';
import tablet1 from 'assets/images/ecosystem/tabletL.png';
import tablet2 from 'assets/images/ecosystem/tabletR.png';
import { BackLogin } from 'assets/custom-icons';

export const BackgroundOdessey = () => {
	return (
		<div className="background-box">
			<div className="tabletk tabletL">
				<img src={tablet1} alt="" />
			</div>
			<div className="tabletk tabletR">
				<img src={tablet2} alt="" />
			</div>
			<BackLogin className="network" />
			<div className="network-mob" />
			<div className="background-box__back">
				<img className="pz-odessey" src={pinkpuzzle} alt="" />
				<img className="pz-odessey1" src={o2} alt="" />
			</div>
		</div>
	);
};
