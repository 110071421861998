import { FC, useState, MouseEvent, ChangeEvent } from 'react';
import { Formik, Form, Field } from 'formik';
import yup from 'services/utils/capsLockValidate';
import Input from 'ui/Formik/Input';
import { Link } from 'react-router-dom';
import { ILoginForm, ILoginSubmitValue } from './types';

export const LoginForm: FC<ILoginForm> = ({ loginSubmit, setCheckbox, checkbox }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isCapsLockOn, setIsCapsLockOn] = useState(false);
	const [currentTab, setCurrentTab] = useState('email');

	const initialValues = {
		// [`${currentTab}`]: '',
		name: '',
		password: '',
		type: currentTab,
		captcha: '00000000',
	};
	/* eslint-disable no-debugger */
	const validationSchema = yup.object().shape({
		// email: yup
		// 	.string()
		// 	.capsLockValidate(isCapsLockOn, 'CapsLock is on')
		// 	.required('required email')
		// 	.email('Invalid email format')
		// 	.max(60, 'email max characters 60'),
		name: yup
			.string()
			.required('Required field')
			// .email('Email format')
			.max(60, 'Email max characters'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length 25'),
	});
	const validationSchema2 = yup.object().shape({
		phone: yup.string().required('required phone'),
		password: yup
			.string()
			.required('password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length 25'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleCurrentTab = (e: MouseEvent<HTMLButtonElement>) => {
		setCurrentTab(e.currentTarget.name);
		// setUserData({
		//   email: '',
		//   password: '',
		//   captcha: '',
		//   phone: '',
		//   referral: userData?.referral ? userData?.referral : '',
		//   agree: false,
		//   passwordDifficult: 'weak',
		// });
	};

	const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.currentTarget;
		setCheckbox(checked);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={currentTab === 'email' ? validationSchema : validationSchema2}
			onSubmit={(values: ILoginSubmitValue, { resetForm, setSubmitting }) => {
				loginSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, handleChange }) => (
				<Form>
					<div className="authorization-form__header">
						<p className="form-title form-title--center">Hi ! Welcome</p>
						<div className="form-text form-text--center">
							<p>Please enter your details</p>
						</div>
					</div>
					<div className="authorization-form__body">
						{/* <div className="authorization-type">
							<button
								type="button"
								className={`authorization-type__item ${currentTab === 'email' ? 'active' : ''}`}
								name="email"
								onClick={handleCurrentTab}
							>
								Email
							</button>
							<button
								type="button"
								className={`authorization-type__item ${currentTab === 'phone' ? 'active' : ''}`}
								name="phone"
								onClick={handleCurrentTab}
							>
								Phone Number
							</button>
						</div> */}
						<div className="authorization-form__group">
							{/* {currentTab === 'email' ? (
								<Field
									type="email"
									placeholder="Email or Phone Number"
									name="email"
									required
									component={Input}
								/>
							) : (
								<Field
									type="phone"
									placeholder="Phone"
									name="phone"
									required
									onChange={handleChange}
									component={Input}
									values
								/>
							)} */}
							<Field
								type="text"
								placeholder="Email or Phone Number"
								name="name"
								required
								component={Input}
							/>
							<Field
								type={isShowPass ? 'text' : 'password'}
								placeholder="Password"
								name="password"
								required
								component={Input}
								ariaLabel="show-password"
								showPass={isShowPass}
								setIsShowPass={handlePassDisplay}
							/>
						</div>
					</div>
					{/* <div className="authorization__form">

						 <div className="authorization__options">
							<Link to="/forgot-password" className="authorization__forgot-link">
								{String(L.translate('Forms.LoginForm.login_forgot_password'))}
							</Link>
							<Link to="/forgot-2fa" className="authorization__forgot-link">
								{String(L.translate('Forms.Forgot2fa.reset_2fa'))}
							</Link>
						</div> 
					</div> */}

					<div className="save-info">
						<div className="checkbox checkbox--save-info checkbox--no-m">
							<label htmlFor="remember" className="checkbox__label">
								<input
									className="hidden"
									id="remember"
									name="setCheckbox"
									type="checkbox"
									checked={checkbox}
									onChange={handleCheckboxChange}
								/>
								<div className=" checkbox__item">
									<span className="checkbox__item-icon">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.73424 3.7002L4.60091 8.83353L2.26758 6.5002"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</div>
								<p className="checkbox__text">Save Password</p>
							</label>
						</div>
						<div className="save-info__reset">
							<Link to="/reset-password">Forgot password ?</Link>
						</div>
					</div>
					<div className="form-footer">
						<button
							disabled={!(isValid && dirty) || isSubmitting}
							type="submit"
							aria-label="form-submit"
							className="button button--big-height button--full-width"
						>
							Go to Log In
						</button>
					</div>
					<div className="form--bottom">
						Don`t have an account ? <Link to="/registration">Sign Up</Link>
					</div>
				</Form>
			)}
		</Formik>
	);
};
