import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import GBig from 'assets/custom-icons/cards/gBig.svg';
import { notificationContainer } from 'services/utils/notificationContainer';
import copy from 'assets/images/copied.svg';
import { shortNotation } from 'services/utils/textSlice';
import Astor from 'assets/images/gate/cardAmb/astro2.png';
import AstorMob from 'assets/images/gate/cardAmb/astroMob.png';
import leftTel from 'assets/images/gate/cardAmb/left-tel.svg';
import rightTel from 'assets/images/gate/cardAmb/right-reaclam.svg';
import astro from 'assets/images/gate/cardAmb/Astro-fly.svg';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import rocket from 'assets/images/gate/cardAmb/refRocket.svg';
import puzzlet from 'assets/images/gate/cardAmb/Turquoise-puzzle.svg';
import { getQrcode } from 'redux/reducers/auth/selectors';
import blPuzzle from 'assets/images/gate/cardAmb/pzBlure.svg';
import planetBl from 'assets/images/gate/cardAmb/planetBlure.svg';
import plusLeft from 'assets/images/gate/cardAmb/plusLeft.svg';
import starLeft from 'assets/images/gate/cardAmb/starLeft.png';
import cash from 'assets/images/gate/cardAmb/cash.png';
import cashTen from 'assets/images/gate/cardAmb/cashTen.png';
import { Table } from './Table';
/* eslint-disable no-debugger */

export const InviteFriends: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const qrcode = useSelector(getQrcode);
	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		dispatch(getUserReferralsInfoRequest());
	}, [dispatch]);

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<section id="plus" className="refer-section">
			<div className="bg-dessey" />
			<div className="background-box__foreground">
				<img className="pz-odessey5" src={starLeft} alt="" />
				<img className="pz-odessey2" src={blPuzzle} alt="" />
				<img className="pz-odessey3" src={planetBl} alt="" />
			</div>
			<div className="container container--relative">
				<div className="cash-back">
					<img className="pz-odessey6" src={plusLeft} alt="" />
					<img className="pz-odessey7" src={cash} alt="" />
					<img className="pz-odessey8" src={cashTen} alt="" />
				</div>
				<div className="refer-box">
					<div className="refer-top">
						<div className="refer-top--block">
							<h4 className="refer-top__title">Refer and earn!</h4>
							<p className="refer-top__link">
								Referral Link: <span>{shortNotation(String(user?.referral_link), 20, 5)} </span>
								{isCopied ? (
									<img className="refer-copy-img" src={copy} alt="copy" />
								) : (
									<CopyToClipboard text={String(user?.referral_link)} onCopy={handleCopy}>
										<button type="button" className="qrcode-block">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="17"
												height="16"
												viewBox="0 0 17 16"
												fill="none"
											>
												<path
													d="M16.9104 3.31353V11.266C16.9104 12.2203 16.3113 13.042 15.4416 13.4397C15.4513 13.3071 15.4609 13.1746 15.4609 13.0332V5.08074C15.4609 3.0131 13.6153 1.32541 11.3541 1.32541H3.26612C3.71063 0.539 4.59963 0 5.63358 0H13.2868C15.287 0 16.9104 1.48446 16.9104 3.31353ZM11.3541 2.65082H2.65735C1.19146 2.65082 0 3.74031 0 5.08074V13.0332C0 14.3736 1.19146 15.4631 2.65735 15.4631H11.3541C12.82 15.4631 14.0115 14.3736 14.0115 13.0332V5.08074C14.0115 3.74031 12.82 2.65082 11.3541 2.65082Z"
													fill="white"
												/>
											</svg>
										</button>
									</CopyToClipboard>
								)}
							</p>
						</div>
						<div className="refer-top--block refer-top--block-img">
							<img src={qrcode} alt="" />
						</div>
					</div>
					<div className="refer-bottom">
						<Table />
					</div>
				</div>
			</div>
			<img className="astor-fly" src={astro} alt="" />
			<img className="ref-rocket" src={rocket} alt="" />
			<img className="ref-puzle" src={puzzlet} alt="" />
		</section>
	);
};
