import { FC, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCardName } from 'redux/reducers/auth/reducer';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { http } from 'services/http';
import { downloadImgHref } from 'services/utils/toBase64';
import gatoecosystem from 'assets/images/ecosystem/ECOSYSTEM.png';
import heart from 'assets/images/gate/card/heart.png';
import share from 'assets/images/gate/card/share.png';
import blackshare from 'assets/images/gate/card/black-share.png';
import blackheart from 'assets/images/gate/card/black-heart.png';
import blackgato from 'assets/images/gate/card/blackgato.png';
import { getQrcode, getImgQrcode, blockBg } from 'redux/reducers/auth/selectors';
import { EcoHeader } from './EcoHeader';
import { TCard } from '../types';
import { SharePopup } from '../../sharePopup';

const REACT_APP_WEB = process.env.REACT_APP_WEB_API_URL;

/* eslint-disable */
export const CardsEcosystem: FC<TCard> = ({
	data,
	handlaClick,
	scale,
	setScale,
	setIsOpen,
	activeSlide,
}) => {
	const dispatch = useDispatch();
	const {
		title,
		footer_img,
		footer_text,
		center_img,
		background,
		bg_text,
		footer_description,
		name,
		id_img,
		footer_text_bottom,
		footer_text_sub_bottom,
		share_img,
	} = data;
	const [imageUrl, setImageUrl] = useState(null);
	const [scaleCard, setcaleCard] = useState(false);
	// const slideRef = useRef<HTMLDivElement>(null);

	const toggleSacle = () => {
		setcaleCard(!scaleCard);
	};

	const handleCardBlockClick = (event: React.MouseEvent<HTMLDivElement>) => {
		if (!event.currentTarget.closest('.swiper-slide')?.classList.contains('swiper-slide-active')) {
			event.stopPropagation();
			return;
		}
		setCardName(name);
		handlaClick?.(name);
	};
	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsOpen?.(true);
	};

	// const handleSlideClick = (event: React.MouseEvent<HTMLDivElement>) => {
	// 	if (!slideRef.current?.contains(event.target as Node)) {
	// 		handlaClick?.('');
	// 	}
	// };

	// useEffect(() => {
	// 	const handleOutsideClick = (event: MouseEvent) => {
	// 		if (slideRef.current && !slideRef.current.contains(event.target as Node)) {
	// 			handlaClick?.('');
	// 		}
	// 	};

	// 	document.addEventListener('click', handleOutsideClick);

	// 	return () => {
	// 		document.removeEventListener('click', handleOutsideClick);
	// 	};
	// }, []);

	return (
		<div
			style={{ background: `${String(background || 'transparent')}` }}
			className="card__container card__container--eco"
			// ref={slideRef}
			onClick={handleCardBlockClick}
		>
			<div className="card__top-wrapper">
				<EcoHeader handleChange={handleOPneCard} data={data} />
				<div className="card__center_img">
					<img src={center_img} alt="" />
				</div>
			</div>
			<div className="card__footer card__footer-eco">
				<p className="card__title">{title}</p>
				<p className="card__footer--description">{footer_description}</p>
				<div className="card__block">
					<span className="card__block-text">{footer_text}</span>
					<div className="card__footer-img">
						<img src={footer_img} alt="" />
					</div>
				</div>
				<div className="card__block--bottom">
					<span className="card__block--bottom-text">{footer_text_bottom}</span>
					<span className="card__block--sub-bottom-text">{footer_text_sub_bottom}</span>
				</div>
			</div>
			<div className="sroll-button-down" />
		</div>
	);
};
