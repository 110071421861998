import { FC, useEffect, useState } from 'react';
import useWindowResize from 'hooks/useWindowResize';
import { NavLink } from 'react-router-dom';
import Loader from 'ui/Loader';
import { EmptyTable } from 'layouts-elements/EmptyTable';
import { CloseEye, OpenEye } from 'assets/custom-icons';
import { useSelector, useDispatch } from 'react-redux';
import { getWalletLoader, getWalletsUser } from 'redux/reducers/wallets/selectors';
import { getAddress } from 'redux/reducers/connectWallet/selectors';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { getUserBalanceFailure, getWalletBalancesRequest } from 'redux/reducers/wallets/reducer';
import Pagination from 'ui/Pagination';
// import { networks } from 'services/utils/networks';
import PaginationTable from 'ui/PaginationTable';
import { WalletsItem } from './WalletsItem';
/* eslint-disable */

export const Wallet: FC = () => {
	const { width } = useWindowResize();
	const [resize, setResize] = useState(false);
	const [open, setOpne] = useState(false);
	const [openCard, setOpenCard] = useState<string | boolean>('' || false);
	console.log(openCard);
	// swiper-button-prev

	useEffect(() => {
		if (width && Number(width) <= 780) {
			setResize(true);
		} else {
			setResize(false);
		}
	}, [width]);

	const handleClick = () => {
		window.document.body.classList.remove('popup-card');
		setOpenCard('');
	};

	const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};

	const dispatch = useDispatch();
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const walletsUser = useSelector(getWalletsUser);
	const address = useSelector(getAddress);
	const walletLoader = useSelector(getWalletLoader);
	const totalPages = walletsUser?.balances.last_page || 1;
	const [hideBalances, setHideBalances] = useState(false);
	const [hideZeroBalances, setHideZeroBalances] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(1);

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			dispatch(
				getWalletBalancesRequest({
					address: address.wallet,
					hide_null: hideZeroBalances,
					current_page: String(currentPage),
					per_page: 15,
				}),
			);
		}

		dispatch(getUserBalanceFailure());
	}, [dispatch, isAuthenticated, address, hideZeroBalances, currentPage]);

	useEffect(() => {
		if (isAuthenticated && address.wallet) {
			if (walletsUser?.balances.data.length === 0 && currentPage > 1 && hideZeroBalances) {
				setCurrentPage(1);
			}
		}
	}, [
		dispatch,
		walletsUser?.balances.data,
		currentPage,
		isAuthenticated,
		address,
		hideZeroBalances,
	]);

	const handleHideBalances = () => {
		setHideBalances(!hideBalances);
	};
	const hideZeroBalance = () => {
		setHideZeroBalances(!hideZeroBalances);
	};
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
	};
	return (
		<div id="pig" className="pig" onClick={handleContentClick}>
			<div className="container container--relative">
				<div className="table-panel">
					<div className="table-panel__title">
						<h3 className="block-title block-title--smaller ">Wallet</h3>
					</div>
					{/* <div className="filter-right-block">
						<PaginationTable
							className="block-pagination--header"
							currentPage={currentPage}
							lastPage={Number(walletsUser?.balances.last_page)}
							setCurrentPage={setCurrentPage}
						/>
					</div> */}
				</div>
				<div className="overview-block overview-block--mt">
					<div className="overview-block__inner">
						<div className="overview-header">
							<div className="hide-balance">
								<p className="hide-balance__text">Total Estimated Balance</p>
								<button type="button" onClick={handleHideBalances} className="hide-btn">
									{hideBalances ? <OpenEye /> : <CloseEye />}
								</button>
							</div>
						</div>
						<div className="wallet-panel">
							<div className="wallet-balance wallet-balance--mb-0">
								<div className="receive-price">
									<p className="receive-price__number">
										{hideBalances ? `$${String(walletsUser?.balanceInUsd || 0)}` : '**********'}
									</p>
								</div>
							</div>
							<div className="gato-buttons gato-buttons--mt0">
								<a
									href="https://gatoscan.com/exchange"
									target="_blank"
									className="button button--full-width"
								>
									Buy
								</a>
								<a href="#" className="button button--transparent button--full-width ">
									Transfer
								</a>
								<a
									href="https://gatoscan.com/staking"
									target="_blank"
									className="button button--transparent button--full-width"
								>
									Stake
								</a>
							</div>
						</div>
					</div>
					<div className="balance-panel">
						<button onClick={hideZeroBalance} type="button" className="hide-balances">
							<span>
								<svg
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.9987 14.6667C11.6654 14.6667 14.6654 11.6667 14.6654 8.00004C14.6654 4.33337 11.6654 1.33337 7.9987 1.33337C4.33203 1.33337 1.33203 4.33337 1.33203 8.00004C1.33203 11.6667 4.33203 14.6667 7.9987 14.6667Z"
										stroke="#335BE9"
										strokeWidth="1.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									{hideZeroBalances && (
										<path
											className="active_balance"
											d="M5.16797 7.99995L7.05464 9.88661L10.8346 6.11328"
											stroke="#335BE9"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									)}
								</svg>
							</span>
							Hide 0 balances
						</button>
					</div>

					<div className="table table--wallet table-position">
						<div className="table-body">
							{walletsUser && walletsUser?.balances?.data.length ? (
								walletsUser?.balances?.data.map((item: any) => (
									<WalletsItem key={item.id} item={item} hideBalancesView={hideBalances} />
								))
							) : (
								<EmptyTable text="No Tokens Yet" />
							)}
						</div>
						{walletLoader && (
							<div className="table-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
