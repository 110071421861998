import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getQrcodeImgRequest, setBackgroundTrue } from 'redux/reducers/auth/reducer';
import heart from 'assets/images/gate/card/heart.png';
import share from 'assets/images/gate/card/share.png';
import { TextOdissay, Scan } from 'assets/custom-icons';
import Gadjet from 'assets/images/gate/cardAmb/Gadjet.svg';
import Astro from 'assets/images/gate/cardAmb/astro.svg';
import DownloadCard from 'assets/images/gate/cardAmb/download.svg';
import { getQrcode, getImgQrcode } from 'redux/reducers/auth/selectors';
import { TCard } from './types';

const REACT_APP_WEB = process.env.REACT_APP_WEB_API_URL;

/* eslint-disable */
export const CardsSoon: FC<TCard> = ({ data, handlaClick, scale, setScale }) => {
	const dispatch = useDispatch();
	const {
		title,
		footer_img,
		footer_text,
		center_img,
		background,
		bg_text,
		name,
		id_img,
		share_img,
		text_small,
	} = data;
	const qrcode = useSelector(getQrcode);
	const [isOpen, setIsOpen] = useState(false);
	const close = () => setIsOpen(false);
	const qrcodeImg = useSelector(getImgQrcode);
	const [scaleCard, setcaleCard] = useState(false);

	const toggleSacle = () => {
		setcaleCard(!scaleCard);
	};

	return (
		<div
			style={{ background: `${String(background || 'transparent')}` }}
			className="card__container card__container--type1"
		>
			<div className="card__bg_text">{bg_text}</div>
			<div className="card__header">
				<div className="card__header-top">
					<span className="card__logo-text">
						<img src={id_img} alt="" />
					</span>
				</div>
				<div className="card__header-text-img">COMING SOON</div>
			</div>
			<div className="card__center_img">
				<img src={center_img} alt="" />
			</div>
			<div className="card__footer">
				<div className={`card__title ${text_small ? `card__title--${text_small}` : ''}`}>
					{footer_text}
				</div>
			</div>
		</div>
	);
};
