import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Auth } from 'layouts/Auth';
import { Link } from 'react-router-dom';
import { CheckEmailReset } from 'components/Forms/CheckEmailReset';
import { IForgotPassSubmitValue } from 'components/Forms/ForgotPassForm/types';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { getForgotPassIsLoad } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';

export const CheckEmailResetPassword: FC = () => {
	const forgotPassIsLoad = useSelector(getForgotPassIsLoad);
	// const navigate = useNavigate();
	// const dispatch = useDispatch();
	// const handleSubmitEmail = (values: IForgotPassSubmitValue) => {
	// 	dispatch(forgotPasswordRequest({ email: values.email, navigate }));
	// };
	return (
		<Auth title="CheckEmail">
			<section className="authorization-section">
				<div className="container">
					<div className="authorization-box">
						<div className="authorization-form">
							<CheckEmailReset />
						</div>
					</div>
					{forgotPassIsLoad && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</section>
		</Auth>
	);
};
