/* eslint-disable */
import { FC, useState, useRef, useEffect } from 'react';
import Swiper from 'swiper';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { Home } from 'components/Home';
import { Dashboard } from 'components/Dashboard';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import useWindowResize from 'hooks/useWindowResize';
import { Page } from 'layouts/page';

export const HomePage: FC = () => {
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const [active, setActive] = useState(false);
	const [nameCard, setNameCard] = useState('');
	const [activeIndex, setActiveIndex] = useState('');
	const [resize, setResize] = useState(false);
	const swiperRef = useRef<Swiper | null>(null);
	console.log(nameCard);
	const { width } = useWindowResize();
	// useEffect(() => {
	// 	const btn: any = document.querySelector('.swiper-button-prev');
	// 	btn.addEventListener('click', () => {
	// 		debugger;
	// 	});
	// }, []);

	const settingsClover = {
		loop: true,
		speed: 1000,
		modules: [EffectCoverflow, Navigation, Pagination],
		effect: 'coverflow',
		grabCursor: true,
		centeredSlides: true,
		slidesPerView: 'auto',

		coverflowEffect: {
			rotate: 0,
			stretch: 20,
			depth: 150,
			modifier: 1,
			slideShadows: true,
		},
		pagination: {
			el: '.swiper-pagination',
			clickable: true,
			dynamicBullets: true,
			dynamicMainBullets: 3,
		},
		on: {
			slideChange(swiper: any) {
				setActiveIndex(swiper?.activeIndex);
			},
		},
		// init: false,
		// on: {
		// 	init() {
		// 		swiper.slideTo(7, 1, false);
		// 	},
		// },
		initialSlide: 3,
	};
	useEffect(() => {
		// let = params
		console.log(width);
		if (width && Number(width) <= 500) {
			setResize(true);
		} else {
			setResize(false);
		}
	}, [width]);

	useEffect(() => {
		setNameCard?.('');
	}, [activeIndex]);

	const initializeSwiper = () => {
		if (swiperRef.current) {
			swiperRef.current.destroy(true, true);
			swiperRef.current = new Swiper(
				'.swiper-container',
				resize
					? ({
							// ваші налаштування слайдера
							loop: true,
							speed: 1000,
							modules: [Navigation, Pagination],
							grabCursor: true,
							centeredSlides: true,
							slidesPerView: 1,
							pagination: {
								el: '.swiper-pagination',
								clickable: true,
								dynamicBullets: true,
								dynamicMainBullets: 3,
							},
							navigation: {
								prevEl: '.swiper-button-prev',
								nextEl: '.swiper-button-next',
							},
							on: {
								slideChange(swiper: any) {
									setActiveIndex(swiper?.activeIndex);
								},
							},

							initialSlide: 5,
					  } as any)
					: settingsClover,
			);
		}
	};

	const handleButtonClick = () => {
		initializeSwiper();
		setNameCard?.('');
	};

	const smothFunc = (value: string) => {
		console.log(value);
		setNameCard(value);

		if (!active && value) {
			setTimeout(() => {
				document.getElementById(value)?.scrollIntoView({ behavior: 'smooth' });
			}, 300);
		}
	};
	return (
		<Page active={active} nameCard={nameCard} title="Home" initSlider={handleButtonClick}>
			{isAuthenticated ? (
				<Dashboard
					active={active}
					setActive={setActive}
					setNameCard={smothFunc}
					swiperRef={swiperRef}
				/>
			) : (
				<Home />
			)}
		</Page>
	);
};
