// /* eslint-disable react-hooks/exhaustive-deps */
// import { FC } from 'react';
// import Popup from 'reactjs-popup';
// import { TPopupProps } from './types';
// /* eslint-disable */

// export const Modals: FC<TPopupProps> = ({
// 	openModal,
// 	setOpenModal,
// 	children,
// 	title,
// 	insideClass = '',
// 	paddingClass = '',
// }) => {
// 	const closeModal = () => setOpenModal(false);
// 	return (
// 		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
// 			<div className="popup-window popup-dropdawn">
// 				<div className={`popup-window__inside ${insideClass}`}>
// 					<div className={`popup popup-block ${openModal ? 'active' : ''}`}>
// 						<div className="popup-body popup-body--dropdown">{children}</div>
// 					</div>
// 				</div>
// 			</div>
// 		</Popup>
// 	);
// };
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */

import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { IModalProps } from './types';

export const Modal: FC<IModalProps> = ({
	isOpen,
	onClose, // the best thing -> useCallback in top component
	disableCloseOutside,
	disableCloseEsc,
	children,
	activeAnim,
	classIn,
}) => {
	return (
		<>
			{isOpen ? (
				<div className="popup-window popup-dropdawn">
					<div className="popup-window__inside popup-window--padding">
						<div
							className={`popup popup-block ${activeAnim ? 'active' : ''} ${
								classIn ? classIn : ''
							}`}
						>
							<div className="popup-body popup-body--dropdown">{children}</div>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
};
