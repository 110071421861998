import { FC, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import pazzle from 'assets/custom-icons/background/pazzle-center.png';
import { PazzleC } from 'assets/custom-icons';
import SocketConnectWrapper from 'components/SocketConnectWrapper';
import SocketWithTopicConnectWrapper from 'components/SocketWithTopicConnectWrapper';

export const Home: FC = () => {
	const numFormat = useMoneyFormat();
	const location = useLocation();
	const { search } = location;
	const [openModal, setOpenModal] = useState(false);
	// const transactionsList = transactions?.slice(0, 5);
	const referral = queryString.parse(search).referral || null;

	useEffect(() => {
		if (!referral) setOpenModal(true);
	}, [referral]);

	return (
		<section className="authorization-section authorization-section--type1">
			<div className="container">
				<div className="authorization-box">
					<img className="authorization-box--img" src={pazzle} alt="" />
					<div className="authorization-title">Unlimited Web3 Gate Connect </div>
					<div className="authorization-main-box">
						<Link to="/login" className="button button--big-height button--full-width">
							Sign in or create account
						</Link>
						<div className="authorization-main-box__options">
							See more sign in options.{' '}
							<a
								className="support--link"
								href="http://Support@gatochain.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								Need help?
							</a>
						</div>

						<button
							type="button"
							className="button button--big-height button--full-width button--type2"
						>
							Connect with GATO
						</button>
						<button
							type="button"
							className="button button--big-height button--full-width button--type2 button--mg"
						>
							What is Gato Gate?
						</button>
						{/* <div className="footer-social footer-social--main">
							<NavLink to="/" className="footer-social__item">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="19"
									height="24"
									viewBox="0 0 19 24"
									fill="none"
								>
									<path
										d="M12.8456 3.64046C13.747 2.67554 14.3566 1.33588 14.1897 0C12.8912 0.0463397 11.3175 0.762222 10.3855 1.72706C9.54994 2.58122 8.81845 3.94635 9.01367 5.25602C10.4626 5.35404 11.9428 4.60381 12.8456 3.64046ZM15.611 12.8661C15.5833 9.87117 17.9821 8.43275 18.0884 8.36324C16.7397 6.32947 14.6394 6.05119 13.8914 6.0198C12.103 5.83206 10.4043 7.10287 9.49629 7.10287C8.59201 7.10287 7.19327 6.04445 5.70999 6.07288C3.76003 6.10501 1.9633 7.24049 0.961819 9.0401C-1.06382 12.6604 0.442633 18.0234 2.41651 20.96C3.38142 22.3976 4.5303 24.0103 6.03897 23.9519C7.4953 23.8936 8.04431 22.9833 9.80136 22.9833C11.5591 22.9833 12.0513 23.9519 13.5891 23.9228C15.1539 23.8936 16.1442 22.4575 17.1009 21.0154C18.2079 19.3481 18.6642 17.734 18.6904 17.6525C18.6568 17.633 15.6431 16.4475 15.611 12.8661Z"
										fill="black"
									/>
								</svg>
							</NavLink>
							<NavLink to="/" className="footer-social__item">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M15.7721 5.94525L19.6596 2.76262C17.5814 1.03716 14.9117 0 12 0C7.36223 0 3.33895 2.63077 1.3418 6.48131H1.34245L1.34252 6.48137L1.34241 6.48135H1.34175C0.483938 8.13365 0 10.0101 0 12C0 14.0578 0.517594 15.9943 1.43011 17.6868L1.43013 17.6868C3.45696 21.4458 7.43013 24 12 24C14.8437 24 17.4561 23.011 19.5125 21.358L19.5126 21.3581C21.6271 19.6583 23.1534 17.2563 23.7376 14.5055C23.9097 13.6972 24 12.8592 24 12C24 11.2339 23.9281 10.4842 23.7903 9.75828H18.7741H12.2638V14.5055H18.6805C18.1029 16.0471 17.0064 17.3348 15.6034 18.1576L15.6033 18.1576C14.5464 18.778 13.3147 19.1335 12 19.1335C8.94133 19.1335 6.33231 17.2082 5.31892 14.5036L5.31889 14.5036L5.31891 14.5035C5.02617 13.7249 4.86661 12.8809 4.86652 12C4.86652 11.1884 5.00236 10.4084 5.25159 9.68179L5.25151 9.68161L5.25169 9.68175C6.21431 6.88017 8.87213 4.86656 12 4.86656C13.3853 4.86656 14.6783 5.26153 15.7721 5.94525Z"
										fill="black"
									/>
								</svg>
							</NavLink>
							<NavLink to="/" className="footer-social__item">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="28"
									height="19"
									viewBox="0 0 28 19"
									fill="none"
								>
									<path
										d="M19.6758 0C17.4278 0 15.6705 1.69312 14.0797 3.84394C11.8937 1.06058 10.0656 0 7.87781 0C3.4175 0 0 5.80473 0 11.9487C0 15.7934 1.86002 18.2183 4.97547 18.2183C7.2178 18.2183 8.83049 17.1611 11.6974 12.1497C11.6974 12.1497 12.8925 10.0392 13.7146 8.58547C14.0027 9.05062 14.3054 9.55106 14.6249 10.0891L15.9693 12.3507C18.5881 16.7331 20.0472 18.2182 22.6912 18.2182C25.7263 18.2182 27.4154 15.7602 27.4154 11.8355C27.4154 5.40266 23.9209 0 19.6758 0ZM9.51122 10.7928C7.1868 14.4365 6.3827 15.2531 5.08856 15.2531C3.75671 15.2531 2.9652 14.0839 2.9652 11.999C2.9652 7.5386 5.18912 2.97773 7.84016 2.97773C9.27578 2.97773 10.4755 3.80683 12.3132 6.43764C10.5682 9.11413 9.51122 10.7928 9.51122 10.7928ZM18.2838 10.3341L16.6764 7.65329C16.2414 6.94585 15.8246 6.29575 15.4223 5.69973C16.8711 3.46371 18.0661 2.34953 19.4873 2.34953C22.4399 2.34953 24.8021 6.6968 24.8021 12.0367C24.8021 14.0721 24.1354 15.2531 22.7541 15.2531C21.4301 15.2531 20.7976 14.3787 18.2838 10.3341Z"
										fill="black"
									/>
								</svg>
							</NavLink>
							<NavLink to="/" className="footer-social__item">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
								>
									<path
										d="M23.9983 23.9998V15.2098C23.9983 10.8898 23.0683 7.58984 18.0283 7.58984C15.5983 7.58984 13.9783 8.90984 13.3183 10.1698H13.2583V7.97984H8.48828V23.9998H13.4683V16.0498C13.4683 13.9498 13.8583 11.9398 16.4383 11.9398C18.9883 11.9398 19.0183 14.3098 19.0183 16.1698V23.9698H23.9983V23.9998Z"
										fill="black"
									/>
									<path d="M0.390625 7.98047H5.37062V24.0005H0.390625V7.98047Z" fill="black" />
									<path
										d="M2.88 0C1.29 0 0 1.29 0 2.88C0 4.47 1.29 5.79 2.88 5.79C4.47 5.79 5.76 4.47 5.76 2.88C5.76 1.29 4.47 0 2.88 0Z"
										fill="black"
									/>
								</svg>
							</NavLink>
						</div> */}
					</div>
				</div>
			</div>
		</section>
	);
};
