export const cardContent = [
	{
		id: 0,
		text: 'Gato Gate serves as the central hub for efficient operations on Gatochain, providing a fast track to success. Your Gato Gate account provides you access to all the projects and functions within the Gato ecosystem, through which you can earn transactional rewards too.',
		btn: false,
	},
	{
		id: 1,
		text: 'Once you create your Gato Gate account, a QR code is automatically generated, which can be distributed, allowing you to establish a legacy. You can also transform your business ideas and startups into dynamic Web 3 spaces focused on regenerative finance, wealth management, and endless legacy building. This is an opportunity to earn from all the active blockchain projects within the Gato ecosystem. Dive into a world of innovation by logging in to Gato Gate today.',
		btn: true,
	},
];
