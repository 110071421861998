import { FC, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
// import loginAvatar from 'assets/images/avatars/default-avatar.png';
import { useWeb3Modal } from '@web3modal/react';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
/* eslint-disable */
export const AuthHeader: FC = () => {
	const user = useSelector(getUserSettingsData);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { open, close } = useWeb3Modal();
	useEffect(() => {
		if (!user) {
			dispatch(userSettingsRequest());
		}
	}, [dispatch, user]);
	const handleSelect = (): void => {
		dispatch(logoutRequest({ navigate }));

		// navigate(link);
	};
	return (
		<div className="header-right">
			<div className="autorized">
				<NavLink to="/profile" className="avatar-box">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32"
						height="32"
						viewBox="0 0 32 32"
						fill="none"
					>
						<path
							d="M26.6575 20.1892C24.9567 18.4885 22.9323 17.2294 20.7235 16.4672C23.0892 14.8379 24.6433 12.1111 24.6433 9.02776C24.6433 4.04986 20.5935 0 15.6156 0C10.6377 0 6.58782 4.04986 6.58782 9.02776C6.58782 12.1111 8.142 14.8379 10.5077 16.4672C8.29895 17.2294 6.27453 18.4885 4.57372 20.1892C1.62433 23.1387 0 27.0601 0 31.2312H2.43993C2.43993 23.9661 8.35049 18.0555 15.6156 18.0555C22.8807 18.0555 28.7912 23.9661 28.7912 31.2312H31.2312C31.2312 27.0601 29.6068 23.1387 26.6575 20.1892ZM15.6156 15.6156C11.9831 15.6156 9.02776 12.6603 9.02776 9.02776C9.02776 5.39518 11.9831 2.43993 15.6156 2.43993C19.2481 2.43993 22.2034 5.39518 22.2034 9.02776C22.2034 12.6603 19.2481 15.6156 15.6156 15.6156Z"
							fill="#D9F343"
						/>
					</svg>
				</NavLink>
				<button type="button" onClick={() => open()} className="wallet-icon-box">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="51"
						height="32"
						viewBox="0 0 51 32"
						fill="none"
					>
						<path
							d="M50.6834 16.9822C50.6171 17.316 50.4505 17.6212 50.2059 17.8567C48.8351 19.2301 47.4667 20.6061 46.1007 21.9847C43.0236 25.0793 39.9449 28.1742 36.8645 31.2692C36.5123 31.6236 36.1309 31.9073 35.6078 31.7174C35.3882 31.63 35.1895 31.4968 35.0248 31.3266C32.7013 28.9992 30.3816 26.6675 28.0657 24.3313C27.2649 23.5261 26.4616 22.7231 25.6557 21.9223C25.3513 21.6187 25.271 21.6187 24.9606 21.9289C21.8641 25.039 18.7672 28.1488 15.67 31.2581C15.5106 31.4177 15.3326 31.5805 15.1326 31.6738C14.6381 31.9056 14.2106 31.729 13.8457 31.3608C13.2479 30.7536 12.6533 30.1463 12.0522 29.5391C8.21353 25.6864 4.37377 21.834 0.532908 17.982C0.462025 17.9108 0.391692 17.839 0.324106 17.7639C-0.111631 17.2831 -0.111631 16.6775 0.346085 16.2133C1.32379 15.2196 2.30718 14.2339 3.29624 13.2561C3.83089 12.7273 4.41828 12.746 4.98259 13.308C6.11837 14.4396 7.2448 15.5812 8.37563 16.7173C10.3724 18.7237 12.3689 20.7294 14.365 22.7343C14.6677 23.0385 14.7468 23.0374 15.0568 22.7255L23.6028 14.1305C23.8611 13.8705 24.1188 13.6099 24.3803 13.3532C25.0331 12.714 25.5892 12.7118 26.2288 13.3532C28.7644 15.8995 31.2981 18.4462 33.8297 20.9932C34.4111 21.5773 34.993 22.1611 35.5754 22.7448C35.8639 23.0335 35.9601 23.0335 36.2502 22.7399C39.3379 19.6371 42.4262 16.5346 45.515 13.4322C45.6769 13.2592 45.8643 13.1122 46.0705 12.9961C46.565 12.7416 46.9909 12.9216 47.3568 13.2859C48.0895 14.0168 48.8201 14.7502 49.5487 15.4863C49.8064 15.7457 50.0855 15.9892 50.3108 16.2751C50.4729 16.481 50.5619 16.7427 50.6834 16.9822Z"
							fill="white"
						/>
						<path
							d="M25.2013 0C27.8773 0.00940923 30.2742 0.43836 32.5899 1.31674C35.6782 2.48846 38.3433 4.31662 40.6184 6.7442C40.7679 6.9036 40.9272 7.05415 41.074 7.21577C41.5552 7.7449 41.5535 8.30945 41.0545 8.82641C40.3085 9.60128 39.5532 10.3634 38.8017 11.1306C38.4896 11.4494 38.1786 11.7698 37.8644 12.0864C37.497 12.4556 37.2462 12.4545 36.8702 12.0864C36.1767 11.4034 35.5059 10.6939 34.7858 10.0413C33.2769 8.6742 31.5301 7.73328 29.6002 7.13994C27.8843 6.61413 26.1327 6.3944 24.3447 6.54163C20.9487 6.82169 17.9763 8.10522 15.484 10.5007C14.92 11.0426 14.3798 11.611 13.8267 12.165C13.4203 12.5724 13.2009 12.5713 12.7972 12.1617C11.7331 11.0758 10.6699 9.98891 9.60762 8.90113C9.14817 8.42845 9.12921 7.83346 9.57782 7.35137C10.9865 5.84202 12.477 4.43285 14.2195 3.32478C16.054 2.15803 18.0034 1.26859 20.0975 0.704585C21.764 0.256504 23.4782 0.0198596 25.2013 0Z"
							fill="white"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};
