import { FC, useEffect } from 'react';
import LoadingBar from 'react-redux-loading-bar';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import useWalletConnect from 'hooks/useWalletConnect';
import NavigateList from 'routes/routesList';

const App: FC = () => {
	useWalletConnect();
	return (
		<BrowserRouter>
			{/* <LoadingBar className="loading-bar" /> */}
			<NavigateList />
		</BrowserRouter>
	);
};

export default App;
