import { FC } from 'react';
import { Jornay } from 'components/Jornay';
import { Odyssey } from 'components/Odyssey';
import { Wallet } from 'components/Wallet';
import { InviteFriends } from 'components/InviteFriends';
import { EcoTools } from 'components/EcoTools';
import { EcoContacts } from 'components/EcoContacts';
import { EcoMine } from 'components/EcoMine';
import { OxExchange } from 'components/OxExchange';
import { OxSoon } from 'components/OxSoon';
import { CryptoEstate } from 'components/CryptoEstate';
import { StableCoin } from 'components/StableCoin';
import { DesertTech } from 'components/DesertTech';
import { Fractionaire } from 'components/Fractionaire';
import { GatoCoin } from 'components/GatoCoin';
import { IContent } from './types';
/* eslint-disable */

export const ContentWrapper: FC<IContent> = ({ nameCard }) => {
	const itemCard: any = {
		explore: <Jornay />,
		odyssey: <Odyssey />,
		pig: <Wallet />,
		plus: <InviteFriends />,
		[`ecosystem--gate`]: <EcoTools />,
		[`ecosystem--contacts`]: <EcoContacts />,
		[`ecosystem--mine`]: <EcoMine />,
		[`ecosystem--exchange`]: <OxExchange />,
		[`ecosystem--soon`]: <OxSoon />,
		[`ecosystem--domino`]: <CryptoEstate />,
		[`ecosystem--guds`]: <StableCoin />,
		[`ecosystem--desert`]: <DesertTech />,
		[`ecosystem--farti`]: <Fractionaire />,
		[`ecosystem--coin`]: <GatoCoin />,
	};
	return <div>{nameCard && itemCard[nameCard]}</div>;
};
