const routes = {
	home: '/',
	login: '/login',
	register: '/registration',
	emailConfirmation: '/confirmation/email',
	forgot: '/reset-password',
	createNewPassword: '/recovery_password',
	login2FA: '/two-factor',
	logout: '/logout',
	checkEmail: '/check-email',
	profile: '/profile',
	profileSettings: '/account-settings',
	profileDetails: '/account-details',
	accountLanguage: '/account-language',
	security: `/security`,
	information: '/information',
	twoFactor: '/two-factor',
	referral: `/referral`,
};
export default routes;
