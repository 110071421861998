import { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getQrcodeImgRequest, setBackgroundTrue } from 'redux/reducers/auth/reducer';
import heart from 'assets/images/gate/card/heart.png';
import share from 'assets/images/gate/card/share.png';
import { TextOdissay, Scan } from 'assets/custom-icons';
import Gadjet from 'assets/images/gate/cardAmb/Gadjet.svg';
import { downloadImgLink } from 'services/utils/toBase64';
import Astro from 'assets/images/gate/cardAmb/astro.svg';
import DownloadCard from 'assets/images/gate/cardAmb/download.svg';
import { getQrcode, getImgQrcode } from 'redux/reducers/auth/selectors';
import { TCard } from './types';

const REACT_APP_WEB = process.env.REACT_APP_WEB_API_URL;

/* eslint-disable */
export const CardsQrCode: FC<TCard> = ({
	data,
	handlaClick,
	scale,
	setScale,
	setIsOpen,
	activeSlide,
}) => {
	const dispatch = useDispatch();
	const {
		title,
		footer_img,
		footer_text,
		center_img,
		background,
		bg_text,
		name,
		id_img,
		share_img,
	} = data;
	const qrcode = useSelector(getQrcode);
	const qrcodeImg = useSelector(getImgQrcode);
	const [scaleCard, setcaleCard] = useState(false);

	const handleCardBlockClick = (event: React.MouseEvent<HTMLDivElement>) => {
		// event.stopPropagation();
		if (!event.currentTarget.closest('.swiper-slide')?.classList.contains('swiper-slide-active')) {
			
			event.stopPropagation();
			return;
		}

		handlaClick?.(name);

		
	};
	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setIsOpen?.(true);
	};

	const handleDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		dispatch(
			getQrcodeImgRequest({
				is_download: 1,
				onOpenPdf: (data) => {
					if (data && REACT_APP_WEB) {
						downloadImgLink(data);
					}
				},
			}),
		);
	};

	const toggleSacle = () => {
		setcaleCard(!scaleCard);
	};

	return (
		
			<div
				onClick={handleCardBlockClick}
				style={{ background: `${String(background || 'transparent')}` }}
				className="card__container card__container--type1"
			>
				<div className="card__bg_text">{bg_text}</div>
				<div className="card__header">
					<div className="card__header-top">
						<span className="card__heart">
							<img src={heart} alt="" />
						</span>
						<span className="card__logo-text">
							<img src={id_img} alt="" />
						</span>
						<button onClick={handleOPneCard} type="button" className="card__share">
							<img src={share} alt="" />
						</button>
					</div>
					<div className="card__header-text-img">JOIN GATO WEB3 ODYSSEY & UNLOCK DEFI EARNINGS</div>
				</div>
				<div className="card__center_img">
					<img src={Astro} alt="" />
				</div>
				<div className="card__footer">
					<div className="card__footer-bg-bloor" />
					<div className="card-scan__type1">
						<div className="card__qr-code">
							<img src={qrcode} alt="qrcode" />{' '}
						</div>
						<Scan className="card-scan" />
					</div>
					<div className="card_type1__block">
						<div className="card_type1__footer-img">
							<img src={Gadjet} alt="" />
						</div>
						{/* <a className="footer__download" href={data} download="referral-img-4.pdf">
							<img src="data:image/png;base64" alt="Download PDF" />
						</a> */}
						{qrcodeImg ? (
							<button className="footer__download" type="button" onClick={handleDownload}>
								<img src={DownloadCard} alt="" />
							</button>
						) : null}
					</div>
				</div>
				<div className="sroll-button-down" />
			</div>
			
		
	);
};
