import { FC } from 'react';
import { NavLink } from 'react-router-dom';

export const AccountSettings: FC = () => {
	return (
		<section className="general-section account--settings">
			<div className="container">
				<div className="inner-block">
					<div className="general-block account--language">
						<NavLink
							to="/account-details"
							className="account-change account-change--security account-change--center"
						>
							<span className="account-change__icon">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M21.0802 9.08003V15.92C21.0802 17.04 20.4802 18.08 19.5102 18.65L13.5702 22.08C12.6002 22.64 11.4002 22.64 10.4202 22.08L4.48016 18.65C3.51016 18.09 2.91016 17.05 2.91016 15.92V9.08003C2.91016 7.96003 3.51016 6.91999 4.48016 6.34999L10.4202 2.92C11.3902 2.36 12.5902 2.36 13.5702 2.92L19.5102 6.34999C20.4802 6.91999 21.0802 7.95003 21.0802 9.08003Z"
										fill="#D9EF0A"
									/>
									<path
										d="M12.0019 12.4998C13.2887 12.4998 14.3319 11.4566 14.3319 10.1698C14.3319 8.88298 13.2887 7.83984 12.0019 7.83984C10.7151 7.83984 9.67188 8.88298 9.67188 10.1698C9.67188 11.4566 10.7151 12.4998 12.0019 12.4998Z"
										fill="#D9EF0A"
									/>
									<path
										d="M14.6811 17.1584C15.4911 17.1584 15.9611 16.2584 15.5111 15.5884C14.8311 14.5784 13.5111 13.8984 12.0011 13.8984C10.4911 13.8984 9.17114 14.5784 8.49114 15.5884C8.04114 16.2584 8.51114 17.1584 9.32114 17.1584H14.6811Z"
										fill="#D9EF0A"
									/>
								</svg>
							</span>
							<span className="account-change__info">
								<span className="account-change__item">Account details</span>
							</span>
							<span className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</NavLink>
						<div className="account-change-line line--rocket">
							<svg
								width="312"
								height="17"
								viewBox="0 0 312 17"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.5 8.50488H140.5H311.5"
									stroke="white"
									strokeOpacity="0.5"
									strokeDasharray="2.1 4.1"
								/>
								<path
									d="M166.551 8.65043C166.577 8.6117 166.591 8.5665 166.591 8.52015C166.592 8.47379 166.579 8.42819 166.555 8.3887C164.949 5.74272 158.184 2.68254 152.862 2.68145C151.916 2.67762 150.972 2.7562 150.04 2.91632C149.292 2.02295 148.41 1.26281 147.406 0.868217C144.564 -0.236787 141.722 1.4552 140.587 2.27016C140.525 2.31445 140.473 2.37262 140.437 2.4401C140.401 2.50759 140.381 2.58254 140.379 2.65906C140.377 2.73559 140.392 2.8116 140.424 2.88112C140.456 2.95064 140.504 3.01177 140.564 3.05971L144.083 5.87957C143.846 6.12079 143.622 6.3739 143.411 6.6379C143.262 6.81877 143.181 7.04611 143.182 7.28043L143.185 9.7322C143.184 9.96648 143.265 10.1937 143.414 10.3747C143.623 10.6373 143.846 10.8891 144.082 11.1291L140.568 13.9468C140.509 13.9948 140.461 14.0559 140.429 14.1254C140.397 14.1949 140.382 14.2709 140.384 14.3474C140.386 14.4239 140.406 14.4988 140.442 14.5663C140.478 14.6338 140.53 14.6919 140.592 14.7363C141.721 15.5534 144.567 17.2494 147.404 16.1393C148.408 15.7447 149.287 14.9904 150.034 14.1032C150.967 14.2646 151.913 14.3444 152.86 14.3417C158.186 14.3551 164.831 11.3941 166.551 8.65043ZM153.365 8.50886C153.365 8.02142 153.509 7.54488 153.78 7.13952C154.051 6.73416 154.436 6.41819 154.886 6.23159C155.336 6.04498 155.832 5.99611 156.31 6.09116C156.788 6.18622 157.227 6.42093 157.572 6.7656C157.917 7.11027 158.151 7.54943 158.246 8.02751C158.341 8.50559 158.292 9.00112 158.106 9.45143C157.919 9.90174 157.603 10.2866 157.198 10.5573C156.793 10.828 156.316 10.9724 155.829 10.9722C155.505 10.9725 155.185 10.9089 154.886 10.7852C154.587 10.6615 154.315 10.48 154.086 10.2513C153.857 10.0225 153.676 9.7508 153.552 9.45183C153.429 9.15285 153.365 8.83242 153.365 8.50886Z"
									fill="white"
								/>
								<path
									d="M142.215 10.5355C141.816 10.5359 141.42 10.2945 140.969 9.97687C139.959 9.26102 139.943 7.75343 140.97 7.03613C141.36 6.76459 141.999 6.476 142.216 6.476C142.264 6.47646 142.31 6.46205 142.348 6.43483C142.387 6.4076 142.416 6.36894 142.431 6.32433C142.446 6.27972 142.447 6.23144 142.433 6.18633C142.419 6.14122 142.392 6.10159 142.354 6.07306C141.813 5.64957 141.145 5.41943 140.458 5.41928C138.75 5.42 135.5 8.50886 135.5 8.50886C135.5 8.50886 138.752 11.5995 140.459 11.5988C141.148 11.6 141.816 11.3694 142.358 10.9443C142.523 10.806 142.428 10.5308 142.215 10.5355Z"
									fill="white"
								/>
							</svg>
						</div>
						<NavLink to="/account-language" className="account-change" type="button">
							<span className="account-change__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										opacity="0.4"
										d="M7.64859 20.9101C7.61859 20.9101 7.57859 20.9301 7.54859 20.9301C5.60859 19.9701 4.02859 18.3801 3.05859 16.4401C3.05859 16.4101 3.07859 16.3701 3.07859 16.3401C4.29859 16.7001 5.55859 16.9701 6.80859 17.1801C7.02859 18.4401 7.28859 19.6901 7.64859 20.9101Z"
										fill="#D9EF0A"
									/>
									<path
										opacity="0.4"
										d="M20.9391 16.4501C19.9491 18.4401 18.2991 20.0501 16.2891 21.0201C16.6691 19.7501 16.9891 18.4701 17.1991 17.1801C18.4591 16.9701 19.6991 16.7001 20.9191 16.3401C20.9091 16.3801 20.9391 16.4201 20.9391 16.4501Z"
										fill="#D9EF0A"
									/>
									<path
										opacity="0.4"
										d="M21.0191 7.70998C19.7591 7.32998 18.4891 7.01998 17.1991 6.79998C16.9891 5.50998 16.6791 4.22998 16.2891 2.97998C18.3591 3.96998 20.0291 5.63998 21.0191 7.70998Z"
										fill="#D9EF0A"
									/>
									<path
										opacity="0.4"
										d="M7.65047 3.09006C7.29047 4.31006 7.03047 5.55006 6.82047 6.81006C5.53047 7.01006 4.25047 7.33006 2.98047 7.71006C3.95047 5.70006 5.56047 4.05006 7.55047 3.06006C7.58047 3.06006 7.62047 3.09006 7.65047 3.09006Z"
										fill="#D9EF0A"
									/>
									<path
										d="M15.4917 6.59C13.1717 6.33 10.8317 6.33 8.51172 6.59C8.76172 5.22 9.08172 3.85 9.53172 2.53C9.55172 2.45 9.54172 2.39 9.55172 2.31C10.3417 2.12 11.1517 2 12.0017 2C12.8417 2 13.6617 2.12 14.4417 2.31C14.4517 2.39 14.4517 2.45 14.4717 2.53C14.9217 3.86 15.2417 5.22 15.4917 6.59Z"
										fill="#D9EF0A"
									/>
									<path
										d="M6.59 15.49C5.21 15.24 3.85 14.92 2.53 14.47C2.45 14.45 2.39 14.46 2.31 14.45C2.12 13.66 2 12.85 2 12C2 11.16 2.12 10.34 2.31 9.56001C2.39 9.55001 2.45 9.55001 2.53 9.53001C3.86 9.09001 5.21 8.76001 6.59 8.51001C6.34 10.83 6.34 13.17 6.59 15.49Z"
										fill="#D9EF0A"
									/>
									<path
										d="M22.0002 12C22.0002 12.85 21.8802 13.66 21.6902 14.45C21.6102 14.46 21.5502 14.45 21.4702 14.47C20.1402 14.91 18.7802 15.24 17.4102 15.49C17.6702 13.17 17.6702 10.83 17.4102 8.51001C18.7802 8.76001 20.1502 9.08001 21.4702 9.53001C21.5502 9.55001 21.6102 9.56001 21.6902 9.56001C21.8802 10.35 22.0002 11.16 22.0002 12Z"
										fill="#D9EF0A"
									/>
									<path
										d="M15.4917 17.4099C15.2417 18.7899 14.9217 20.1499 14.4717 21.4699C14.4517 21.5499 14.4517 21.6099 14.4417 21.6899C13.6617 21.8799 12.8417 21.9999 12.0017 21.9999C11.1517 21.9999 10.3417 21.8799 9.55172 21.6899C9.54172 21.6099 9.55172 21.5499 9.53172 21.4699C9.09172 20.1399 8.76172 18.7899 8.51172 17.4099C9.67172 17.5399 10.8317 17.6299 12.0017 17.6299C13.1717 17.6299 14.3417 17.5399 15.4917 17.4099Z"
										fill="#D9EF0A"
									/>
									<path
										d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
										fill="#D9EF0A"
									/>
								</svg>
							</span>

							<span className="account-change__info">
								<span className="account-change__subtitle">Language</span>
								<span className="account-change__item">English</span>
							</span>

							<span className="account-change__arrow">
								<svg
									width="24"
									height="25"
									viewBox="0 0 24 25"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.94922 4.57992L15.4692 11.0999C16.2392 11.8699 16.2392 13.1299 15.4692 13.8999L8.94922 20.4199"
										stroke="#8E8EA9"
										strokeWidth="1.2"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
						</NavLink>
					</div>

					<button className="button button--big-height button--full-width button--mt" type="button">
						Save
					</button>
				</div>
			</div>
		</section>
	);
};
