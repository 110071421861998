/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConnectStore, TBalancesPayload, TWallets } from './types';

// ==========================================:
export const initialState: IConnectStore = {
	wallet: {
		auth: null,
		wallet: '' || null || undefined,
		loading: false,
		error: null || undefined,
		active: false,
		type: null,
		account: '' || null || undefined,
	},
	balances: [],
};

// ==========================================:
const connectWallet = createSlice({
	name: '@@connectWallet',
	initialState,
	reducers: {
		setAddress: (state, action: PayloadAction<TWallets>) => {
			const connectState = state;
			const { payload } = action;

			connectState.wallet = {
				...connectState.wallet,
				wallet: payload.account,
				loading: payload.loading,
				active: payload.active,
				auth: null,
				error: {},
			};
		},
		AddWalletAddressSuccess: (state, action: PayloadAction<TWallets & TBalancesPayload>) => {
			const connectState = state;
			const { payload } = action;

			connectState.wallet = {
				...connectState.wallet,
				wallet: payload.account,
				loading: false,
				active: payload.active,
				type: payload?.type,
				auth: null,
				error: {},
			};
			connectState.balances = payload.balances;
		},
		setLogoutWallet: (state) => {
			const connectState = state;
			connectState.wallet = initialState.wallet;
		},
		connectInitState: () => initialState,
	},
});

export default connectWallet.reducer;
export const { setAddress, connectInitState, setLogoutWallet, AddWalletAddressSuccess } =
	connectWallet.actions;
