import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Helmet from 'react-helmet';
import { TextBig } from 'assets/custom-icons';
import { Background } from 'layouts-elements/Background';
import { BackgroundLogin } from 'layouts-elements/Background/BackgroundLogin';
import { useLocation } from 'react-router';
import HeaderMenu from 'layouts-elements/Header/HeaderMenu';
import { APP_NAME } from 'services/constants/env';
import { ContentWrapper } from 'components/ContentWrapper';
import { BackgroundWrapper } from 'components/BackgroundWrapper';
import Footer from 'layouts/Footer';
import { Header } from 'layouts-elements/Header';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
// import { getTheme } from 'redux/reducers/settings/selectors';
import { NotificationInfo } from 'layouts-elements/Notifications/NotificationInfo';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({
	children,
	title,
	link,
	text,
	active,
	nameCard,
	initSlider,
}) => {
	const pageTitle = title ? `| ${title}` : '';
	const pageText = text || '';
	const pageLink = link || '';
	const location = useLocation();
	const isAuthenticated = useSelector(getAuthIsAuthenticated);
	const [open, setOpen] = useState(false);

	console.log(location.pathname);

	useEffect(() => {
		if (location.pathname === '/') {
			document.body.className = 'black';
			// document.body.classList.remove('black');
		}
		// return document.body.classList.remove('black');
	}, [location]);

	// const theme = useSelector(getTheme);
	console.log(nameCard);

	return (
		<>
			{/* <Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet> */}
			<Header open={open} setOpen={setOpen} initSlider={initSlider} />
			<HeaderMenu open={open} setOpen={setOpen} />
			<div className="wrapper">
				{isAuthenticated ? <BackgroundWrapper nameCard={nameCard} /> : <Background />}
				<main className="main">
					<TextBig className="main__text" />
					{children}
				</main>
				{isAuthenticated ? <ContentWrapper nameCard={nameCard} /> : null}
				<Footer />
			</div>
		</>
	);
};
