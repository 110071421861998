/* eslint-disable */
import { FC, useEffect, useRef, useState } from 'react';
import { cardsItem } from './constants';
import { Cards } from './Card/Cards';
import { register } from 'swiper/element/bundle';
import { SliderItem } from 'layouts-elements/SliderItem';
import useWindowResize from 'hooks/useWindowResize';
import Swiper from 'swiper';
import { ISlide } from './types';
// import {
// 	Swiper,
// 	SwiperSlide,
// 	useSwiperSlide,
// 	SwiperRef,
// 	useSwiper,
// 	SwiperProps,
// } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
// import SwiperMob from 'react-id-swiper';

export const SliderMobile: FC<ISlide> = ({ handlaOpenCard, scale, setScale, swiperRef }) => {
	// debugger;
	const [activeIndex, setActiveIndex] = useState('');
	const { width } = useWindowResize();
	const [swiperArr, setSwiperArr] = useState('');

	useEffect(() => {
		handlaOpenCard?.('');
	}, [activeIndex]);

	console.log(activeIndex);
	let params: any = {
		grabCursor: true,
		centeredSlides: true,
		slidesPerView: 1,
		width: 338,
		pagination: {
			clickable: true,
			// el: '.my-custom-pagination ',
			dynamicBullets: true,
			dynamicMainBullets: 3,
		},
		navigation: {
			nextEl: '.swiper-button-next swiper-button-disabled',
			prevEl: '.swiper-button-prev swiper-button-disabled',
		},

		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				spaceBetween: 30,
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 1,
				spaceBetween: 60,
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 3,
				spaceBetween: 80,
			},
		},
	};

	// const handlaClick = (item: string) => {
	// 	if (item === 'explore') {
	// 		setActive(!active);
	// 	}
	// };

	// const swiperRef = useRef(null);

	// useEffect(() => {
	// 	// Register Swiper web component
	// 	// Register Swiper web component
	// 	register();
	// 	if (swiperRef.current) {
	// 		// Add event listener
	// 		swiperRef.current?.addEventListener('swiperslidechange', (e:any) => {
	// 			console.log(e.detail);
	// 		});

	// 		// Object with parameters
	// 		const params = {
	// 			// or pass it in on
	// 			on: {
	// 				slideChange(s: any) {
	// 					console.log(s);
	// 				},
	// 			},
	// 		};

	// 		// Assign it to swiper element
	// 		if (swiperRef.current) {
	// 			Object.assign(swiperRef.current, params);

	// 			// initialize swiper
	// 			// swiperRef.current?.initialize();
	// 		}
	// 	}
	// }, []);
	// const setSliderm = () => {
	// 	swipr?.init();
	// 	console.log('try');
	// };

	useEffect(() => {
		if (swiperRef) {
			swiperRef.current = new Swiper('.mySwiper', {
				loop: true,
				speed: 1000,
				modules: [Navigation, Pagination],
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 1,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3,
				},
				navigation: {
					prevEl: '.swiper-button-prev',
					nextEl: '.swiper-button-next',
				},
				on: {
					slideChange(swiper: any) {
						setActiveIndex(swiper?.activeIndex);
						// const activeSlide1 = swiper?.slides?.find((item: any) => {
						// 	return item.childNodes[0].className.includes('card card__soon swiper-slide')
						// });
						console.log(swiper.slides);
						const activeSlide = swiper?.slides?.find((item: any) => {
							// console.log(item.className.include('swiper-slide-active'));
							return item.className.includes('swiper-slide-active');
						});
						if (activeSlide) {
							setSwiperArr(activeSlide.className);
						}
					},
				},

				initialSlide: 5,
			});
		}
	}, [swiperRef]);

	// useEffect(() => {
	// 	swiperRef.current = new Swiper('.swiper-container', {
	// 		loop: true,
	// 	});
	// }, [swiperRef]);
	return (
		<>
			{/* <button
				style={{ color: 'white', width: '20px', height: '20px' }}
				type="button"
				onClick={setSliderm}
			>
				press me
			</button> */}
			{/* <Swiper
				modules={[Pagination, Navigation]}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={1}
				speed={800}
				initialSlide={5}
				loop={true}
				pagination={{
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3,
				}}
				onSlideChange={(swiper: any) => setActiveIndex(swiper?.activeIndex)}
				navigation={true}
				className="swiper-container"
				// ref={swiperRef}
			> */}
			<div className="swiper mySwiper swiper-container home-slider">
				<div className="swiper-wrapper">
					{cardsItem.map((item, index) => (
						// <SwiperSlide key={index}>
						<Cards
							key={index}
							data={item}
							handlaClick={handlaOpenCard}
							// activeSlide={String(activeSl)}
						/>
						// <div key={index} className="swiper-slide">
						// 	<Cards data={item} handlaClick={handlaOpenCard} />
						// </div>
						// </SwiperSlide>
					))}
				</div>
				<div className="swiper-button-prev"></div>
				<div className="swiper-button-next"></div>
				<div className="swiper-pagination"></div>
			</div>
			{/* </Swiper> */}
		</>
	);
};
