import { Security } from 'components/Profile/Security';
import { PageProfile } from 'layouts/page/Profile';
import { FC } from 'react';

export const SecurityPage: FC = () => {
	return (
		<PageProfile title="Profile" text="Security" link="/security">
			<Security />
		</PageProfile>
	);
};
