import { FC, useEffect, useState } from 'react';
import { DownloadAll } from 'assets/custom-icons';
import download from 'assets/images/gate/card/papka.svg';
import mars from 'assets/images/gate/mars.svg';
import rocket from 'assets/images/gate/rocket-mob.svg';
import useWindowResize from 'hooks/useWindowResize';
import planet from 'assets/images/gate/sitem-planet.png';
import planetCheez from 'assets/images/gate/planet/planet-cheez.png';
import rocketFly from 'assets/images/gate/roket-fly.png';
import planetGato from 'assets/images/gate/planet/planetGato.png';
import planetMlech from 'assets/images/gate/cardAmb/planetMlech.png';
import planetMob from 'assets/images/gate/mlech.png';
import { CardBlockchain } from './puzzles/CardBlockchain';
import { ChooseDownloads } from './Downloads/ChooseDownloads';
import { Cards } from './Card/Cards';
import { puzzlesArr } from './utils';
/* eslint-disable */

export const Jornay: FC = () => {
	const { width } = useWindowResize();
	const [resize, setResize] = useState(false);
	const [open, setOpne] = useState(false);
	const [openCard, setOpenCard] = useState<string | boolean>('' || false);
	console.log(openCard);
	// swiper-button-prev

	useEffect(() => {
		if (width && Number(width) <= 780) {
			setResize(true);
		} else {
			setResize(false);
		}
	}, [width]);

	const handleClick = () => {
		window.document.body.classList.remove('popup-card');
		setOpenCard('');
	};

	const handleContentClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
	};
	return (
		<div id="explore" className="jornay" onClick={handleContentClick}>
			<div className="stars-bg">
				{/* <img src={stars} alt="" /> */}

				<div className="planet-screen">
					<img className="mlech-mob" src={planetMob} alt="" />
					<img className="planet--mlech" src={planetMlech} alt="" />
					{/* {resize ? <img src={planetMob} alt="" /> : <img src={planetMlech} alt="" />} */}
					<div className="planet--cheez">
						<img src={planetCheez} alt="" />
					</div>
					<div className="rocket--fly">
						<img src={rocketFly} alt="" />
					</div>
					{puzzlesArr.map((item) => (
						<CardBlockchain
							key={item.id}
							data={item}
							setOpenCard={setOpenCard}
							openCard={openCard}
						/>
					))}
				</div>

				<div className="container contaioner__item-drop">
					<div className="title">
						The Gato Journey
						<div className="planet--gato">
							<img src={planetGato} alt="" />
						</div>
					</div>
				</div>
			</div>
			{open ? <ChooseDownloads setOpne={setOpne} handleClick={handleClick} /> : null}

			<img src={rocket} alt="" className="rocket" />
			<img src={mars} alt="" className="mars" />
			<button type="button" onClick={() => setOpne(true)} className="downloads__button">
				<img src={download} alt="" />
				<span>All Docs</span>
			</button>
			<div className="qr-code-mobile">
				<div className="jorney-footer-title">Join Gato Odyssey Today!</div>
				<div className="jorney-footer-subtitle">
					Build a Sustainable Web3 Legacy & Earn Continuously in DeFi!
				</div>
				<Cards />
			</div>
		</div>
	);
};
