import { FC, ChangeEvent, useState } from 'react';
import { ICardItem } from './type';
/*eslint-disable */

export const ItemCard: FC<ICardItem> = ({
	setCheckbox,
	checkbox,
	data,
	img_link,
	handleClick,
	handleCheckboxChange,
	classN,
}) => {
	const { style, text, puzzleImg, name, styleMob, id } = data;
	const [checkbo, setCheckbo] = useState(false);
	// const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
	// 	const { checked, name } = e.currentTarget;
	// 	setCheckbo(checked);
	// };

	return (
		<div style={{ ...style }} className={`download-card card--${String(name)} ${String(classN)}`}>
			{' '}
			{classN ? (
				<button
					className="popup-close popup-close--type2"
					type="button"
					onClick={() => handleClick?.()}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="31"
						height="31"
						viewBox="0 0 31 31"
						fill="none"
						className="download-cross"
					>
						<path
							d="M9.35142 22.5145C9.09402 22.5145 8.83662 22.4139 8.63517 22.2125C8.23229 21.8098 8.23229 21.1722 8.63517 20.7807L20.9344 8.47586C21.3373 8.07315 21.9864 8.07315 22.3781 8.47586C22.781 8.87856 22.781 9.52736 22.3781 9.90769L10.0677 22.2125C9.87741 22.4139 9.60882 22.5145 9.34022 22.5145H9.35142Z"
							fill="white"
						/>
						<path
							d="M21.6804 22.5145C21.4118 22.5145 21.1656 22.4139 20.953 22.2125L8.65373 9.90769C8.25084 9.51617 8.25084 8.86737 8.65373 8.47586C9.04542 8.07315 9.69452 8.07315 10.0862 8.47586L22.3966 20.7807C22.7883 21.1834 22.7883 21.821 22.3966 22.2125C22.1952 22.4139 21.9378 22.5145 21.6692 22.5145H21.6804Z"
							fill="white"
						/>
						<path
							d="M15.5064 30.1102C11.5671 30.1102 7.86278 28.5777 5.06496 25.7924C2.27833 23.007 0.745117 19.2932 0.745117 15.3556C0.745117 11.4069 2.27833 7.70427 5.06496 4.9189C7.85159 2.13354 11.5671 0.589844 15.5064 0.589844C19.457 0.589844 23.1613 2.12235 25.9479 4.9189C28.7346 7.69308 30.279 11.4069 30.279 15.3556C30.279 19.2932 28.7457 22.9958 25.9479 25.7924C23.1613 28.5665 19.457 30.1102 15.5064 30.1102ZM15.5064 2.63692C8.50069 2.63692 2.78193 8.35307 2.78193 15.3556C2.78193 22.3694 8.50069 28.0855 15.5064 28.0855C22.5234 28.0855 28.2309 22.3694 28.2309 15.3556C28.2309 8.35307 22.5234 2.63692 15.5064 2.63692Z"
							fill="white"
						/>
					</svg>
				</button>
			) : null}
			<div className="download-card__wrapper-img">
				<svg
					width="20"
					height="4"
					viewBox="0 0 19 4"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className="settings-point"
				>
					<path
						d="M3.40946 2.09126C3.40946 2.28792 3.37224 2.47926 3.2978 2.66529C3.22335 2.84601 3.11168 3.00546 2.97343 3.14365C2.83517 3.28716 2.67564 3.39346 2.48953 3.46787C2.30873 3.54229 2.1173 3.57949 1.92055 3.57949C1.7238 3.57949 1.53237 3.54229 1.35158 3.46787C1.16546 3.39346 1.00594 3.28716 0.86768 3.14365C0.729423 3.00546 0.617755 2.84601 0.54331 2.66529C0.468864 2.47926 0.431641 2.28792 0.431641 2.09126C0.431641 1.8946 0.468864 1.70326 0.54331 1.52254C0.617755 1.34183 0.729423 1.18237 0.86768 1.03887C1.00594 0.900673 1.16546 0.794372 1.35158 0.71996C1.53237 0.640234 1.7238 0.603027 1.92055 0.603027C2.1173 0.603027 2.30873 0.640234 2.48953 0.71996C2.67564 0.794372 2.83517 0.900673 2.97343 1.03887C3.11168 1.18237 3.22335 1.34183 3.2978 1.52254C3.37224 1.70326 3.40946 1.8946 3.40946 2.09126Z"
						fill="#18191B"
					/>
					<path
						d="M10.8597 2.09126C10.8597 2.28792 10.8224 2.47926 10.748 2.66529C10.6682 2.84601 10.5619 3.00546 10.4236 3.14365C10.2854 3.28716 10.1205 3.39346 9.93972 3.46787C9.75893 3.54229 9.5675 3.57949 9.37075 3.57949C9.174 3.57949 8.98257 3.54229 8.80177 3.46787C8.61566 3.39346 8.45613 3.28716 8.31787 3.14365C8.1743 3.00546 8.06795 2.84601 7.9935 2.66529C7.91906 2.47926 7.88184 2.28792 7.88184 2.09126C7.88184 1.8946 7.91906 1.70326 7.9935 1.52254C8.06795 1.34183 8.1743 1.18237 8.31787 1.03887C8.45613 0.900673 8.61566 0.794372 8.80177 0.71996C8.98257 0.640234 9.174 0.603027 9.37075 0.603027C9.5675 0.603027 9.75893 0.640234 9.93972 0.71996C10.1205 0.794372 10.2854 0.900673 10.4236 1.03887C10.5619 1.18237 10.6682 1.34183 10.748 1.52254C10.8224 1.70326 10.8597 1.8946 10.8597 2.09126Z"
						fill="#18191B"
					/>
					<path
						d="M18.304 2.09126C18.304 2.28792 18.2668 2.47926 18.1923 2.66529C18.1126 2.84601 18.0062 3.00546 17.868 3.14365C17.7297 3.28716 17.5649 3.39346 17.3841 3.46787C17.2033 3.54229 17.0118 3.57949 16.8151 3.57949C16.6183 3.57949 16.4269 3.54229 16.2461 3.46787C16.06 3.39346 15.9005 3.28716 15.7622 3.14365C15.6186 3.00546 15.5123 2.84601 15.4378 2.66529C15.3634 2.47926 15.3262 2.28792 15.3262 2.09126C15.3262 1.8946 15.3634 1.70326 15.4378 1.52254C15.5123 1.34183 15.6186 1.18237 15.7622 1.03887C15.9005 0.900673 16.06 0.794372 16.2461 0.71996C16.4269 0.640234 16.6183 0.603027 16.8151 0.603027C17.0118 0.603027 17.2033 0.640234 17.3841 0.71996C17.5649 0.794372 17.7297 0.900673 17.868 1.03887C18.0062 1.18237 18.1126 1.34183 18.1923 1.52254C18.2668 1.70326 18.304 1.8946 18.304 2.09126Z"
						fill="#18191B"
					/>
				</svg>
			</div>
			<div className="download-card__img">
				<img src={puzzleImg} alt="" />
			</div>
			<div className="download-container__footer download-footer">
				<p className="downloads__text">{text}</p>
				{classN ? (
					<div className="download-card_img-down">
						<img src={img_link} alt="" />
					</div>
				) : (
					<div className="download-footer__wrapper-check">
						<div className="checkbox checkbox--save-info checkbox--no-m">
							<label htmlFor={`card${id}`} className="checkbox__label label--type1">
								<input
									className="hidden"
									id={`card${id}`}
									name={id}
									// value={checkbo ? 1 : 0}
									type="checkbox"
									// checked={checkbo}
									defaultChecked={true}
									onChange={handleCheckboxChange}
								/>
								<div className="checkbox__item item--type1">
									<span className="checkbox__item-icon">
										<svg
											width="12"
											height="13"
											viewBox="0 0 12 13"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.73424 3.7002L4.60091 8.83353L2.26758 6.5002"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</div>
							</label>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
