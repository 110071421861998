/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState, useRef, useCallback } from 'react';
import { getQrcode, getImgQrcode, blockBg } from 'redux/reducers/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { getQrcodeRequest, getQrcodeImgRequest } from 'redux/reducers/auth/reducer';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { Jornay } from 'components/Jornay';
import house from 'assets/images/gate/house-nav.png';
import cube from 'assets/images/gate/puzzl-nav.png';
import useWindowResize from 'hooks/useWindowResize';
import { ICover } from './types';
import { CoverflowEffect } from './Slider';
import { SliderMobile } from './SliderMobile';

// import Slider from './Slider';

// import Swiper from 'swiper';
// import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
// import { ExpBig } from 'assets/custom-icons';
/* eslint-disable */

export const Dashboard: FC<ICover> = ({ setActive, active, setNameCard, swiperRef }) => {
	const numFormat = useMoneyFormat();
	const [resize, setResize] = useState(false);
	const dispatch = useDispatch();
	const qrcode = useSelector(getQrcode);
	const qrcodeImg = useSelector(getImgQrcode);
	const containerBg = useSelector(blockBg);

	console.log(containerBg);

	// const [scale, setScale] = useState(false);

	const { width } = useWindowResize();

	useEffect(() => {
		// let = params
		console.log(containerBg);
	}, [containerBg]);

	useEffect(() => {
		// let = params
		console.log(width);
		if (width && Number(width) <= 500) {
			setResize(true);
		} else {
			setResize(false);
		}
	}, [width]);
	const handlaOpenCard = (item: string) => {
		setNameCard(item);
		// if (item === 'explore') {
		// setActive(!active);
		// }
	};
	// useEffect(() => {
	// 	dispatch(userSettingsRequest());
	// }, [dispatch]);

	useEffect(() => {
		if (!qrcode && !qrcodeImg) {
			dispatch(getQrcodeRequest());
			dispatch(getQrcodeImgRequest({}));
		}
	}, [dispatch, qrcode, qrcodeImg]);
	return (
		<section className="dashboard">
			<div className="container dashboard-container">
				{containerBg ? <div className="bg-blurl" /> : null}
				{resize ? (
					<SliderMobile handlaOpenCard={handlaOpenCard} swiperRef={swiperRef} />
				) : (
					<CoverflowEffect handlaOpenCard={handlaOpenCard} swiperRef={swiperRef} />
				)}
				<div className="dashboard-container__navigation navigation-bar">
					<span className="navigation-bar__cube">
						all
						<span className="navigation-bar__icon">
							<img src={cube} alt="" />
						</span>
					</span>
					<span className="navigation-bar__house">
						<span className="navigation-bar__icon">
							<img src={house} alt="" />
						</span>
						here
					</span>
				</div>
			</div>
		</section>
	);
};
