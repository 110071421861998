/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAuthStore,
	ICreateNewPasswordPayload,
	IEmailConfirmPayload,
	IEmailResetConfirmTokenPayload,
	IForgotPasswordPayload,
	IGenerateSmsRequestPayload,
	IipConfirmPayload,
	ILoginPayload,
	ILoginResponse,
	ILogoutPayload,
	IRegistrationPayload,
	IResetTwoFaPayload,
	IRegistrResponse,
	IResendEmail,
	IResendPhone,
	IGenerateSmsChecktPayload,
	IRefreshTokenResponse,
	IRefreshTokenRequest,
	IDownloadList,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	tokenExpired: 0,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	registerUser: null,
	referralQrcode: null,
	referralImgQrcode: null,
	backgrounds: false,
	cardName: '',
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;

			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.tokenExpired = payload.token_expired_at;
			localStorage.setItem('accessToken', payload.token);
		},
		loginGoTo2FASuccess: (state) => {
			const loginState = state;
			loginState.loginLoader = false;
		},

		registrationRequest: (state, action: PayloadAction<IRegistrationPayload>) => {
			const registrationState = state;

			registrationState.registrLoader = true;
		},
		resendEmailRequest: (state, action: PayloadAction<IResendEmail>) => {
			const registrationState = state;

			registrationState.registrLoader = true;
		},
		resendPhoneRequest: (state, action: PayloadAction<IResendPhone>) => {
			const registrationState = state;

			registrationState.registrLoader = true;
		},
		registrationSuccess: (state, action: PayloadAction<IRegistrResponse>) => {
			const { payload } = action;
			const registrationState = state;

			registrationState.registrLoader = false;
			registrationState.registerUser = payload;
		},

		forgotPasswordRequest: (state, action: PayloadAction<IForgotPasswordPayload>) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},
		forgotPasswordSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},
		forgotPasswordEmailRequest: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = true;
		},
		forgotPasswordEmailSuccess: (state) => {
			const forgotPasswordState = state;
			forgotPasswordState.forgotPasswordLoader = false;
		},
		emailConfirmStatusRequest: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = true;
		},
		emailConfirmStatusCallback: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirm = false;
			emailConfirmState.emailResetConfirmTokenMessage = false;
		},

		emailConfirmRequest: (state, action: PayloadAction<IEmailConfirmPayload>) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = true;
		},
		emailConfirmSuccess: (state) => {
			const emailConfirmState = state;
			emailConfirmState.emailConfirmLoader = false;
		},

		emailResetConfirmTokenRequest: (
			state,
			action: PayloadAction<IEmailResetConfirmTokenPayload>,
		) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = true;
		},
		emailResetConfirmTokenSuccess: (state) => {
			const emailResetConfirmTokenState = state;
			emailResetConfirmTokenState.emailResetConfirmTokenLoader = false;
		},

		createNewPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = true;
		},
		createNewPasswordSuccess: (state) => {
			const createNewPasswordState = state;

			createNewPasswordState.createNewPasswordLoader = false;
		},

		ipConfirmRequest: (state, action: PayloadAction<IipConfirmPayload>) => {
			const ipConfirmState = state;

			ipConfirmState.ipConfirmLoader = true;
		},
		ipConfirmSuccess: (state) => {
			const ipConfirmState = state;
			ipConfirmState.ipConfirmLoader = false;
		},
		phoneConfirmRequest: (state, action: PayloadAction<IGenerateSmsRequestPayload>) => {
			const confirmState = state;
			confirmState.ipConfirmLoader = false;
		},
		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const logoutState = state;
			logoutState.logoutLoader = true;
		},

		generateSmsRequest: (state, action: PayloadAction<IGenerateSmsRequestPayload>) => {
			const generateSmsState = state;

			generateSmsState.generateSmsLoader = true;
		},
		forgotTwoFaRequest: (state, action: PayloadAction<IResetTwoFaPayload>) => {
			const twoFaState = state;
		},
		phoneConfirmSmsNewPassRequest: (state, action: PayloadAction<IGenerateSmsChecktPayload>) => {
			const confirmState = state;
			confirmState.generateSmsLoader = false;
		},

		getRefreshTokenRequest: (state, action: PayloadAction<IRefreshTokenRequest>) => {},
		getRefreshTokenSuccess: (state, { payload }: PayloadAction<IRefreshTokenResponse>) => {
			state.accessToken = payload.token;
			state.tokenExpired = payload.token_expired_at;
		},

		authInitState: () => initialState,

		getQrcodeRequest: (state) => {
			const loginRequestState = state;
		},
		getQrcodeSuccess: (state, { payload }: PayloadAction<any>) => {
			const loginRequestState = state;
			loginRequestState.referralQrcode = payload;
		},
		getQrcodeImgRequest: (state, action: PayloadAction<IDownloadList>) => {
			const loginRequestState = state;
		},
		getQrcodeImgSuccess: (state, { payload }: PayloadAction<any>) => {
			const loginRequestState = state;
			loginRequestState.referralImgQrcode = payload;
		},
		setBackgroundTrue: (state, action: PayloadAction<boolean>) => {
			const requestState = state;
			requestState.backgrounds = action.payload;
		},
		setCardName: (state, action: PayloadAction<string>) => {
			const currentState = state;
			currentState.cardName = action.payload;
		},
	},
});

export default auth.reducer;
export const {
	ipConfirmRequest,
	ipConfirmSuccess,
	loginRequest,
	loginSuccess,
	loginGoTo2FASuccess,
	registrationRequest,
	registrationSuccess,
	authInitState,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	createNewPasswordRequest,
	createNewPasswordSuccess,
	emailConfirmRequest,
	emailConfirmSuccess,
	forgotTwoFaRequest,
	emailResetConfirmTokenRequest,
	emailResetConfirmTokenSuccess,
	emailConfirmStatusRequest,
	emailConfirmStatusCallback,
	generateSmsRequest,
	logoutRequest,
	phoneConfirmRequest,
	resendEmailRequest,
	phoneConfirmSmsNewPassRequest,
	resendPhoneRequest,
	forgotPasswordEmailRequest,
	getRefreshTokenRequest,
	getRefreshTokenSuccess,
	getQrcodeRequest,
	getQrcodeSuccess,
	getQrcodeImgRequest,
	getQrcodeImgSuccess,
	setBackgroundTrue,
	setCardName,
} = auth.actions;
