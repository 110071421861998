import { FC } from 'react';
import { BackgroundJornay } from 'components/Jornay/Background';
import { BackgroundOdessey } from 'components/Odyssey/Background';
import { IContent } from './types';

export const BackgroundWrapper: FC<IContent> = ({ nameCard }) => {
	const itemCard: any = {
		explore: <BackgroundJornay />,
		odyssey: <BackgroundOdessey />,
		pig: <BackgroundJornay />,
		plus: <BackgroundJornay />,
		[`ecosystem--gate`]: <BackgroundOdessey />,
		[`ecosystem--contacts`]: <BackgroundOdessey />,
		[`ecosystem--mine`]: <BackgroundOdessey />,
		[`ecosystem--exchange`]: <BackgroundOdessey />,
		[`ecosystem--soon`]: <BackgroundOdessey />,
		[`ecosystem--domino`]: <BackgroundOdessey />,
		[`ecosystem--guds`]: <BackgroundOdessey />,
		[`ecosystem--desert`]: <BackgroundOdessey />,
		[`ecosystem--farti`]: <BackgroundOdessey />,
		[`ecosystem--coin`]: <BackgroundOdessey />,
	};
	return <>{nameCard ? itemCard[nameCard] : <BackgroundOdessey />}</>;
};
