import L from 'i18n-react';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import centerTopPuzzle from 'assets/images/puzzle-menu/puzzle_center_top.png';
import leftTopPuzzle from 'assets/images/puzzle-menu/puzzle_left_top.png';
import middlePuzzle1 from 'assets/images/puzzle-menu/puzzle_middle1.png';
import rightTopPuzzle from 'assets/images/puzzle-menu/puzzle_right_top.png';
import puzzleRightDown from 'assets/images/puzzle-menu/puzzle_rigth_down.png';
import leftDownPuzzle from 'assets/images/puzzle-menu/puzzle_left_down.png';
import puzzleMiddle2 from 'assets/images/puzzle-menu/puzzle_middle2.png';
import mobileLeftDownPuzzle from 'assets/images/puzzle-menu/mobile_left_down.png';
import mobileleftTopPuzzle from 'assets/images/puzzle-menu/mobile_left_top.png';
import mobileRightDown from 'assets/images/puzzle-menu/mobile_rigth_down.png';
import mobilerightTopPuzzle from 'assets/images/puzzle-menu/mobile_top_right.png';
// import puzzleRightDown from 'assets/images/puzzle-menu/puzzle_rigth_down.png';
// import leftDownPuzzle from 'assets/images/puzzle-menu/puzzle_left_down.png';
// import puzzleMiddle2 from 'assets/images/puzzle-menu/puzzle_middle2.png';
// import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { TextBig } from 'assets/custom-icons';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import useClickOutside from 'hooks/useOutsideClick';
import { IHeader } from '../types';
// import HeaderDropdown from 'ui/HeaderDropdown';
// import { IHeaderDropdownList } from 'layouts-elements/Header/types';
// import LangSwitcher from 'layouts-elements/Header/LangSwitcher/LangSwitcher';
// import NotificationBell from 'components/NotificationMessage/NotificationBell';
// import ThemeSwitch from '../ThemeSwitch';

// ==========================================:

const HeaderMenu: FC<IHeader> = ({ open, setOpen }) => {
	const dispatch = useDispatch();
	// const history = useHistory();

	const isAuth = useSelector(getAuthIsAuthenticated);

	const ref = useRef(null);

	useEffect(() => {
		dispatch(userSettingsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const openMenuHandler = () => {
	// 	setOpen(true);
	// };
	const closeMenuHandler = () => {
		setOpen?.(false);
	};

	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = '';
		}
		return () => {
			document.body.style.overflow = '';
		};
	}, [open]);

	// useClickOutside(ref, closeMenuHandler);

	// const handleSelect = (value: IHeaderDropdownList): void => {
	// 	value.link && history.push(value.link);
	// };

	// const handleLogout = () => {
	// 	dispatch(logoutRequest({ history }));
	// 	closeMenuHandler();
	// };

	// const [open, setOpen] = useState<boolean>(false);

	return (
		<div className="sandwich">
			{/* <button
				type="button"
				onClick={openMenuHandler}
				className="sandwich__open-btn"
				aria-label="sandwich open button"
			>
				<span className="sandwich__line" />
				<span className="sandwich__line" />
				<span className="sandwich__line" />
			</button> */}
			<div
				data-sandwich="wrapper"
				className={`sandwich__main ${open ? 'sandwich__main--active' : ''}`}
			>
				<div
					className={`sandwich__content ${open ? 'sandwich__content--active' : ''}`}
					style={open ? { right: 0 } : {}}
					ref={ref}
				>
					<div className="background-puzzle">
						<img className="menu-img menu-puzzle1" src={leftTopPuzzle} alt="" />
						<img className="menu-img menu-puzzle2" src={centerTopPuzzle} alt="" />
						<img className="menu-img menu-puzzle3" src={middlePuzzle1} alt="" />
						<img className="menu-img menu-puzzle4" src={rightTopPuzzle} alt="" />
						<img className="menu-img menu-puzzle5" src={puzzleRightDown} alt="" />
						<img className="menu-img menu-puzzle6" src={leftDownPuzzle} alt="" />
						<img className="menu-img menu-puzzle7" src={puzzleMiddle2} alt="" />
						<img className="menu-img mobile-puzzle1" src={mobileLeftDownPuzzle} alt="" />
						<img className="menu-img mobile-puzzle2" src={mobileleftTopPuzzle} alt="" />
						<img className="menu-img mobile-puzzle3" src={mobileRightDown} alt="" />
						<img className="menu-img mobile-puzzle4" src={mobilerightTopPuzzle} alt="" />
					</div>
					{/* <div className="sandwich__header">
						 <ThemeSwitch /> 
						<button type="button" onClick={closeMenuHandler} className="sandwich__close-btn">
							<span className="sandwich__close-icon icon-close" />
						</button>
					</div> */}

					<nav className="main-nav sandwich__nav">
						<TextBig className="main__text" />
						<ul className="main-nav__list">
							<li className="main-nav__item small-margin ">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-bitcoin-convert" />
									What is GatoPassport?
								</Link>
							</li>
							<li className="main-nav__item forty-margin">
								<p className="main-nav__link">
									<span className="main-nav__icon icon-money-recive" />
									How i can earn helping
									<br />
									the Ecosystem growing?
								</p>
							</li>
							<li className="main-nav__item forty-margin">
								<p className="main-nav__link">
									<span className="main-nav__icon icon-trade" />
									How can i be part of starting community?
								</p>
							</li>
							<li className="main-nav__item padding-left">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									Where i can contribute?
								</Link>
							</li>
							<li className="main-nav__item forty-margin">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									Where i can invest?
								</Link>
							</li>
							<li className="main-nav__item small-margin ">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									Ecosystem Road Map
								</Link>
							</li>
							<li className="main-nav__item forty-margin">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									What is Gatochain?
								</Link>
							</li>
							<li className="main-nav__item ten-margin">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									What is GUDS?
								</Link>
							</li>
							<li className="main-nav__item padding-left">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									What is Gato Coin?
								</Link>
							</li>
							<li className="main-nav__item forty-margin">
								<Link to="/" className="main-nav__link">
									<span className="main-nav__icon icon-user" />
									What is Glam?
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
};

export default HeaderMenu;
