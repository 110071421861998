import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getAuthIsAuthenticated } from 'redux/reducers/auth/selectors';
import { AuthHeader } from 'layouts-elements/Header/AuthHeader';
import { Link } from 'react-router-dom';
import { Logo } from 'assets/custom-icons';
import AppLogo from 'ui/AppLogo';
import HeaderMenu from './HeaderMenu';
import { StaticHeader } from './StaticHeader';
import { IHeader } from './types';
/* eslint-disable*/
export const Header: FC<IHeader> = ({ open, setOpen, initSlider }) => {
	const isAuth = useSelector(getAuthIsAuthenticated);

	const openMenuHandler = () => {
		setOpen?.(true);
	};
	const closeMenuHandler = () => {
		setOpen?.(false);
	};

	// const { handleScroll }: any = useScrollHandler();

	return (
		<header className={`header ${isAuth ? 'header--autorized' : ''}`}>
			<div className="header__container  ">
				<div className="header-left">
					{isAuth ? (
						<>
							<button
								type="button"
								onClick={open ? closeMenuHandler : openMenuHandler}
								className="nav-btn "
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="42"
									height="19"
									viewBox="0 0 42 19"
									fill="none"
									className={`menu-btn ${open ? 'active' : ''}`}
								>
									<circle cx="35" cy="7" r="7" fill="#D9F343" />
									<rect className="stoke" y="6" width="35" height="2.5" fill="white" />
									<rect className="stoke" y="16.2441" width="35" height="2.5" fill="white" />
								</svg>
							</button>
							{/* {open ? (
								<button type="button" onClick={closeMenuHandler} className="nav-btn">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="42"
										height="19"
										viewBox="0 0 42 19"
										fill="none"
										className={`menu-btn ${open ? 'active' : ''}`}
									>
										<circle cx="35" cy="7" r="7" fill="#D9F343" />
										<rect className="stoke" y="6" width="35" height="2.5" fill="white" />
										<rect className="stoke" y="16.2441" width="35" height="2.5" fill="white" />
									</svg>
								</button>
							) : (
								<button
									type="button"
									onClick={openMenuHandler}
									className="nav-btn "
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="42"
										height="19"
										viewBox="0 0 42 19"
										fill="none"
										className={`menu-btn ${open ? 'active' : ''}`}
									>
										<circle cx="35" cy="7" r="7" fill="#D9F343" />
										<rect className="stoke" y="6" width="35" height="2.5" fill="white" />
										<rect className="stoke" y="16.2441" width="35" height="2.5" fill="white" />
									</svg>
								</button>
							)} */}
						</>
					) : (
						''
					)}
					{/* {isAuth ? <HeaderMenu /> : ''} */}
					<Link to="/" className="logo">
						<span className="logo__icon" onClick={initSlider}>
							<Logo />
						</span>
					</Link>
					{/* <AppLogo handleClick={initSlider} /> */}
				</div>
				{/* <MainNav open={open} /> */}
				{isAuth ? <AuthHeader /> : ''}
			</div>
		</header>
	);
};
