import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig, Chain } from 'wagmi';
import { arbitrum, mainnet, polygon, polygonMumbai } from 'wagmi/chains';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { publicProvider } from 'wagmi/providers/public';
/* eslint-disable */

export const binanceChain: Chain | any = {
	id: 56,
	name: 'Binance Smart Chain Mainnet',
	network: 'binance-smart chain mainnet',
	nativeCurrency: {
		name: 'Binance Chain Native Token',
		symbol: 'BNB',
		decimals: 18,
	},
	rpcUrls: {
		binance: 'https://bsc-dataseed4.binance.org',
		default: 'https://bsc-dataseed4.binance.org',
		public: 'https://bsc-dataseed4.binance.org',
	},
	// rpcUrls: {
	// 	binance: 'https://bsc-dataseed4.binance.org',
	// 	defibit: 'https://bsc-dataseed4.defibit.io',
	// 	ninicoin: 'https://bsc-dataseed4.ninicoin.io',
	// 	public: 'https://bsc-dataseed4.binance.org',
	// 	default: 'https://bsc-dataseed4.binance.org',
	// },
	blockExplorers: {
		default: { name: 'Bscscan', url: 'https://bscscan.com' },
	},
	testnet: false,
};

const chains = [arbitrum, mainnet, polygon, polygonMumbai];
const projectId = 'c6c9bacd35afa3eb9e6cccf6d8464395';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, chains }),
	publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

export const Web3Provider = ({ children }: { children: React.ReactNode }) => {
	return (
		<>
			<WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>

			<Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
		</>
	);
};

// const { chains, provider } = configureChains(
//   [binanceChain],
//   [publicProvider()],
//   [
//     jsonRpcProvider({
//       rpc: (chain) => {
//         if (chain.id !== binanceChain.id) return null;
//         return { http: chain.rpcUrls.default };
//       },
//     }),
//   ]
// );

// const client = createClient({
//   connectors: [
//     new MetaMaskConnector({ chains }),
//     new WalletConnectConnector({
//       options: {
//         chains,
//         qrcode: true,
//         rpc: {
//           56: "https://bsc-dataseed4.binance.org",
//         },
//       },
//     }),
//   ],
//   autoConnect: true,
//   chains,
//   provider,
// });
