import React from 'react';
import leftBackPuzzle from 'assets/images/puzzle3.png';
import rigthBackPuzzle from 'assets/images/puzzle31.png';
import leftFrontPuzzle from 'assets/images/pazzle2.png';
import rightFrontPuzzle from 'assets/images/puzzel21.png';
import puzzleMob from 'assets/images/puzzleMob.png';

export const Background = () => {
	return (
		<div className="background-box">
			<img className="puzzleMob" src={puzzleMob} alt="" />
			<div className="background-box__back">
				<img className="puzzle3" src={leftBackPuzzle} alt="" />
				<img className="puzzle31" src={rigthBackPuzzle} alt="" />
			</div>
			<div className="background-box__foreground">
				<img className="puzzle21" src={leftFrontPuzzle} alt="" />
				<img className="puzzle2" src={rightFrontPuzzle} alt="" />
			</div>
		</div>
	);
};
