import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateNewPassIsLoad } from 'redux/reducers/auth/selectors';
import { createNewPasswordRequest } from 'redux/reducers/auth/reducer';
import { Auth } from 'layouts/Auth';
import { SuccessPassReset } from 'components/Forms/FormModals/SuccessPassReset';
import CreateNewPassForm from 'components/Forms/CreateNewPassForm';
import { ICreateNewPasswordFormData } from 'components/Forms/CreateNewPassForm/types';
import { ICreateNewPasswordPayload } from 'redux/reducers/auth/types';
import Loader from 'ui/Loader';

// ==========================================:
export const CreateNewPassword: FC = () => {
	const createNewPassLoad = useSelector(getCreateNewPassIsLoad);
	const dispatch = useDispatch();
	const [openModalSend, setOpenModalSend] = useState(false);
	const [activeAnim, setActiveAnim] = useState(false);

	const handleCreateNewPass = (values: ICreateNewPasswordFormData) => {
		const params: ICreateNewPasswordPayload = {
			...values,
			onSuccess: () => {
				setActiveAnim(true);
			},
			onError: () => {
				setOpenModalSend(false);
			},
		};
		dispatch(createNewPasswordRequest(params));
		setOpenModalSend(true);
	};

	return (
		<Auth title="Create new password">
			<section className="authorization-section">
				<div className="container">
					<div className="authorization-box">
						<div className="authorization-form">
							<CreateNewPassForm createNewPassSubmit={handleCreateNewPass} />
							{/* {createNewPassLoad && (
								<div className="auth-loader-wrapper">
									<Loader />
								</div>
							)} */}
						</div>
					</div>
				</div>
			</section>
			<SuccessPassReset
				openModal={openModalSend}
				setOpenModal={setOpenModalSend}
				activeAnim={activeAnim}
			/>
		</Auth>
	);
};
