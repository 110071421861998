import { FC, useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { ModalWarapper } from 'ui/Popups/ModalWarapper';
import { http } from 'services/http';
import { PlusText } from 'assets/custom-icons';
import { getQrcodeImgRequest, setBackgroundTrue } from 'redux/reducers/auth/reducer';
import { downloadImgHref } from 'services/utils/toBase64';
import gatoFull from 'assets/images/gate/cardAmb/gatoFull.svg';
import gato from 'assets/images/gate/card/gato_name.png';
import planet from 'assets/images/gate/card/brand_icon.png';
import heart from 'assets/images/gate/card/heart.png';
import share from 'assets/images/gate/card/share.png';
import blackshare from 'assets/images/gate/card/black-share.png';
import blackheart from 'assets/images/gate/card/black-heart.png';
import blackgato from 'assets/images/gate/card/blackgato.png';
import { downloadImgLink } from 'services/utils/toBase64';
import { toBase64 } from 'services/utils/toBase64';
import bg_puzzle from 'assets/images/gate/card/bg_puzzl_card.svg';
import { TextOdissay, Scan } from 'assets/custom-icons';
import Gadjet from 'assets/images/gate/cardAmb/Gadjet.svg';
import Astro from 'assets/images/gate/cardAmb/astro.svg';
import DownloadCard from 'assets/images/gate/cardAmb/download.svg';
import { getQrcode, getImgQrcode, blockBg } from 'redux/reducers/auth/selectors';
import { TCard } from './types';
import { SharePopup } from '../../Dashboard/sharePopup';

const REACT_APP_WEB = process.env.REACT_APP_WEB_API_URL;

/* eslint-disable */
export const Cards: FC<TCard> = ({ data, handlaClick, scale, setScale }) => {
	const dispatch = useDispatch();

	const qrcode = useSelector(getQrcode);
	const [isOpen, setIsOpen] = useState(false);
	const close = () => setIsOpen(false);
	const qrcodeImg = useSelector(getImgQrcode);
	const [imageUrl, setImageUrl] = useState(null);
	const [scaleCard, setcaleCard] = useState(false);

	const handleDownload = () => {
		dispatch(
			getQrcodeImgRequest({
				is_download: 1,
				onOpenPdf: (data) => {
					if (data && REACT_APP_WEB) {
						downloadImgLink(data);
						// window.location.replace(`${REACT_APP_WEB}download-img?image=${data}`);
					}
				},
			}),
		);
	};

	return (
		<div className={`card card__jorney swiper-slide qr-code-jorney`}>
			<div
				style={{ background: `url(${bg_puzzle})` }}
				className="card__container card__container--type1"
			>
				<div className="card__bg_text">
					<PlusText />
				</div>
				<div className="card__header">
					<div className="card__header-top">
						<span className="card__heart">
							<img src={heart} alt="" />
						</span>
						<span className="card__logo-text">
							<img src={gatoFull} alt="" />
						</span>
						<button onClick={() => setIsOpen(true)} type="button" className="card__share">
							<img src={share} alt="" />
						</button>
					</div>
					<div className="card__header-text-img">Your Web3 Passport </div>
				</div>
				<div className="card__center_img ">
					<img src={planet} alt="" />
				</div>
				<div className="card__footer">
					<div className="card__footer-bg-bloor" />
					<div
						// onClick={() => handlaClick?.(name)}
						className="card-scan__type1"
					>
						<div className="card__qr-code">
							<img src={qrcode} alt="qrcode" />{' '}
						</div>
						<Scan className="card-scan" />
					</div>
					{/* <div className='card__wrapper'></div> */}
					<div className="card_type1__block">
						<div className="card_type1__footer-img">
							<img src={Gadjet} alt="" />
						</div>
						{qrcodeImg ? (
							<button className="footer__download" type="button" onClick={() => handleDownload()}>
								<img src={DownloadCard} alt="" />
							</button>
						) : null}
					</div>
				</div>
			</div>

			<ModalWarapper openModal={isOpen} setOpenModal={close}>
				<SharePopup close={close} share_img="card-odissey.png" name="odyssey" />
			</ModalWarapper>
		</div>
	);
};
