import { FC, useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { toBase64 } from 'services/utils/toBase64';
import { widthFile } from 'services/utils/fileWidth';
import { notificationContainer } from 'services/utils/notificationContainer';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import plusIcon from 'assets/images/gate/plus-icon.png';
import Input from 'ui/Formik/Input';
// import TextError from 'ui/Formik/TextError';
import { editProfileRequest } from 'redux/reducers/settings/reducer';
import Loader from 'ui/Loader';
import {
	getUserSettingsData,
	getNationalities,
	getUserSettingsDataLoader,
} from 'redux/reducers/settings/selectors';
import loginAvatar from 'assets/images/avatars/default-avatar.png';
import { CSearchInput } from 'ui/CSearchInput';

import { months, years } from 'services/utils/ditePicker';
import { getMonth, getYear } from 'date-fns';
import { TSubmitValue } from './type';

const acceptTypeFiles = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];

export const AccountDetails: FC = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUserSettingsData);
	const nationalities = useSelector(getNationalities);
	const loadingDataLoader = useSelector(getUserSettingsDataLoader);

	const [avatar, setAvatar] = useState<string>('');
	const [file, setFile] = useState<File | null | string>(null);
	const [errorSelfie, setErrorSelfie] = useState(false);
	const [startDate, setStartDate] = useState<Date | null>(null);
	// const [errors, setErrors] = useState(false);

	const initialValues = {
		first_name: user?.data?.first_name || '',
		last_name: user?.data?.last_name || '',
		nationality: user?.data?.nationality || '',
		birthday: user?.data?.birthday || '',
		nick_name: user?.data?.nick_name || '',
		avatar: user?.data?.avatar_path || '',
		email: user?.email || '',
		phone: user?.phone || '',
	};

	const [init, setInit] = useState(initialValues);
	const [selectItem, setSelectItem] = useState<string>('');

	useEffect(() => {
		if (user?.data) {
			setAvatar(user?.data?.avatar_path);
			setSelectItem(user?.data?.nationality);
			setStartDate(new Date(user.data.birthday));
			setInit({
				first_name: user?.data?.first_name || '',
				last_name: user?.data?.last_name || '',
				nationality: user?.data?.nationality || '',
				birthday: user?.data?.birthday || '',
				nick_name: user?.data?.nick_name || '',
				avatar: user?.data?.avatar_path || '',
				email: user?.email || '',
				phone: user?.phone || '',
			});
		}
		// eslint-disable-next-line
	}, [user?.data]);

	useEffect(() => {
		if (!file) return;
		const getImg: any = async () => {
			try {
				const img: any = await toBase64(file);
				if (img) {
					setAvatar(img);
					setErrorSelfie(false);
				}
			} catch {
				setErrorSelfie(true);
				notificationContainer('smg went wrong', 'error');
			}
		};
		getImg();
	}, [file]);

	const validationSchema = yup.object().shape({
		first_name: yup.string().required('Mandatory field'),
		email: yup.string().required('Mandatory field'),
		phone: yup.string().required('Mandatory field'),
	});

	// const handleCalendarOpen = () => console.log('Calendar opened');

	// const handleDeleteFile = () => {
	// 	setFile(null);
	// 	setAvatar(null);
	// };

	// useEffect(() => {
	// 	if (startDate) {
	// 		setErrors(false);
	// 	}
	// }, [startDate]);

	const handleUploadFile = (e: React.FormEvent<HTMLInputElement>) => {
		const tragetFile: File | null | undefined = e.currentTarget.files?.item(0);
		if (!widthFile(tragetFile?.size)) {
			setErrorSelfie(true);
			notificationContainer('Img width wrong', 'error'); // error if file size bigger then 3 MB);
			return;
		}

		if (tragetFile) {
			setErrorSelfie(false);
			setFile(tragetFile);
		}
	};

	const currentImage = (avtr: string, item: string | undefined) => {
		if (avtr.match(/base64,/g)) {
			return avtr.split('64,')[1];
		}
		if (item) {
			return String(item);
		}
		return '';
	};

	// const handleBlur = () => {
	// 	// without setTimeout the dropdown will close before we select asset
	// 	if (!startDate) {
	// 		setErrors(true);
	// 	}
	// };

	const handleSelect = (value: string): void => {
		if (value) setSelectItem(value);
	};

	// const confirmSubmit = (values: TSubmitValue): void => {
	// 	dispatch();
	// };
	const tryOnEmpty = (data: TSubmitValue) => {
		const newArray: any = Object.entries(data).filter((item: any) => item[1] !== '');
		const newPayload = newArray.reduce((acc: any, obj: any) => {
			const [one, two] = obj;
			acc[one] = two;
			return acc;
		}, {});

		return newPayload;
	};

	return (
		<Formik
			initialValues={init}
			validationSchema={validationSchema}
			onSubmit={(values: TSubmitValue, { setSubmitting }) => {
				const payload = {
					...values,
					birthday: Date.parse(String(startDate)) / 1000 || '',
					avatar: String(currentImage(avatar, user?.data?.avatar)) || '',
					nationality: selectItem || '',
				};
				dispatch(editProfileRequest(tryOnEmpty(payload)));
				setSubmitting(false);
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, handleChange }) => {
				return (
					<Form>
						<section className="general-section account-details">
							<div className="container">
								<div className="inner-block">
									<div className="login-info login-info--center">
										<div className="form">
											<div className="form__wrapper">
												<div className="info-avatar">
													<div className="info-avatar__wrapper">
														<div className="info-avatar__img">
															<img src={String(avatar) || loginAvatar} alt="avatar" />
														</div>
														<div className="upload-foto">
															<label className="upload-foto__label">
																<input
																	type="file"
																	onChange={handleUploadFile}
																	multiple
																	className="hidden"
																	defaultValue=""
																	accept={String(acceptTypeFiles)}
																/>
																<span className="upload-foto__icon">
																	<img className="upload-foto__icon--plus" src={plusIcon} alt="" />
																	<svg
																		xmlns="http://www.w3.org/2000/svg"
																		width="44"
																		height="45"
																		viewBox="0 0 44 45"
																		fill="none"
																	>
																		<circle
																			cx="22"
																			cy="22.2031"
																			r="19.5"
																			fill="#EFF4F8"
																			stroke="#00001E"
																			strokeWidth="5"
																		/>
																	</svg>
																</span>
															</label>
														</div>
													</div>
												</div>
												<h4 className="info-avatar__title">Liam Smith</h4>
												<h4 className="info-avatar__subtitle">Engineer</h4>
												<div className="form__body">
													<div className="form-group">
														<Field
															type="text"
															placeholder="Name"
															name="first_name"
															required
															component={Input}
															disableGreen="none"
														/>
														<Field
															type="text"
															placeholder="Surname"
															name="last_name"
															component={Input}
															disableGreen="none"
														/>
														<Field
															type="text"
															placeholder="Email"
															name="email"
															required
															someName={`${user?.email_verified_at ? 'disable' : ''}`}
															component={Input}
															disableGreen="none"
														/>
														<Field
															type="phone"
															placeholder="Phone Number"
															name="phone"
															required
															onChange={handleChange}
															component={Input}
															someName={`${user?.phone_verified_at ? 'disable' : ''}`}
															disableGreen="none"
															values
														/>
														<CSearchInput
															listData={nationalities}
															onSelect={handleSelect}
															dataItem={selectItem}
														/>
														<div className="input">
															<div className="input-wrapper">
																<DatePicker
																	className="input-item"
																	selected={startDate}
																	dateFormat="dd/MM/yyyy"
																	onChange={(date: Date) => setStartDate(date)}
																	maxDate={new Date()}
																	renderDayContents={(date: number) => <span>{date}</span>}
																	renderCustomHeader={({
																		date,
																		// eslint-disable-next-line @typescript-eslint/no-shadow
																		changeYear,
																		decreaseMonth,
																		increaseMonth,
																		prevMonthButtonDisabled,
																		nextMonthButtonDisabled,
																	}) => {
																		return (
																			<div className="calendar-header">
																				<div className="btn-wrapper">
																					<button
																						className="arrow left-arrow"
																						type="button"
																						onClick={decreaseMonth}
																						disabled={prevMonthButtonDisabled}
																					>
																						<span />
																					</button>
																					<span className="h5">{months[getMonth(date)]} </span>
																					<button
																						className="arrow right-arrow"
																						type="button"
																						onClick={increaseMonth}
																						disabled={nextMonthButtonDisabled}
																					>
																						<span />
																					</button>
																				</div>
																				<select
																					className="select-year"
																					value={getYear(date)}
																					onChange={({ target: { value } }) =>
																						changeYear(Number(value))
																					}
																				>
																					{years.map((option) => (
																						<option key={option} value={option}>
																							{option}
																						</option>
																					))}
																				</select>
																			</div>
																		);
																	}}
																/>

																<div className="input-icon input-icon--right">
																	<svg
																		width="16"
																		height="16"
																		viewBox="0 0 16 16"
																		fill="none"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			d="M5.33301 1.33337V3.33337"
																			stroke="#C0C0CF"
																			strokeWidth="1.5"
																			strokeMiterlimit="10"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M10.667 1.33337V3.33337"
																			stroke="#C0C0CF"
																			strokeWidth="1.5"
																			strokeMiterlimit="10"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M2.33301 6.06006H13.6663"
																			stroke="#C0C0CF"
																			strokeWidth="1.5"
																			strokeMiterlimit="10"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M14 5.66671V11.3334C14 13.3334 13 14.6667 10.6667 14.6667H5.33333C3 14.6667 2 13.3334 2 11.3334V5.66671C2 3.66671 3 2.33337 5.33333 2.33337H10.6667C13 2.33337 14 3.66671 14 5.66671Z"
																			stroke="#C0C0CF"
																			strokeWidth="1.5"
																			strokeMiterlimit="10"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M7.99666 9.13338H8.00265"
																			stroke="#C0C0CF"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M5.52987 9.13338H5.53585"
																			stroke="#C0C0CF"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																		<path
																			d="M5.52987 11.1334H5.53585"
																			stroke="#C0C0CF"
																			strokeWidth="2"
																			strokeLinecap="round"
																			strokeLinejoin="round"
																		/>
																	</svg>
																</div>
															</div>
															{/* {errors ? <TextError>Mandatory field</TextError> : null} */}
														</div>
														<Field
															type="text"
															placeholder="Nickname"
															name="nick_name"
															component={Input}
															disableGreen="none"
														/>
													</div>
													<div className="login-info-btns">
														<button
															// onClick={resetForm}
															type="button"
															className="button button--full-width button--transparent"
														>
															Cancel
														</button>
														<button
															// disabled={!(isValid && dirty) || isSubmitting}
															disabled={!isValid || isSubmitting}
															aria-label="form-submit"
															type="submit"
															className="button button--full-width"
														>
															Confirm
														</button>
													</div>
												</div>
											</div>
										</div>
										{loadingDataLoader && (
											<div className="auth-loader-wrapper">
												<Loader />
											</div>
										)}
									</div>
								</div>
							</div>
						</section>
					</Form>
				);
			}}
		</Formik>
	);
};
