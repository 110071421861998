import { FC, useState } from 'react';

import { I2FAParams, IEmailAlreadySentParams } from 'redux/reducers/auth/types';
import { notificationContainer } from 'services/utils/notificationContainer';
import { Auth } from 'layouts/Auth';
import { Login2FA } from 'components/Login2FA';
import { LoginCreds } from 'components/Forms/LoginCreds';

const Login: FC = () => {
	const [params2FA, setParams2FA] = useState<I2FAParams | null>(null);

	// Backend bug? Need to send a code with each login?
	const handleEmailAlreadySent = ({ apiParams }: IEmailAlreadySentParams) => {
		setParams2FA({ apiParams, type2FA: 'email' });
		notificationContainer('Email code already sent', 'info');
	};

	return (
		<Auth title="Auth">
			{!params2FA && (
				<LoginCreds on2FA={setParams2FA} onEmailAlreadySent={handleEmailAlreadySent} />
			)}
			{params2FA && <Login2FA params2FA={params2FA} />}
		</Auth>
	);
};
export default Login;
