/* eslint-disable */
/* eslint-disable import/no-unresolved */
import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { cardsItem } from './constants';
import { SliderItem } from 'layouts-elements/SliderItem';
import { TCard } from './Card/types';
import { Cards } from './Card/Cards';
import Swiper from 'swiper';
import useWindowResize from 'hooks/useWindowResize';
import { ISlide } from './types';
import { SwiperSlide } from 'swiper/react';
import { setSlider } from 'redux/reducers/settings/reducer';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { useDispatch } from 'react-redux';
// import Swiper from 'react-id-swiper';

export const CoverflowEffect: FC<ISlide> = ({ handlaOpenCard, scale, setScale, swiperRef }) => {
	// const [swiperRef, setSwiperRef] = useState<any>();
	const { width } = useWindowResize();
	const [sliderIdx, setSliderIdx] = useState(0);
	const [swipr, setSwipe] = useState<Swiper>();
	const [activeSl, setActiveSl] = useState('');
	const prevRef = useRef<HTMLButtonElement | null>(null);
	const nextRef = useRef<HTMLButtonElement | null>(null);
	const dispatch = useDispatch();
	// let params: any = {
	// 	effect: 'coverflow',
	// 	centeredSlides: true,
	// 	slidesPerView: 'auto',
	// 	isDuplicate: true,
	// 	// loop: true,
	// 	preloadImages: false,
	// 	coverflowEffect: {
	// 		rotate: 0,
	// 		stretch: 20,
	// 		depth: 150,
	// 		modifier: 1,
	// 		slideShadows: true,
	// 	},
	// 	lazy: {
	// 		loadOnTransitionStart: false,

	// 		loadPrevNext: false,
	// 	},
	// 	pagination: {
	// 		clickable: true,
	// 		el: '.my-custom-pagination',
	// 		dynamicBullets: true,
	// 		dynamicMainBullets: 3,
	// 	},
	// 	navigation: {
	// 		nextEl: '.swiper-button-next .swiper-button-disabled',
	// 		prevEl: '.swiper-button-prev .swiper-button-disabled',
	// 	},
	// 	//slider
	// 	// breakpoints: {
	// 	// 	// when window width is >= 320px
	// 	// 	320: {
	// 	// 		slidesPerView: 1,
	// 	// 		spaceBetween: 20,
	// 	// 	},
	// 	// 	// when window width is >= 480px
	// 	// 	480: {
	// 	// 		slidesPerView: 1,
	// 	// 		spaceBetween: 30,
	// 	// 	},
	// 	// 	// when window width is >= 640px
	// 	// 	640: {
	// 	// 		slidesPerView: 3,
	// 	// 		spaceBetween: 40,
	// 	// 	},
	// 	// },
	// };
	useEffect(() => {
		handlaOpenCard?.('');
	}, [sliderIdx]);

	const getCurrentSlideIdx = useCallback(() => {
		let index = null;
		const slides = document.querySelectorAll('.mySwiper .swiper-slide');
		slides.forEach((curr, idx) => {
			if (curr.classList.contains('swiper-slide-active')) {
				curr.classList.remove('swiper-slide-active');
				index = idx;
			}
			if (curr.classList.contains('card__explore')) {
				curr.classList.add('swiper-slide-active');
				index = curr;
			}
		});
		return index;
	}, []);
	useEffect(() => {
		if (swiperRef) {
			swiperRef.current = new Swiper('.mySwiper', {
				loop: true,
				speed: 1000,
				modules: [EffectCoverflow, Navigation, Pagination],
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',

				coverflowEffect: {
					rotate: 0,
					stretch: 20,
					depth: 150,
					modifier: 1,
					slideShadows: true,
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3,
				},
				on: {
					slideChange(swiper: any) {
						setSliderIdx(swiper?.activeIndex);
						const activeSlide = swiper?.slides?.find((item: any) => {
							// console.log(item.className.include('swiper-slide-active'));
							return item.className.includes('swiper-slide-active');
						});
						if (activeSlide) {
							setActiveSl(activeSlide.className);
						}
						// console.log(activeSlide);
						// debugger;
					},
				},
				// init: false,
				// on: {
				// 	init() {
				// 		swiper.slideTo(7, 1, false);
				// 	},
				// },
				initialSlide: 4,
			});
			// swiper.on('slideChangeTransitionEnd', function () {
			// 	// setSliderIdx(getCurrentSlideIdx());
			// });

			// swiper.on('beforeInit', function () {
			// 	console.log(swiper.activeIndex);
			// 	let csilde = getCurrentSlideIdx();
			// 	console.log(csilde);
			// 	// if (csilde) swiper.appendSlide([csilde]);
			// 	// swiper.slideTo(5, 6, false);
			// 	// swiper.slidePrev(3, false);
			// // });
			// swiper.init();
			// dispatch(setSlider(swiper));
			// setSwipe(swiper);}
		}
	}, [swiperRef]);

	const setSliderm = () => {
		swipr?.init();
		console.log('try');
	};

	return (
		<>
			{/* <button
				style={{ color: 'white', width: '20px', height: '20px' }}
				type="button"
				onClick={setSliderm}
			>
				press me
			</button> */}
			<div className="swiper mySwiper swiper-container home-slider">
				<div className="swiper-wrapper">
					{cardsItem.map((item, index) => (
						// <div key={item.id} className="swiper-slide">
						<Cards
							key={index}
							data={item}
							handlaClick={handlaOpenCard}
							activeSlide={String(activeSl)}
						/>
						// </div>
					))}
				</div>
				<div className="swiper-pagination"></div>
			</div>
			{/* <button
				type="button"
				className="slider-btn swiper-button-prev"
				ref={prevRef}
				aria-label="slider button previous"
			/>
			<button
				type="button"
				className="slider-btn swiper-button-next"
				ref={nextRef}
				aria-label="slider button next"
			/> */}

			{/* <Swiper
				effect={'coverflow'}
				modules={[EffectCoverflow, Pagination, Navigation]}
				grabCursor={true}
				centeredSlides={true}
				slidesPerView={'auto'}
				loop={true}
				coverflowEffect={{
					rotate: 0,
					stretch: 20,
					depth: 150,
					modifier: 1,
					slideShadows: true,
				}}
				pagination={{
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 3,
				}}
				navigation={true}
				// navigation={{
				// 	nextEl: '.swiper-button-next .swiper-button-disabled',
				// 	prevEl: '.swiper-button-prev .swiper-button-disabled',
				// }}

				className="swiper-container swiper-anim"
			>
				{cardsItem.map((item) => (
					<SwiperSlide key={item.id * Math.random()} className="slider">
						<Cards data={item} handlaClick={handlaOpenCard} />
					</SwiperSlide>
				))}
			</Swiper> */}
		</>
	);
};
