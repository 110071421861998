import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import mineBack from 'assets/custom-icons/background/mineBack.png';
import download from 'assets/images/gate/card/download_fot.png';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import search_eco from 'assets/images/search-eco.png';
import telegram from 'assets/images/ecosystem/Telegram.png';
import messanger from 'assets/images/ecosystem/messanger.png';
import pinterest from 'assets/images/ecosystem/Pinterest.png';
import { EcoHeader } from './EcoHeader';
import { ICardProps } from './types';

/* eslint-disable no-debugger */

export const CardContainer: FC<any> = ({ data }) => {
	const dispatch = useDispatch();
	const { text, btn, img, id } = data;
	const qrcode = useSelector(getQrcode);
	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		// setIsOpen?.(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<div className={`container container--relative container-${String(id)}`}>
			<div className="radial-blur" />
			<div
				style={{ background: `${String(`url(${mineBack})` || 'transparent')}` }}
				className="card__container card__container--eco"
			>
				{' '}
				<div className="card__coin_back">
					<img src={img?.coin1 || ''} alt="" />
				</div>
				<div className="card__top-wrapper">
					<EcoHeader handleChange={handleOPneCard} data={data} />
					<div className="card__center_info">
						{text}
						<div className={`card__center_coin center_info_coin-${String(id)}`}>
							<img src={img?.coin2 || ''} alt="" />
						</div>
					</div>
				</div>
				{btn ? (
					<div className="card__footer">
						<div className="card__block">
							<span className="card__block-text">DOWNLOAD HNI</span>
							<div className="card__footer-img">
								<img src={download} alt="" />
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
