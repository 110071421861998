import { FC, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import queryString from 'query-string';
import * as yup from 'yup';
// import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import { ICreateNewPassSubmitValue, ICreateNewPasswordForm } from './types';
/* eslint-disable */
// ==========================================:
const CreateNewPassForm: FC<ICreateNewPasswordForm> = ({ createNewPassSubmit }) => {
	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const queryPar = queryString.parse(location.search);
	const tempToken = Object.keys(queryPar);
	const typeReset = location.state;

	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	const validationSchema = yup.object().shape({
		password: yup
			.string()
			.required('Password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.max(25, 'Password max length'),
		confirmPassword: yup
			.string()
			.required('Confirm password required')
			.matches(
				/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
				'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
			)
			.oneOf([yup.ref('password'), null], `Password doesn't match`)
			.max(25, 'Password max length'),
	});

	const handlePassDisplay = (): void => {
		setIsShowPass(!isShowPass);
	};

	const handleConfirmPassDisplay = (): void => {
		setIsShowConfirmPass(!isShowConfirmPass);
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: ICreateNewPassSubmitValue, { resetForm, setSubmitting }) => {
				const dataValues = {
					data: {
						password: values.password,
						c_password: values.confirmPassword,
						token: tempToken[0],
						type: String(typeReset),
					},
					navigate,
				};
				createNewPassSubmit(dataValues);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, errors }) => {
				const { confirmPassword } = errors;
				return (
					<Form>
						<div className="authorization-form__header">
							<p className="form-title form-title--center">Enter new password</p>
						</div>
						<div className="authorization-form__body">
							<div className="authorization-form__group">
								<Field
									type={isShowPass ? 'text' : 'password'}
									placeholder="New Password"
									name="password"
									required
									component={Input}
									ariaLabel="show-password"
									showPass={isShowPass}
									setIsShowPass={handlePassDisplay}
								/>

								<Field
									type={isShowConfirmPass ? 'text' : 'password'}
									placeholder="Confirm password"
									name="confirmPassword"
									required
									component={Input}
									ariaLabel="show-confirm-password"
									showPass={isShowConfirmPass}
									setIsShowPass={handleConfirmPassDisplay}
								/>
							</div>
						</div>
						<div className="form-footer">
							<button
								disabled={!(isValid && dirty) || isSubmitting}
								aria-label="form-submit"
								type="submit"
								className={`button button--big-height button--full-width ${
									confirmPassword && String(confirmPassword) === "Password doesn't match"
										? 'button--warning'
										: ''
								}`}
							>
								{confirmPassword && String(confirmPassword) === "Password doesn't match"
									? 'Please Try Again'
									: 'Update password'}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default CreateNewPassForm;
