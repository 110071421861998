import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import mineBack from 'assets/custom-icons/background/mineBack.png';
import check from 'assets/images/ecosystem/check.png';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import QX from 'assets/custom-icons/background/QX.png';
import { EcoHeader } from './EcoHeader';
import { ICardProps } from './types';

/* eslint-disable no-debugger */

export const CardContainer: FC<any> = ({ data }) => {
	const dispatch = useDispatch();
	const { text, btn } = data;
	const qrcode = useSelector(getQrcode);
	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		// setIsOpen?.(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<div className="container container--relative">
			<div
				style={{ background: `${String(`url(${QX})` || 'transparent')}` }}
				className="card__container card__container--eco"
			>
				<div className="radial-blur radial-blur-card1" />
				<div className="radial-blur radial-blur-card2" />
				<div className="card__top-wrapper">
					<EcoHeader handleChange={handleOPneCard} data={data} />
					<div className="card__center_info">{text}</div>
				</div>

				{btn ? (
					<div className="card__footer">
						<div className="card__block">
							<span className="card__block-text">REGISTER/TRADE</span>
							<div className="card__footer-img">
								<img src={check} alt="" />
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
