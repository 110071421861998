import { FC, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import CopyToClipboard from 'react-copy-to-clipboard';
import { shortNotation } from 'services/utils/textSlice';
import referImg from 'assets/images/refer-icon.svg';
import { notificationContainer } from 'services/utils/notificationContainer';
// import gatoEarning from 'assets/images/gato-earnigs.png';
import { getQrcode } from 'redux/reducers/auth/selectors';
import copy from 'assets/images/copied.svg';
// import useClipboard from 'react-use-clipboard';
import { Table } from './Table';
/* eslint-disable no-debugger */

export const Referral: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector(getUserSettingsData);
	const qrcode = useSelector(getQrcode);
	const [isCopied, setCopied] = useState(false);
	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);
	// const [isCopied, setCopied] = useClipboard(`${String(user?.referral_link)}` || '');
	// const data = referralsData && referralsData.data;
	// debugger;
	useEffect(() => {
		dispatch(getUserReferralsInfoRequest());
	}, [dispatch]);

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<section className="refer-section refer-profile">
			<div className="container">
				<div className="refer-box">
					<div className="refer-top">
						<div className="refer-top--block">
							<div className="refer-top__img">
								<img src={referImg} alt="refer" />
							</div>
							<h4 className="refer-top__title">Refer and earn!</h4>
							<p className="refer-top__link">
								Referral Link: <span>{shortNotation(String(user?.referral_link), 20, 5)} </span>
								{isCopied ? (
									<img className="refer-copy-img" src={copy} alt="copy" />
								) : (
									<CopyToClipboard text={String(user?.referral_link)} onCopy={handleCopy}>
										<button type="button" className="qrcode-block">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="17"
												height="16"
												viewBox="0 0 17 16"
												fill="none"
											>
												<path
													d="M16.9104 3.31353V11.266C16.9104 12.2203 16.3113 13.042 15.4416 13.4397C15.4513 13.3071 15.4609 13.1746 15.4609 13.0332V5.08074C15.4609 3.0131 13.6153 1.32541 11.3541 1.32541H3.26612C3.71063 0.539 4.59963 0 5.63358 0H13.2868C15.287 0 16.9104 1.48446 16.9104 3.31353ZM11.3541 2.65082H2.65735C1.19146 2.65082 0 3.74031 0 5.08074V13.0332C0 14.3736 1.19146 15.4631 2.65735 15.4631H11.3541C12.82 15.4631 14.0115 14.3736 14.0115 13.0332V5.08074C14.0115 3.74031 12.82 2.65082 11.3541 2.65082Z"
													fill="black"
												/>
											</svg>
										</button>
									</CopyToClipboard>
								)}
							</p>
						</div>
						<div className="refer-top--block refer-top--block-img">
							<img src={qrcode} alt="" />
						</div>
					</div>
					<div className="refer-bottom">
						{/* <div className="refer-subscription">
							<div className="refer-subscription__left">
								<div className="refer-subscription__info">
									<p className="refer-subscription__subtitle">Friends Receive</p>
									<p className="refer-subscription__text">10% Cashback</p>
									<span>+</span>
									<NavLink to="/" className="refer-subscription__link">
										Free subscription to
									</NavLink>
								</div>
							</div>
							<div className="refer-subscription__right">
								<div className="refer-subscription__logo">
									<img src={gatoEarning} alt="" />
								</div>
							</div>
						</div> */}
						<Table />
					</div>
				</div>
			</div>
		</section>
	);
};
