import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import search_eco from 'assets/images/search-eco.png';
import telegram from 'assets/images/ecosystem/Telegram.png';
import messanger from 'assets/images/ecosystem/messanger.png';
import pinterest from 'assets/images/ecosystem/Pinterest.png';
import rubinGreen from 'assets/custom-icons/background/rubinGreen.png';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { ICardProps } from './types';

/* eslint-disable no-debugger */

export const CardContainer: FC<ICardProps> = ({ data }) => {
	const dispatch = useDispatch();

	const qrcode = useSelector(getQrcode);
	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		// setIsOpen?.(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<div className="container container--relative">
			<div
				style={{ background: `${String(`url(${rubinGreen})` || 'transparent')}` }}
				className="card__container card__container--eco"
			>
				<div className="radial-blur radial-blur-card1" />
				<div className="radial-blur radial-blur-card2" />
				<div className="card__center_info">{data?.text}</div>
				<div className="card__footer">
					<div className="card__block">
						<div className="card__block-img">
							<img src={pinterest} alt="" />
						</div>
						<span>Email</span>
					</div>
					<div className="card__block">
						<div className="card__block-img">
							<img src={telegram} alt="" />
						</div>
						<span>Telegram</span>
					</div>
					<div className="card__block">
						<div className="card__block-img">
							<img src={messanger} alt="" />
						</div>
						<span className="card__block_img-description--blue">Coming Soon</span>
						<span>live chat support</span>
					</div>
				</div>
			</div>
		</div>
	);
};
