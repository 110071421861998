import { useEffect } from 'react';
// import useAuth from 'hooks/useAuth';
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';
import { setAddress } from 'redux/reducers/connectWallet/reducer';
import { setSlipping } from 'redux/reducers/wallets/reducer';
// import { Web3Provider } from '@ethersproject/providers';
// import { getLS } from 'components/Exchange/ExchangeForm/utils';
import { notificationContainer } from 'services/utils/notificationContainer';
/* eslint-disable */

const useWalletConnect = () => {
	const dispatch = useDispatch();
	// const { login } = useAuth();
	const { address, isConnected, connector } = useAccount();
	const connectorName = window.localStorage.getItem('connectorLocalStorageKey');
	// const slippage = getLS('slippage')

	// useEffect(() => {
	//      console.log(connectorName)
	// 	if (connectorName && !active) {
	// 		login(connectorName);

	// 	}
	// }, [connectorName, login, active]);
	useEffect(() => {
		if (isConnected) {
			const payload = {
				account: address,
				active: isConnected,
				loading: true,
				// type: library?.connection?.url,
			};

			dispatch(setAddress(payload));
		}
	}, [isConnected, connectorName]);
	// dispatch(setSlipping(slippage))
};

export default useWalletConnect;
