import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import { LoginForm } from 'components/Forms/LoginForm';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import { ILoginCredsProps } from './types';

export const LoginCreds: FC<ILoginCredsProps> = ({ on2FA, onEmailAlreadySent }) => {
	const [checkbox, setCheckbox] = useState(false);
	const dispatch = useDispatch();
	const loginIsLoad = useSelector(getLoginIsLoad);

	const validateEmail = (email: string) => {
		const search = '[a-z0-9]+@[a-z]+.[a-z]{2,3}';
		const regex = new RegExp(`${search}`, 'i');
		if (regex.test(email)) {
			return true;
		}
		return false;
	};

	const handleLoginSubmit = (values: ILoginSubmitValue): void => {
		if (!values) return;
		const { name, password, captcha } = values;
		const data = {
			type: 'email',
			email: name,
			password,
			captcha,
			email_code: '111111',
			totp: '1111111',
			[`${validateEmail(String(name)) ? 'email' : 'phone'}`]: validateEmail(String(name))
				? String(name).toLowerCase()
				: `+${String(name)}`,
		};

		if (validateEmail(String(name))) {
			const params = { data, on2FA, onEmailAlreadySent };
			dispatch(loginRequest(params));
			return;
		}
		const params = { data, on2FA, onEmailAlreadySent };
		dispatch(loginRequest(params));
	};

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="authorization-form">
						<LoginForm
							loginSubmit={handleLoginSubmit}
							setCheckbox={setCheckbox}
							checkbox={checkbox}
						/>
						{loginIsLoad && (
							<div className="auth-loader-wrapper">
								<Loader />
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};
