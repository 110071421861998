/* eslint-disable @typescript-eslint/no-explicit-any */
import { notificationContainer } from 'services/utils/notificationContainer';

// ==========================================:
const customResponseErrors = (errorMessage: string, type: string, response: any) => {
	const urlPath = response.config.url;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const params: string = urlPath?.split('/').pop()?.replace('_', '/').toUpperCase() || ' ';
	if (errorMessage === 'Pair_not_available') {
		notificationContainer(`Pair ${params}  not available. Choose another pair.`, type);
	} else {
		notificationContainer(errorMessage, type);
	}
};
export { customResponseErrors };

const customResponseErrorsAll = (errorMessage: string, type: string, response: any) => {
	const message = response;
	const params: string = message?.split('_').join(' ').toUpperCase() || ' ';
	if (errorMessage === 'Pair_not_available') {
		notificationContainer(`Pair ${params}  not available. Choose another pair.`, type);
	} else {
		notificationContainer(errorMessage, type);
	}
};
export { customResponseErrorsAll };

export const callErrorFunc = (errorsData = [], msgTimeout = 5000) => {
	msgTimeout;
	const notifyError = (errors: any) => {
		errors.forEach((err: any) => {
			const firstLetter: string = err.charAt(0).toUpperCase();
			const msg = `${firstLetter}${String(err.slice(1, err.length).split('_').join(' '))}`;

			notificationContainer(msg, 'error');
		});
	};

	if (errorsData) {
		const errors = errorsData;

		if (errors) {
			if (Array.isArray(errors)) {
				notifyError(errors.flat(1));
			} else if (Object.values(errors).length) {
				notifyError(Object.values(errors).flat(1));
			} else {
				notificationContainer('Please try again later', 'error');
			}
		}
	} else {
		notificationContainer('Please try again later', 'error');
	}
};
