import { FC, useState, ChangeEvent } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { loginRequest } from 'redux/reducers/auth/reducer';
// import { getLoginIsLoad } from 'redux/reducers/auth/selectors';
import Loader from 'ui/Loader';
import { ILogin2FAProps } from './types';

export const Login2FA: FC<ILogin2FAProps> = ({ params2FA }) => {
	// const dispatch = useDispatch();
	// const loginLoading = useSelector(getLoginIsLoad);

	const [code2FA, setCode2FA] = useState('');

	const { apiParams, type2FA } = params2FA;
	const isByEmail = type2FA === 'email';

	const handleChange2FA = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 6) return;
		setCode2FA(e.target.value);
	};

	const handleSubmit = () => {
		const data = { ...apiParams };

		if (type2FA === 'email') {
			data.email_code = code2FA;
		} else {
			// type2FA === 'google'
			data.totp = code2FA;
		}
		// dispatch(loginRequest({ data }));
	};

	return (
		<section className="authorization-section">
			<div className="container">
				<div className="authorization-box">
					<div className="authorization-form">
						<div className="authorization-form__header">
							<p className="form-title form-title--center">Security verification</p>

							<div className="form-text form-text--center form-text--fixed-width">
								<p>To secure your account, please complete the following verification.</p>
							</div>
						</div>

						<div className="authorization-form__body">
							<div className="authorization-form__group">
								<div className="input input--no-mb">
									<p className="input__name">{isByEmail ? 'Email' : 'Authenticator'} Code</p>

									<div className="input-wrapper">
										<input
											className="input-item"
											type="number"
											value={code2FA}
											onChange={handleChange2FA}
										/>
									</div>
									{/* 
									<p className="input-hint">
										Enter the 6 digit code{' '}
										{isByEmail
											? `sent to email${apiParams.email ? `: ${apiParams.email}` : ''}`
											: 'from Google Authenticator'}
									</p> */}
								</div>
							</div>

							<div className="verification-actions">
								<button type="button" className="verification-action verification-action--type2">
									Security verification unavailable?
								</button>
							</div>
						</div>

						<div className="form-footer">
							<button
								type="button"
								className="button button--big-height button--full-width"
								onClick={handleSubmit}
								disabled={!code2FA}
							>
								Submit
							</button>
						</div>
					</div>

					{/* {loginLoading && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)} */}
				</div>
			</div>
		</section>
	);
};
