/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { VerificationModal } from 'components/Forms/FormModals/VerificationModal';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { isAfter } from 'date-fns';
import { emailConfirmRequest } from 'redux/reducers/auth/reducer';
import { Page } from 'layouts/page';
import EmailConfirmSuccess from 'components/EmailConfirmSuccess';
import EmailConfirmError from 'components/EmailConfirmError';

// ==========================================:
const EmailConfirm: FC = () => {
	const location = useLocation();
	const dispatch = useDispatch();

	const [openModalSend, setOpenModalSend] = useState(false);
	const [activeAnim, setActiveAnim] = useState(false);

	const queryPar = queryString.parse(location.search);
	const tokenTime = new Date(Number(queryPar.timestamp) * 1000);
	const currentTime = new Date();

	const tokenTimeExpired = isAfter(currentTime, tokenTime);

	useEffect(() => {
		if (!tokenTimeExpired) {
			dispatch(
				emailConfirmRequest({
					token: queryPar.token,
					timestamp: queryPar.timestamp,
					onSuccess: () => {
						setActiveAnim(true);
					},
				}),
			);
			setOpenModalSend(true);
		}
	}, [dispatch, queryPar.timestamp, queryPar.token, tokenTimeExpired]);

	return (
		<Page title="Email confirm">
			{tokenTimeExpired ? <EmailConfirmError /> : <EmailConfirmSuccess />}
			<VerificationModal
				openModal={openModalSend}
				setOpenModal={setOpenModalSend}
				activeAnim={activeAnim}
				classIn="height--800"
			/>
		</Page>
	);
};

export default EmailConfirm;
