/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
// import L from 'i18n-react';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { callErrorFunc } from 'services/http/customResponseError/customResponseErrors';
import { notificationContainer } from 'services/utils/notificationContainer';
import {
	TWalletBalancesDataRequest,
	TWalletBalancesDataResponse,
	TWalletUserBalanceRequest,
	TWalletUserBalanceResponse,
	TUserTransactionResponse,
	TUserTransactionRequest,
	TWalletTransferRequest,
} from './types';
import {
	getWalletBalancesRequest,
	getWalletBalancesSuccess,
	getWalletBalancesFelure,
	getUserBalanceRequest,
	getUserBalanceSuccess,
	getUserBalanceFailure,
	getHistoryTranasactionRequest,
	getHistoryTranasactionSuccess,
	getHistoryTranasactionFelure,
	addTransferRequest,
} from './reducer';

/* eslint-disable no-debugger */

// =============================================================:
function* getWaletsBalancesWorker({ payload }: PayloadAction<TWalletBalancesDataRequest>) {
	try {
		yield put(showLoading());
		const response: TWalletBalancesDataResponse = yield call(
			api.wallet?.getWalletsBalances,
			payload,
		);

		// notificationContainer('Phone was successfully changed', 'success');
		yield put(getWalletBalancesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getWalletBalancesFelure());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getUserBalanceWorker({ payload }: PayloadAction<TWalletUserBalanceRequest>) {
	try {
		yield put(showLoading());
		const response: TWalletUserBalanceResponse = yield call(api.wallet?.getTokenBalanse, payload);

		// notificationContainer('Phone was successfully changed', 'success');
		yield put(getUserBalanceSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getUserBalanceFailure());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* getUserTranasactionsWorker({ payload }: PayloadAction<TUserTransactionRequest>) {
	try {
		yield put(showLoading());
		const response: TUserTransactionResponse = yield call(
			api.wallet?.getTransactionHistory,
			payload,
		);

		// notificationContainer('Phone was successfully changed', 'success');
		yield put(getHistoryTranasactionSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(getHistoryTranasactionFelure());
	} finally {
		yield put(hideLoading());
	}
}

// // =============================================================:
// function* addTransferWorker({ payload }: PayloadAction<TWalletTransferRequest>) {
// 	try {
// 		yield put(showLoading());
// 		const response: TUserTransactionResponse = yield call(api.wallet.postWalletTransfer, payload);
// 	} catch (error) {
// 		if (axios.isAxiosError(error)) {
// 			responseErrors(error);
// 		}
// 	} finally {
// 		yield put(hideLoading());
// 	}
// }

export function* walletsSaga() {
	yield takeEvery(getWalletBalancesRequest.type, getWaletsBalancesWorker);
	yield takeEvery(getUserBalanceRequest.type, getUserBalanceWorker);
	yield takeEvery(getHistoryTranasactionRequest.type, getUserTranasactionsWorker);
	// syield takeEvery(addTransferRequest.type, addTransferWorker);
}
