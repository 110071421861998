/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { http } from 'services/http';
/* eslint-disable */
export const toBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

const getImageBlob = async (imgSrc: string): Promise<Blob> => {
	debugger;
	try {
		const response = await axios.get<ArrayBuffer, any>(imgSrc, { responseType: 'arraybuffer' });
		debugger;
		const imageBlob = new Blob([response.data], { type: 'image/png' });
		return imageBlob;
	} catch (error) {
		// Handle error appropriately
		console.error('Error fetching image:', error);
		throw error; // Rethrow the error or handle it as needed
	}
};

// Example usage

export const downloadImgHref = async (imgSrc: string) => {
	debugger;
	// const imageBlob = await http
	// 	.get<ArrayBuffer, any>(imgSrc)
	// 	.then((response) => response.arrayBuffer())
	// 	.then((buffer) => new Blob([buffer], { type: 'image/png' }));
	//console.log(imageBlob);
	//const imageBlob = await fetch(imgSrc)
	//.then((response) => response.arrayBuffer())
	//.then((buffer) => new Blob([buffer], { type: 'image/jpeg' }));

	debugger;
	const imageBlob = await getImageBlob(imgSrc);
	// Now you can use the 'imageBlob' as needed
	console.log(imageBlob, URL.createObjectURL(imageBlob));
	const link = document.createElement('a');
	// link.href = URL.createObjectURL(imageBlob);
	// link.href = String(imageBlob);
	// link.download = 'cosmo-code.png';
	// link.style.display = 'none';
	// document.body.appendChild(link);
	// link.click();
	// document.body.removeChild(link);
};

export const downloadImgLink = async (imgSrc: string) => {
	const xhr = new XMLHttpRequest();
	xhr.open('GET', imgSrc, true);
	xhr.responseType = 'blob';
	xhr.onload = () => {
		const blob = xhr.response;
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.download = 'image.png'; // Ім'я файлу для завантаження
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};
	xhr.send();
};
