import { spawn } from 'redux-saga/effects';
import { authSaga } from './reducers/auth/saga';
import { settingsSaga } from './reducers/settings/saga';
import { connectSaga } from './reducers/connectWallet/saga';
import { walletsSaga } from './reducers/wallets/saga';

export function* rootSaga() {
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(connectSaga);
	yield spawn(walletsSaga);
}
