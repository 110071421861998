import { FC, useState } from 'react';
import { LanguageItem } from '../LanguageItem';
import { arrItem, TItem } from '../LanguageItem/constant';

export const AccountLanguage: FC = () => {
	const [checked, setChecked] = useState('English');
	return (
		<section className="general-section">
			<div className="container">
				<div className="inner-block">
					<div className="general-block authorization-form--language">
						<p className="general-title general-title--mob-center">Language</p>
						<ul className="language-list">
							{arrItem.map((langItem: TItem, id: number) => (
								<LanguageItem
									key={`${String(id) + langItem.text}`}
									langItem={langItem}
									checked={checked}
									setChecked={setChecked}
								/>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};
