export const cardContent = [
	{
		id: 0,
		text: 'Discover Crypto Exchange Technology, a cutting-edge white label solution revolutionizing real estate investment. Our blockchain-powered solution enables borderless property investments with crypto tokens and smart contracts. Enjoy regulatory-compliant, on-chain property listings with our secure, user-friendly dashboard.',
		btn: true,
		btn_text: 'Download pdf',
	},
	{
		id: 1,
		text: 'Our specifically designed white label solution allows you to create your own platform using unmatched technology and design techniques, maintaining a user-friendly interface. Connect with us today to start your journey to the future of technology.',
		btn: true,
		btn_text: 'Request a demo',
	},
	{
		id: 2,
		text: 'Connect with us today to start your journey to the future of technology. Our specialized team is always at your assistance to help you with your concerns regarding your investments.',
		btn: true,
		btn_text: 'contact cet tech',
	},
];
