import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getUserReferralsInfoRequest } from 'redux/reducers/settings/reducer';
import cryptoEst from 'assets/custom-icons/background/cryptoEst.png';
import twoDomino from 'assets/custom-icons/cards/dominTwo.png';
import { getUserSettingsData } from 'redux/reducers/settings/selectors';
import { notificationContainer } from 'services/utils/notificationContainer';
import { getQrcodeRequest } from 'redux/reducers/auth/reducer';
import search_eco from 'assets/images/search-eco.png';
import { getQrcode } from 'redux/reducers/auth/selectors';
import { ICardProps } from './types';
import { EcoHeader } from './EcoHeader';

/* eslint-disable no-debugger */

export const CardContainer: FC<ICardProps> = ({ data }) => {
	const { id, btn, btn_text } = data;
	const dispatch = useDispatch();

	const qrcode = useSelector(getQrcode);
	const user = useSelector(getUserSettingsData);
	const [isCopied, setCopied] = useState(false);
	// const data = referralsData && referralsData.data;

	useEffect(() => {
		if (!qrcode) {
			dispatch(getQrcodeRequest());
		}
	}, [dispatch, qrcode]);

	const handleOPneCard = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		// setIsOpen?.(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	const handleCopy = () => {
		notificationContainer('Address copied successfully!', 'info');
		setCopied(true);
	};

	return (
		<div className={`container container--relative container-${String(id)}`}>
			<div className="radial-blur" />
			<div
				style={{ background: `${String(`url(${cryptoEst})` || 'transparent')}` }}
				className="card__container card__container--eco"
			>
				{' '}
				{/* <div className="eco-puzzle">
					<img src={puzzle} alt="" />
				</div> */}
				<div>
					<EcoHeader handleChange={handleOPneCard} data={data} />
					<div className="card__center_info">
						{data?.text}
						<div className="card__center_coin">
							<img src={twoDomino || ''} alt="" />
						</div>
					</div>
				</div>
				{btn ? (
					<div className="card__footer">
						<div className="card__block">
							<span className="card__block-text">{btn_text}</span>
							<div className="card__footer-img">
								<img src={search_eco} alt="" />
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
		</div>
	);
};
