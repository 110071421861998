import plus_tire from 'assets/images/gate/card/plus_tire.png';
import download from 'assets/images/gate/card/download_fot.png';

export const cardContent = [
	{
		id: 0,
		text: 'Gato Coin, built on a layer 1 blockchain, offers vast earning possibilities within the Gato ecosystem. Users can earn rewards from staking, referral reward system, other active projects within the ecosystem and more. Expand your portfolio with Gato and ensure multiplied earnings for your investments. For early birds, Gato is now available for $0.18 in its presale phase. Grab this opportunity before the price spikes!',
		btn: true,
		btn_text: 'buy gato coin',
		f_img: plus_tire,
	},
	{
		id: 1,
		text: 'This limited supply digital asset helps you gain huge returns in multiple ways. While you can use the coin for your transactions offline and online, you can earn from the blockchain projects within the ecosystem. Apart from the gaming rewards and DeFi cashback, Gato also allows you to increase your passive income through staking your coins.',
		btn: true,
		btn_text: 'download pdf',
		f_img: download,
	},
];
