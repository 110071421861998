import { auth } from './auth/index';
import { assets } from './assets/index';
import { IApi } from './types';
import { settings } from './settings';
import { wallet } from './wallet';

const api: IApi = {
	auth,
	settings,
	wallet,
	assets,
};
export { api };
