import { TWalletBalancesDataRequest } from 'redux/reducers/wallets/types';

export const endpoint = {
	auth: {
		LOGIN: '/login',
		REFRESH_TOKEN: 'token/refresh',
		REGISTRATION: '/register',
		LOGOUT: '/logout',
		RESET_PASSWORD: '/reset/password/email',
		RESET_PHONE_PASSWORD: '/reset/password/phone',
		NEW_PASSWORD: '/reset/password/email/user/password',
		NEW_PASSWORD_PHONE: '/reset/password/phone/user/password',
		NEW_PASSWORD_PHONE_TOKEN: '/reset/password/phone/user/token',
		RESET_EMAIL_CONFIRM: '/reset-token',
		EMAIL_CONFIRM: (token: string) => `/confirmation/email?token=${token}`,
		IP_CONFIRM: '/whitelist_ip',
		GENERATE_SMS: '/generate_sms',
		CONFIRM_PHONE: '/confirmation/phone',
		RESET_TWOFA: '/2fa/reset',
		RESEND_EMAIL: '/re_send_confirmation',
		REFERAL_QRCODE: '/user/referral-qr',
		REFERAL_IMG_QRCODE: '/user/referral-qr-image',
	},
	settings: {
		USER_SETTINGS: '/user',
		CHANGE_PASSWORD: '/user/change/password',
		POST_USER_PROFILE: 'user/profile',
		POST_USER_AVATAR: 'user/avatar',

		// 2FA New
		SEND_EMAIL_CODE: '/2fa/email/code',
		ENABLE_EMAIL_2FA: '/settings/2fa/email/enable',
		DISABLE_EMAIL_2FA: '/settings/2fa/email/disable',
		GENERATE_GOOGLE_2FA_KEY: '/settings/2fa/generate_secret_key',
		ENABLE_GOOGLE_2FA: '/settings/2fa/enable',
		DISABLE_GOOGLE_2FA: '/settings/2fa/disable',
		// 2FA Old (to delete)
		ENABLE_2FA: '/settings/2fa/enable',
		DISABLE_2FA: '/settings/2fa/disable',
		GENERATE_2FA_KEY: '/settings/2fa/generate_secret_key',
		CHECK_ENABLED_2FA: '/settings/2fa/check_enabled',

		SET_PHONE: '/user_settings/set_phone',
		CHECK_NOTIFICATION: '/user_settings/notification_settings',
		UPDATE_NOTIFICATION: '/user_settings/notification_settings',
		REFERRALS: '/user/referrals',
		NATIONALITIES: '/nationalities',
	},
	wallet: {
		ADD_WALLET: 'user/wallet',
		GET_BALANSE: (token: string, address: string) => `user/wallet/balance/${token}/${address}`,
		GET_WALLETS_BALANSE: (payload: TWalletBalancesDataRequest) => {
			return `user/wallet/balances/${String(payload.address.toLocaleLowerCase())}${
				payload?.current_page ? `?current_page=${String(payload?.current_page)}` : ''
			}${payload?.per_page ? `&per_page=${String(payload?.per_page)}` : ''}${
				payload?.hide_null ? '&hide_null=1' : ''
			}`;
		},
		GET_TRANSACTIONS_HISTORY: 'user/wallet/transactions',
	},
	assets: {
		GET_ASSETS: '/assets?is_active_global=1',
		GET_ASSETS_BY_ID: (id: string) => `swap/assets?id=${id}`,
		ASSET_PAIRS: (id: string) => `/asset_pairs/${id}`,
		GET_GRAPH: (idPair: string, period: string) => `/candles/${idPair}/${period}`,
		POST_ASSET: `/swap/assets/add_asset`,
	},
};
