import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import { getUserSettingsDataLoader, getReferrals } from 'redux/reducers/settings/selectors';
import { TReferrals } from 'redux/reducers/settings/types';
import { TableItem } from '../TableItem';
import { TTable, TTables } from './type';
/* eslint-disable no-debugger */

export const Table: FC<TTable> = () => {
	const dataLoad = useSelector(getUserSettingsDataLoader);
	const referralsInfo = useSelector(getReferrals);

	const replaceData: TTables | any = useMemo(() => {
		if (!referralsInfo) return [];

		const emptyLevel: TReferrals = {
			level: 1,
			referrals: [],
			total: 0,
		};
		const arrClice: number =
			referralsInfo.length % 2 ? (referralsInfo.length + 1) / 2 : referralsInfo.length / 2;

		const firstArr: Array<TReferrals> = referralsInfo.slice(0, arrClice);
		const secondArr: Array<TReferrals> =
			referralsInfo.length % 2
				? referralsInfo.slice(-(arrClice - 1))
				: referralsInfo.slice(-arrClice);

		const addEmptyObject: Array<TReferrals> =
			secondArr.length % 2 === 1
				? [
						...secondArr,
						{ ...emptyLevel, level: Number(secondArr[secondArr.length - 1]?.level) + 1 },
				  ]
				: [...secondArr];
		const twoTable: TTables | any = [firstArr, addEmptyObject];
		return twoTable;
	}, [referralsInfo]);

	return (
		<div className="refer-tables">
			{dataLoad ? (
				<div className="table-loader-wrapper">
					<Loader small />
				</div>
			) : null}
			{replaceData
				? replaceData?.map((table: Array<TReferrals>, id: number) => {
						return (
							<div key={`${String(id)}23`} className="table table--refer">
								<div className={`table-header ${id === 1 ? 'table-header--hidden' : ''}`}>
									<div className="tr">
										<div className="td">MY NETWORK</div>
										<div className="td td--center">Total members</div>
									</div>
								</div>
								<div className="table-body">
									{!dataLoad && table
										? table?.map((item: TReferrals) => <TableItem key={item.level} item={item} />)
										: null}
								</div>
							</div>
						);
				  })
				: null}
		</div>
	);
};
