import { FC } from 'react';
import { Modal } from 'ui/Popups/ModalsDropdown';
import { Link } from 'react-router-dom';
import Clock from 'assets/images/gate/clock.png';

export const ConfirmatedModal: FC<any> = ({ openModal, setOpenModal, activeAnim }) => {
	return (
		<Modal isOpen={openModal} onClose={setOpenModal} activeAnim={activeAnim}>
			<div className="popup-clock">
				<img src={Clock} alt="" />
			</div>
			<div className="popup-content">
				<h1 className="title">Security verification sent </h1>
				<p className="sub-title">Now you can Log In to your account.</p>
				<Link to="/" className="button button--full-width button--big-height button--margin-top">
					Check your account
				</Link>
			</div>
		</Modal>
	);
};
