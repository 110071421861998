import { FC, useState, KeyboardEvent, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
// import L from 'i18n-react';
import Input from 'ui/Formik/Input';
import { IForgotPassSubmitValue, IForgotPasswordForm } from './types';
/* eslint-disable no-debugger */

// ==========================================:
export const ForgotPassForm: FC<IForgotPasswordForm> = ({ emailSubmit }) => {
	const initialValues = {
		text: '',
	};

	const validationSchema = yup.object().shape({
		text: yup
			.string()
			.required('Required field')
			// .email('Email format')
			.max(60, 'Email max characters'),
	});

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={(values: any, { resetForm, setSubmitting }) => {
				emailSubmit(values);
				setSubmitting(false);
				resetForm();
			}}
			validateOnBlur
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty }) => {
				return (
					<Form>
						<>
							<div className="authorization-form__header">
								<p className="form-title form-title--center">Oh, don’t Worry!</p>
								<div className="form-text form-text--center">
									<p>
										Enter your email, phone or username and we’ll send you a link to reset your
										password.
									</p>
								</div>
							</div>
							<div className="authorization-form__body">
								<div className="authorization-form__group">
									<Field
										type="text"
										placeholder="Email or Phone Number"
										name="text"
										required
										component={Input}
									/>
								</div>
							</div>
							<div className="form-footer">
								<button
									disabled={!(isValid && dirty) || isSubmitting}
									type="submit"
									aria-label="form-submit"
									className="button button--big-height button--full-width button--type7"
								>
									Email Sent
								</button>
							</div>
							<div className="form--bottom">
								Have an account ? <Link to="/registration">Sign Up</Link>
							</div>
						</>
					</Form>
				);
			}}
		</Formik>
	);
};
