import { FC } from 'react';
import img from 'assets/custom-icons/cards/gudsHeader.png';
import heart from 'assets/images/gate/card/heart.png';
import share from 'assets/images/gate/card/share.png';
import { TCard } from '../../Dashboard/CardDifferent/types';

export const EcoHeader: FC<TCard> = ({ handleChange }) => {
	return (
		<div className="card__header">
			<span className="card__heart card__eco-heart">
				<img src={heart} alt="" />
			</span>
			<span className="card__logo-text card__eco-text">
				<img src={img} alt="" />
			</span>
			<button
				onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleChange?.(e)}
				type="button"
				className="card__share card__eco-share"
			>
				<img src={share} alt="" />
			</button>
		</div>
	);
};
