import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BackgroundProfile } from 'layouts-elements/Background/BackgroundProfile';
import { userSettingsRequest } from 'redux/reducers/settings/reducer';
import HeaderMenu from 'layouts-elements/Header/HeaderMenu';
import { APP_NAME } from 'services/constants/env';
import Footer from 'layouts/Footer';
import { Header } from 'layouts-elements/Header';
import { getTheme } from 'redux/reducers/settings/selectors';
import { TopBlock } from 'layouts-elements/TopBlock';
import { TPageProps } from './types';

// ==========================================:
export const PageProfile: FC<TPageProps> = ({ children, title, link, text }) => {
	const pageTitle = title ? `| ${title}` : '';
	const pageText = text || '';
	const pageLink = link || '';
	const [open, setOpen] = useState(false);

	const theme = useSelector(getTheme);
	// const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(userSettingsRequest());
	// }, [dispatch]);

	return (
		<>
			{/* <Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet> */}
			<Header open={open} setOpen={setOpen} />
			<HeaderMenu open={open} setOpen={setOpen} />
			<div className={`wrapper ${theme}`}>
				<BackgroundProfile />
				{pageText ? <TopBlock text={pageText} link={pageLink} /> : ''}
				<main className="main">{children}</main>
				<Footer />
			</div>
		</>
	);
};
